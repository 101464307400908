
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { PATHS } from "../../constants";


import "../../App.css";
import { LoaderScreen, NavOnDashboard, FooterOnDashboard, Sidebar } from "../includes";
import {  setLoader, userInfo, fetchRequestedSession } from "../../actions";

import ReactHtmlParser from 'react-html-parser';
//import {IMAGE_BASE_PATH} from "../../constants";
import  {add3dots}  from "../../utils/general";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
/*
 TYPES
*/

type Props = {
  history: Object,
  fetchTopic: Function,
  fetchSubCategory: Function,
  setLoader: Function,
  isLoading: boolean
};

class RequestedSession extends Component<Props> {

  constructor(props){
       super(props)
       this.state = {
          inputValue:'',
          isAuth : false,
          alertMessage: this.props.location.state?this.props.location.state.alertMessage : "",
          alertClass: this.props.location.state?this.props.location.state.alertClass: "",
       }
      
    }

  componentDidMount() {

    this.props.setLoader(true);
    const user = localStorage.getItem('isAuth') ? true : false;
    this.setState({ isAuth:user });

    this.props.fetchRequestedSession();

   
    this.props.userInfo().then(result => {
        if (this.props.userDetail) {
            this.props.setLoader(false);
        }
    })

    if(this.state.alertClass){
       setTimeout(() => {
          this.setState({ alertMessage: "", alertClass: "" })
        }, 4000);
    }
   
  }

  handleChange = (event) => {
    this.setState({value: event.target.value});
  }

   handleView (topic_id) {
  //  e.preventDefault();
   // this.props.history.push('/topic/' + topic_id)
    this.props.history.push('requested-session-detail/'+topic_id)
  }

  handleRemove = (mytopic_id) => {
    
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure want to Delete this topic?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.deleteMyTopic(mytopic_id)
            
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    });
  }


  strip = (html) =>{
   let doc = new DOMParser().parseFromString(html, 'text/html');
   return doc.body.textContent || "";
}


   handleChange = e => {
       this.setState({ inputValue: e.target.value });
   };

   renderList = () => {

      let  { requestedSessionResponse } = this.props;
      const  { inputValue } = this.state;
      if(inputValue && requestedSessionResponse && requestedSessionResponse.length>0){

         requestedSessionResponse =  requestedSessionResponse.filter(mytopic => {
          
            return mytopic.title.toLowerCase().includes(inputValue.toLowerCase()) ||  this.strip(mytopic.description).replace(/&.*;/g,'').toLowerCase().includes(inputValue.toLowerCase()) 
         })
    }



      return requestedSessionResponse && requestedSessionResponse.length>0 ? 
         requestedSessionResponse.map((mytopic, i) => (
            <tr key={i}>                                                
                <td>{mytopic.title}</td>
                 <td>{mytopic.topic_info ? mytopic.topic_info.title : ""}</td>
                <td>{mytopic.description ? ReactHtmlParser(add3dots(mytopic.description, 150)) :  ReactHtmlParser(mytopic.description)}</td>
                 <td>{mytopic.approve_status === 0 ?
                      <h4><span className="badge badge-pill badge-warning">Pending</span></h4>
                      : mytopic.approve_status === 1 ?
                      <h4><span className="badge badge-pill  badge-success">Approved</span></h4>
                      : mytopic.approve_status === 2 ?
                        <h4><span className="badge badge-pill badge-danger">Rejected</span></h4>
                      :<h4><span className="badge badge-pill badge-danger">Created</span></h4>
                      }</td>
                <td>
                  <button className="btn btn-primary" onClick={() => this.handleView(mytopic.id)} ><i className="fa fa-eye" aria-hidden="true"></i></button>{' '} 
                  </td>
            </tr>
            ))
          :
            <tr>                                                
                <td colSpan="4" className="text-center">No record found</td>
            </tr>
              
  }


  render() {
   
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    return (
     <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
        data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <NavOnDashboard user={this.props.userDetail}/>
        <Sidebar />
       <div className="page-wrapper">
       <div className="page-m-height">
            <div className="page-breadcrumb">
                <div className="row">
                    <div className="col-12 col-sm-8 align-self-center mb-3 mb-sm-0">
                        <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">My Sessions</h3>                        
                    </div>
                    <div className="col-12 col-sm-4 align-self-center search-form">
                       
                         <span className="nav-link pl-0">
                            
                                  <div className="customize-input">
                                      <input className="form-control custom-shadow custom-radius border-0 bg-white"
                                          type="search" placeholder="Search" aria-label="Search" onChange={this.handleChange} value={this.state.inputValue}/>
                                          <i className="fas fa-search form-control-icon"></i>
                                  </div>
                              
                          </span>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                <div className="col-lg-12 col-md-12 stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className=" brdrbtm-lblue mb-3 d-flex justify-content-between">
                                <h4 className="card-title pb-3">Requested Session</h4>
                                <p className="card-title pb-3"><Link to={PATHS.ADD_REQUESTED_SESSION} className="btn btn-sm btn-primary float-right">Create</Link></p>
                               
                            </div>                                
                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <thead className="bg-info text-white">
                                        <tr>
                                            <th scope="col">Session Name</th>
                                            <th scope="col">Topic Name</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { this.renderList()}
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

               {/* this.props.myTopic ? 
                    this.props.myTopic.map((mytopic, i) => (
                        <div className="col-lg-4 col-md-12 stretch-card" key={i}>
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title brdrbtm-lblue pb-3 mb-3 text-c-blue">{mytopic.topic.title}</h4>
                                <div className="course-detail mb-3">
                                    <img src={mytopic.topic.image ? IMAGE_BASE_PATH + `uploads/topic/${mytopic.topic.image}`: "/assets/images/a.png"} alt="" className="imh-fluid" />
                                    <p>
                                       {mytopic.topic.description ? ReactHtmlParser(add3dots(mytopic.topic.description, 150)) : ''}
                                    </p>
                                </div> 
                                
                                <div className="cat-info-list">
                                    <h5 className="card-title">Topics</h5>
                                    <ul className="list-style-none pl-3 mb-3">
                                        <li>
                                            <a href="void(0)">
                                                <i className="fa fa-check text-info"></i> 
                                                Lorem ipsum dolor sit amet
                                            </a>
                                        </li>
                                        <li>
                                            <a href="void(0)">
                                                <i className="fa fa-check text-info"></i>
                                                Consectetur adipiscing elit
                                            </a>
                                        </li>
                                        <li>
                                            <a href="void(0)">
                                                <i className="fa fa-check text-info"></i>
                                                Integer molestie lorem at massa 
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <a href="javacript:void(0)" className="btn waves-effect waves-light btn-rounded btn-primary pl-4 pr-4">View more</a>
                            </div>
                        </div>
                    </div>
                    ))
                    
                  :
                   <div className="col-lg-12 col-md-12 stretch-card">
                        <div className="card">
                            <div className="card-body">
                            <p>NO record found </p>
                            </div>
                        </div>
                    </div>
                 */ }
                </div>
            </div>
          </div>
          <FooterOnDashboard />
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => {
  console.log(state.service)
  return {
        isLoading: state.service.isLoading,
        userDetail: state.service.userDetailResponse ? state.service.userDetailResponse.data : '',
        requestedSessionResponse: state.service.requestedSessionResponse ? state.service.requestedSessionResponse.data : '',
    };
};

export default connect(mapStateToProps, { setLoader, userInfo, fetchRequestedSession })(RequestedSession);
