import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
 import { PATHS } from "../../constants";

import "../../App.css";
import { LoaderScreen, NavOnIndex, FooterOnIndex } from "../includes";
import { contactForm, contactUsSubmit, setLoader } from "../../actions";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { required, email, whiteSpace, phoneNumber, number } from "../../constants/validation";
import {  renderInputNormal, renderTextArea } from "../../constants/redux-form";
import { Helmet } from 'react-helmet';
/*
 TYPES
*/

type Props = {
  history: Object,
  langList: Object,
  fetchCmsPage: Function,
  setLoader: Function,
  isLoading: boolean
};

const mystyle = {
      border: "0",
      width: "100%",
      height: "350px",
    };

// const mapUrl = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621";     


const mapUrl = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30248.190283830398!2d73.68742963955076!3d18.617999899999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2ba5710325491%3A0xb242a1ed0320ffc5!2sInternational%20Institute%20of%20Management%20Studies%20(IIMS%20Pune)!5e0!3m2!1sen!2sin!4v1631020844530!5m2!1sen!2sin"

class Contactus extends Component<Props> {

  constructor(props){
       super(props)
        this.state = {
            alertMessage: "",
            alertClass: "",
        };
    }

  componentDidMount() {

    AOS.init();
    //document.getElementById('canonical').setAttribute('href', window.location.href);  
   
  }

  componentWillMount() {
        this.props.initialize('contactForm');
    }

onSubmit = formValues => {
        let errorMessage = "";
        setTimeout(() => {

            this.props.contactUsSubmit(formValues)
                .then(result => {
                    this.props.setLoader(false);
                    if (this.props.response.success) {
                        this.props.setLoader(false);
                        this.props.reset('contactForm');
                        this.setState({ alertMessage: this.props.response.message, alertClass: "alert alert-success" });
                    } else {
                        this.props.setLoader(false);
                        errorMessage = this.props.response.message;
                        this.setState({ alertMessage: errorMessage, alertClass: "alert alert-danger" });
                    }

                    setTimeout(() => {
                        this.setState({ alertMessage: "", alertClass: "" })
                    }, 3000);
                })
                .catch(e => {
                    this.props.setLoader(false);
                });
        }, 500);
    };

  

  render() {
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    return (
      <div className="main-wrapper white-bg">

        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact Us | MyEduMaster</title>
          <meta name="description" content="Please fill out the form below to contact my EduMaster expert team. We will respond as soon as possible." />
          {/* <link rel="canonical" href={window.location.href} /> */}
        </Helmet>
          <NavOnIndex inner="true"/>        
         
          <div className="innr-banner inn-pt">
              <div className="container pt-4 pb-4">
                  <div className="row">
                      <div className="col-md-10">
                          <nav aria-label="breadcrumb" data-aos="fade-up" data-aos-delay="100">
                            <ol className="breadcrumb p-0">
                                <li className="breadcrumb-item"><Link to={PATHS.HOME}>Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Contact us</li>                                
                            </ol>
                        </nav>
                        <div className="mb-2" data-aos="fade-up" data-aos-delay="200">
                            <h1>
                                Get in touch with us
                            </h1>
                      
                        </div>
                      </div>
                  </div>
              </div>
          </div>
       
          <div className="section py-5 contact map-bg">
              <div className="container">
                <div className="row">

                  <div className="col-lg-6 mb-4" data-aos="fade-up" data-aos-delay="100">

                    <div className="row">
                      
                      <div className="col-md-6 mb-3">
                        <div className="info-box">
                          <i className="fas fa-phone-alt"></i>
                          <h3>Call Us</h3>
                      <p><a href="tel:+020-66547500">020-66547500</a></p>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="info-box">
                          <i className="far fa-envelope"></i>
                          <h3>Email Us</h3>
                      <p><a href="mailto:support@myedumaster.com">support@myedumaster.com</a></p>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="info-box">
                          <i className="fas fa-map-marker-alt"></i>
                          <h3>Address</h3>
                          <p>Sr.No. 54(1+2/1) Nere Dattawadi, Near Hinjawadi IT Park<br></br>Pune, MH 411033</p>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="col-lg-6 mb-4" data-aos="fade-up" data-aos-delay="200">
                    <form
                      name="contactForm"
                      onSubmit={this.props.handleSubmit(this.onSubmit)}
                      className="php-email-form brdr-dark transparent-bg brdr-radius-5"
                  >
                  {this.state.alertMessage ? (
                                        <div className={this.state.alertClass} role="alert">
                                            {this.state.alertMessage}
                                        </div>
                                    ) : null}
                      <div className="row">
                        <div className="col-md-12">
                          <div className="black-heading mb-3">
                            <h3 className="text-left">Contact us</h3>
                          </div>                            
                        </div>
                        <div className="col-md-6 mb-3">
                         <Field name="first_name" type="text" id="first_name" validate={[required, whiteSpace]} placeholder={`First Name`} component={renderInputNormal} />
                        </div>
                        <div className="col-md-6 mb-3">
                          <Field name="last_name" type="text" id="last_name" validate={[required, whiteSpace]} placeholder={`Last Name`} component={renderInputNormal} />
                        </div>

                        <div className="col-md-6 mb-3">
                         <Field name="email" type="email" id="email" validate={[required, email]} placeholder={`Email`} component={renderInputNormal} />
                        </div>
                        <div className="col-md-6 mb-3">
                           <Field name="phone_number" id="phone_number" type="text" validate={[number, phoneNumber, required]} placeholder={`Phone Number`} component={renderInputNormal} />
                        </div>

                        <div className="col-md-12 mb-3">
                          <Field name="message" id="message" validate={[required]} placeholder={`Please write something for us`} component={renderTextArea} />
                        </div>

                        <div className="col-md-12 text-center mb-3">
                          <div className="loading">Loading</div>
                          <div className="error-message"></div>
                          <div className="sent-message">Your message has been sent. Thank you!</div>

                          <button type="submit" className="btn btn-primary py-2 px-4">Send Message</button>
                        </div>

                      </div>
                    </form>

                  </div>


                  <div className="col-md-12" data-aos="fade-up" data-aos-delay="300">
                    <div className="p-4 brdr-dark transparent-bg brdr-radius-5">
                      <iframe style={mystyle} title="map"
                      src={mapUrl} frameBorder="0" allowFullScreen></iframe>
                    </div>
                  </div>

                </div>

              </div>
          </div>



        <FooterOnIndex />

    </div>
    );
  }
}

const mapStateToProps = state => {
    return {
        response: state.service.getContactResponse,
        isLoading: state.service.isLoading
    };
};

export default connect(mapStateToProps, {
    setLoader,
    contactForm,
    contactUsSubmit
})(reduxForm({ form: "contactForm" })(Contactus));  
