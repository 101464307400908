
import React, { Component } from "react";
import { connect } from "react-redux";
//import { Link } from "react-router-dom";
//import { PATHS } from "../../constants";


import "../../App.css";
import { LoaderScreen, NavOnDashboard, FooterOnDashboard, Sidebar } from "../includes";
import {  setLoader, userInfo, fetchMyTopic, deleteMyTopic } from "../../actions";

import ReactHtmlParser from 'react-html-parser';
//import {IMAGE_BASE_PATH} from "../../constants";
import  {add3dots, titleCase}  from "../../utils/general";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
//import {RunningCourse} from "../myTopic";

import {  faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
/*
 TYPES
*/

type Props = {
  history: Object,
  fetchTopic: Function,
  fetchSubCategory: Function,
  setLoader: Function,
  isLoading: boolean
};

const authImgStyle = {
  height: '134px',
  width: '360px',
};

class MyTopic extends Component<Props> {

  constructor(props){
       super(props)
       this.state = {
          inputValue:'',
          isAuth : false,
       }
      
    }

  componentDidMount() {

    this.props.setLoader(true);
    const user = localStorage.getItem('isAuth') ? true : false;
    this.setState({ isAuth:user });

    this.props.fetchMyTopic();

   
    this.props.userInfo().then(result => {
        if (this.props.userDetail) {
            this.props.setLoader(false);
        }
    })
   
  }

  handleChange = (event) => {
    this.setState({value: event.target.value});
  }

   handleView (e,topic_id) {
     e.preventDefault();
    this.props.history.push('/topic/' + topic_id)
  }

  handleRemove = (e, mytopic_id) => {
     e.preventDefault();
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure want to Delete this topic?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.deleteMyTopic(mytopic_id)
            
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    });
  }

 strip = (html) =>{
   let doc = new DOMParser().parseFromString(html, 'text/html');
   return doc.body.textContent || "";
}


   handleChange = e => {
       this.setState({ inputValue: e.target.value });
   };

   renderList = () => {

      let  { myTopicResponse, imagePath } = this.props;
      const  { inputValue } = this.state;
      console.log(inputValue);
      if(inputValue && myTopicResponse && myTopicResponse.length>0){

         myTopicResponse =  myTopicResponse.filter(mytopic => {

            return mytopic.topic.title.toLowerCase().includes(inputValue.toLowerCase()) || this.strip(mytopic.topic.description).replace(/&.*;/g,'').toLowerCase().includes(inputValue.toLowerCase())
         })
      }


        // return myTopicResponse && myTopicResponse.length>0 ? 
        //  myTopicResponse.map((mytopic, i) => (
        //   <div className="col-lg-4 col-md-12 stretch-card" key ={i}>
        //     <div className="card">
        //             <img src={ mytopic.topic.image ? (imagePath.topic + mytopic.topic.image) :"https://mdbootstrap.com/img/new/standard/nature/184.jpg"} className="card-img-top" alt="..." />
        //             <div className="card-body">
        //                 <h5 className="card-title">{mytopic.topic.title}</h5>
        //                 <p className="card-text">
        //                     {ReactHtmlParser(add3dots(mytopic.topic.description, 150))}
        //                 </p>
        //                 <p> No. of Chapter - {mytopic.topic.chapter_count} </p>
        //                 <button  onClick={() => this.handleView(mytopic.topic.slug)} className="btn btn-primary"><i className="fa fa-eye" aria-hidden="true"></i></button>{' '}
        //                 <button className="btn btn-danger" onClick={() => this.handleRemove(mytopic.topic_id)} ><FontAwesomeIcon icon={faHeartBroken} /></button>
        //             </div>
        //         </div>
        //     </div>
        //   ))
        //   :"" 

        {/*<div className="col-lg-4 col-md-12 stretch-card" key ={i}>
                    <div className="card">

                        <img className="card-img-top img-fluid" src={ mytopic.topic.image ? (imagePath.topic + mytopic.topic.image) :"/assets/images/card-1.jpg"} alt="Card" />
                        <div className="card-body">
                            <div className="ct-row">
                                <h4 className="card-title">{mytopic.topic.title}</h4>
                                <a href="#!" onClick={(e) => this.handleRemove(e,mytopic.topic_id)} className="ct-fav ml-auto addtofav"><FontAwesomeIcon icon={faHeart} /></a>
                            </div>
                            <p>
                               {ReactHtmlParser(add3dots(mytopic.topic.description, 50))}
                            </p>
                            
                            <a href="#!" onClick={(e) => this.handleView(e, mytopic.topic.slug)} className="btn waves-effect waves-light btn-rounded btn-primary pl-4 pr-4">View more</a>
                        </div>
                    </div>
                  </div>*/}

        return myTopicResponse && myTopicResponse !== undefined && myTopicResponse.length>0 ? 
                 myTopicResponse.map((mytopic, i) => (   
                 
                  <div className="col-lg-4 col-md-12 stretch-card" data-aos="fade-up" data-aos-delay="100" key={i}>
                    <div className="card">
                    <div className="ck-banner">
                        <img className="card-img-top img-fluid" src={ mytopic.topic.image ? (imagePath.topic + mytopic.topic.image) :"/assets/images/card-1.jpg"} alt="Card" style={authImgStyle} />
                        </div>
                        <div className="card-body ck-editor">
                            <div className="ct-row">
                                <h4 className="card-title">{titleCase(add3dots(mytopic.topic.title,20))}</h4>
                            </div>
                            <p>
                              <div className="cat-title-info">
                                {/* ReactHtmlParser(add3dots(mytopic.topic.description,220)) */}
                                <HTMLEllipsis
                                  isClamped={false}
                                  unsafeHTML={mytopic.topic.description}
                                  maxLine="2"
                                  ellipsis="..."
                                  ellipsisHTML="<i>... read more</i>"
                                  basedOn="letters"
                                />
                              </div>
                            </p>
                           
                            
                            <a href="#!" onClick={(e) => this.handleView(e, mytopic.topic.slug)} className="btn waves-effect waves-light btn-rounded btn-primary pl-4 pr-4">View more</a>
                        </div>
                    </div>
                </div> 
             ))
          :<div className="col-lg-12 col-md-12 stretch-card" data-aos="fade-up" data-aos-delay="100">
              <div className="card">
                  <div className="card-body">
                  <p>No Record Found</p>
                  </div>
              </div>
          </div>    
  }


  render() {

    console.log(this.props)
   
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    return (
     <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
        data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <NavOnDashboard user={this.props.userDetail}/>
        <Sidebar />
       <div className="page-wrapper">
       <div className="page-m-height">
            <div className="page-breadcrumb">
                <div className="row">
                    <div className="col-12 col-sm-8 align-self-center mb-3 mb-sm-0">
                        <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">My Topics</h3>                        
                    </div>
                    <div className="col-12 col-sm-4 align-self-center search-form">
                       
                         <span className="nav-link pl-0">
                            
                                  <div className="customize-input">
                                      <input className="form-control custom-shadow custom-radius border-0 bg-white"
                                          type="search" placeholder="Search" aria-label="Search" onChange={this.handleChange} value={this.state.inputValue}/>
                                          <i className="fas fa-search form-control-icon"></i>
                                  </div>
                              
                          </span>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">

                  
                                      { this.renderList()}
                                      
                                     
                      
                       
                 

               {/* 
                <RunningCourse search={this.state.inputValue} />
                this.props.myTopic ? 
                    this.props.myTopic.map((mytopic, i) => (
                        <div className="col-lg-4 col-md-12 stretch-card" key={i}>
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title brdrbtm-lblue pb-3 mb-3 text-c-blue">{mytopic.topic.title}</h4>
                                <div className="course-detail mb-3">
                                    <img src={mytopic.topic.image ? IMAGE_BASE_PATH + `uploads/topic/${mytopic.topic.image}`: "/assets/images/a.png"} alt="" className="imh-fluid" />
                                    <p>
                                       {mytopic.topic.description ? ReactHtmlParser(add3dots(mytopic.topic.description, 150)) : ''}
                                    </p>
                                </div> 
                                
                                <div className="cat-info-list">
                                    <h5 className="card-title">Topics</h5>
                                    <ul className="list-style-none pl-3 mb-3">
                                        <li>
                                            <a href="void(0)">
                                                <i className="fa fa-check text-info"></i> 
                                                Lorem ipsum dolor sit amet
                                            </a>
                                        </li>
                                        <li>
                                            <a href="void(0)">
                                                <i className="fa fa-check text-info"></i>
                                                Consectetur adipiscing elit
                                            </a>
                                        </li>
                                        <li>
                                            <a href="void(0)">
                                                <i className="fa fa-check text-info"></i>
                                                Integer molestie lorem at massa 
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <a href="javacript:void(0)" className="btn waves-effect waves-light btn-rounded btn-primary pl-4 pr-4">View more</a>
                            </div>
                        </div>
                    </div>
                    ))
                    
                  :
                   <div className="col-lg-12 col-md-12 stretch-card">
                        <div className="card">
                            <div className="card-body">
                            <p>NO record found </p>
                            </div>
                        </div>
                    </div>
                 */ }
                </div>
            </div>
          </div>
          <FooterOnDashboard />
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
        isLoading: state.service.isLoading,
        userDetail: state.service.userDetailResponse ? state.service.userDetailResponse.data : '',
        myTopicResponse: state.service.myTopicResponse ? state.service.myTopicResponse.data : '',
        imagePath: state.service.userDetailResponse.path,
    };
};

export default connect(mapStateToProps, { setLoader, userInfo, fetchMyTopic, deleteMyTopic })(MyTopic);
