import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import loginBanner from "../../images/big/login-banner.svg"
import  webLogo  from "../../images/web-logo.svg"
import "../../App.css";
import { LoaderScreen } from "../includes";
import {  setLoader, forgotPassword } from "../../actions";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { renderEmailWithIcon } from "../../constants/redux-form";
import { required, email } from "../../constants/validation";
/*
 TYPES
*/

import { Link } from "react-router-dom";
import { PATHS } from "../../constants";


class Forget extends Component<Props> {

   constructor(props) {
    super(props);
    this.state = {
      alertMessage: "",
      alertClass: "",
      email: ""
    };
     //document.getElementById('canonical').setAttribute('href', window.location.href); 
  }

  componentDidMount() {
    AOS.init();

  }

   onSubmit = formValue => {
    this.props.setLoader(true);
    const email = formValue.email;
    this.setState({
      email: email
    });
    localStorage.setItem('email', email)
    let successMessage = "";
    let errorMessage = "";

    setTimeout(() => {
      this.props.forgotPassword({ email }).then(result => {
        console.log('forgot password',this.props.forgotPswdResponse)
        this.props.setLoader(true);
        if (this.props.forgotPswdResponse.success) {
          this.props.setLoader(false);
          this.props.reset();
          successMessage = this.props.forgotPswdResponse.message;
          this.setState({ alertMessage: successMessage, alertClass: "alert alert-success" });
          this.props.reset('forgetpassword');
        } else {

          this.props.setLoader(false);
          errorMessage = this.props.forgotPswdResponse.message;
          this.setState({ alertMessage: errorMessage, alertClass: "alert alert-danger" });
        }

        setTimeout(() => {
          this.setState({ alertMessage: "", alertClass: "" })
        }, 3000);

      });
    }, 1000);
  };

  render() {
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    return (
      <div className="main-wrapper">
        <div className="auth-wrapper d-flex no-block justify-content-center align-items-center position-relative auth-bg">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-11">
                        <div className="auth-box" data-aos="fade-up" data-aos-delay="400">
                            <div className="auth-left modal-bg-img">
                                <img src={loginBanner} className="img-fluid" alt="logo" />
                            </div>
                            <div className="auth-right bg-white">
                                <div className="p-4 p-md-5 auth-sec">
                                    <div className="text-center auth-logo mb-3 mb-md-4 mb-lg-5">
                                        <a href={PATHS.HOME}>
                                            <img src={webLogo} alt="wrapkit"/>
                                        </a>
                                    </div>
                                    <h2 className="mt-3 text-center">Forgot Password</h2>
                                    <p className="text-center">Enter your email address to receive a link.</p>
                                    <form
                                        name="forgetPasswordForm"
                                        className="form"
                                        onSubmit={this.props.handleSubmit(this.onSubmit)}
                                      >
                                      {this.state.alertMessage ? (
                                          <div className={this.state.alertClass} role="alert">
                                            {this.state.alertMessage}
                                          </div>
                                        ) : null}
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <Field icon="fas fa-envelope" name="email" type="email" validate={[required, email]} placeholder={`Email`} component={renderEmailWithIcon} />
                                            </div>

                                            <div className="col-lg-12 text-center mb-4">
                                                <button type="submit" className="btn btn-block btn-purple">Reset Password</button>
                                            </div>
                                            <div className="col-lg-12 auth-links text-center mb-2">
                                                <div className="mb-2"> 
                                                   <Link to={PATHS.LOGIN}>Sign In?</Link>
                                                </div>
                                                Don't have an account? <Link to={PATHS.REGISTER}>Sign Up</Link>
                                                
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>        
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.service.isLoading,
  forgotPswdResponse: state.service.forgotPswdResponse,
});

export default connect(mapStateToProps, {
  setLoader,
  forgotPassword,
})(reduxForm({ form: "forgetPasswordForm" })(Forget));

