import React, { Component } from "react";
import { connect } from "react-redux";

import "../../App.css";
import {  setLoader, userInfo, updateProfile, fetchAllMedium } from "../../actions";

import { PATHS } from "../../constants";
import { LoaderScreen, NavOnDashboard, FooterOnDashboard, Sidebar } from "../includes";
import { Field, reduxForm } from "redux-form";
import { required, phoneNumberValidation, whiteSpace } from "../../constants/validation";
import { renderInputNormal, renderFile, SelectField } from "../../constants/redux-form";

/*
 TYPES
*/

type Props = {
  history: Object,
};




class Profile extends Component<Props> {

  constructor(props) {
    super(props);
    this.state = {
       alertMessage: "",
       alertClass: "",
       imagePreviewUrl: '',
       file: '',
       image:'',
    };
  }

  componentDidMount() {
    
    if (!localStorage.getItem("access_token")) {
      this.props.history.push(PATHS.LOGIN)
      localStorage.clear();
    }
   
    this.props.setLoader(true);
    this.props.userInfo().then(result => {
        if (this.props.userDetail) {
            this.props.setLoader(false);
        }
    })

  }

   fileChangedHandler = (event) => {
        event.preventDefault();
        this.setState({
            image: event.target.files[0] ? event.target.files[0] : ''
        })
        let reader = new FileReader();
        let file = event.target.files[0];
        reader.onloadend = () => {
            this.setState({
                imagePreviewUrl: reader.result
            });
        }
        reader.readAsDataURL(file);
    }

  onSubmit = formValue => {
        let errorMsg = "";
        // console.log(this.props.viewProfileResponse.data.avatar_url)
        if (this.props.userDetail && !this.props.userDetail.profile_image && !this.state.image) {
            errorMsg = "Please select Image";
            this.setState({
                errorMsg: errorMsg,
                alertClass: "alert alert-danger"
            });
            return false
        } else {
            this.setState({
                errorMsg: "",
                alertClass: ""
            });
        }

        let input = new FormData();
        input.append('image', this.state.image ? this.state.image : '');
        input.append('name', formValue.name);
        input.append('mobile_number', formValue.mobile_number);
        setTimeout(() => {
            this.props.updateProfile(input).then(result => {
             
                this.props.setLoader(true);
                if (this.props.response.success) {
                    this.props.setLoader(false);
                    this.setState({ alertMessage: this.props.response.message, alertClass: "alert alert-success" });

                } else {
                    this.props.setLoader(false);
                    this.setState({ alertMessage: this.props.response.message, alertClass: "alert alert-danger" });
                }

                this.props.userInfo();

                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);
            });
        }, 500);
    };

  render() {
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    console.log(this.props.imagePath)

    return (
     <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
        data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <NavOnDashboard user={this.props.userDetail}/>
        <Sidebar />
        <div className="page-wrapper">
          <div className="page-m-height">
            <div className="container-fluid">
              <div className="row">
                    <div className="col-12 stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title brdrbtm-lblue pb-3 mb-3">Update Profile</h4>

                            <form
                                name="profileForm"
                                onSubmit={this.props.handleSubmit(this.onSubmit)}
                                className="mt-4"
                              >   
                              {this.state.alertMessage ? (
                                            <div className={this.state.alertClass} role="alert">
                                                {this.state.alertMessage}
                                            </div>
                                        ) : null}                              
                                <div className="form-group row">
                                    <label htmlFor="name" className="col-md-2 col-form-label">Name<span className="required">*</span></label>

                                    <div className="col-md-10">
                                         <Field className="form-control input_user" name="name" type="text" id="name" validate={[required, whiteSpace]} placeholder={`Name`} component={renderInputNormal} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="email" className="col-md-2 col-form-label">Email<span className="required">*</span></label>

                                    <div className="col-md-10">
                                        <Field disabled={true} className="form-control input_user" name="email" type="text" id="email" validate={[required]} placeholder={`Email`} component={renderInputNormal} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="mobile_number" className="col-md-2 col-form-label">{`Phone Number`}<span className="required">*</span></label>

                                    <div className="col-md-10">
                                    <Field className="form-control input_user" name="mobile_number" type="text" id="mobile_number" validate={[required, phoneNumberValidation]}  component={renderInputNormal} />
                                        
                                    </div>
                                </div>

                                 <div className="form-group row">
                                    <label htmlFor="topic" className="col-md-2 col-form-label"> Medium<span className="required">*</span></label>

                                    <div className="col-md-10">
                                        <Field
                                          className="form-control input_user" 
                                          name="medium_id" type="text"
                                          disabled={true} 
                                          id="medium_id" validate={[required]} 
                                          component={SelectField} >
                                             <option value="">Please Select</option>
                                             {this.props.allMedium ? this.props.allMedium.map((data, index) => (<option value={data.id} key={index}>{data.title}</option>)) : ''}
                                          </Field>
                                    </div>
                                </div>

                                
                                
                                  <div className="form-group row">
                                    <label htmlFor="subject_expertise" className="col-md-2 col-form-label">Image<span className="required">*</span></label>

                                    <div className="col-md-4">
                                       <Field
                                            type="file"
                                            onChange={this.fileChangedHandler}
                                            id="image"
                                            name="image"
                                            component={renderFile}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                    { this.state.imagePreviewUrl ? <img src={this.state.imagePreviewUrl} alt="user" className="rounded-circle" width="50" height="50" /> : ( this.props.userDetail &&  this.props.userDetail.profile_image) ? <img src={this.props.imagePath.user + this.props.userDetail.profile_image} alt="user" className="rounded-circle" width="50" height="50" /> : null }
                                    </div>
                                </div>
                                <button className="btn btn-sm btn-primary float-right" type="submit">Update</button>
                             </form>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
            <FooterOnDashboard />
        </div>
    </div>
    );
  }
}

const mapStateToProps = state => ({
  userDetail: state.service.userDetailResponse ? state.service.userDetailResponse.data : '',
  isLoading: state.service.isLoading,
  initialValues: {
      name: state.service.userDetailResponse.data.name,
      email: state.service.userDetailResponse.data.email,
      mobile_number: state.service.userDetailResponse.data.mobile_number,
      medium_id: state.service.userDetailResponse.data.medium_id,
      
    },
   response: state.service.updateProfileResponse ? state.service.updateProfileResponse : '', 
   allMedium : state.service.mediumResponse ? state.service.mediumResponse.data : '', 
   imagePath: state.service.userDetailResponse.path 
});

export default connect(mapStateToProps, {
  setLoader,
  userInfo,
  updateProfile,
  fetchAllMedium
})(reduxForm({ form: "profileForm" })(Profile));
