//Use axios to call API handle

//import axios from 'axios';

import { MENU_LIST, SET_LOADER, FETCH_CMS_CONTENT, FETCH_FAQ, CONTACT_FORM, CONTACT_US,
  USER_LOGOUT,
 CATCH_ERROR,
 USER_LOGIN_SUCCESS,
 USER_LOGIN_FAIL,
 USER_REGISTER_SUCCESS,
 USER_REGISTER_FAIL,
 FORGOT_PSWD,
 RESET_PSWD,
 VERIFY_EMAIL,
 VERIFY_EMAIL_SEND,
 USER_INFO,
 UPDATE_INFO,
 FETCH_SUB_CATEGORY,
 FETCH_TOPIC,
 FETCH_TOPIC_INFO,
 FETCH_CATEGORY,
 CHANGE_PASSWORD,
 FETCH_MY_TOPIC,
 DELETE_MY_TOPIC,
 ADD_MY_TOPIC,
 FETCH_REQUESTED_SESSION,
 ADD_SESSION_FORM,
 FETCH_ALL_TOPIC,
 ADD_SESSION_REQUEST,
 LIVE_SESSION_EVENT,
 FETCH_NOTIFICATION,
 MARK_READ_NOTIFICATION,
 DASHBOARD_DATA,
 FETCH_QUIZ_INFO,
 INITIALTESTDATA,
 UPDATE_TEST_ATTEMPTS,
 GET_QUIZ_RESULT,
 FETCH_PRACTICE_TEST,
 FETCH_ALL_MEDIUM,
 UPDATE_MEDIUM,
 SESSION_DETAIL,
 FETCH_WALLET,
 FETCH_PLANS,
 MAKE_SUBSCRIPTION,
 FETCH_MY_SUBSCRIPTION,
 REQUESTED_SESSION_DETAIL,
 PAY_SESSION,
 EVENT,
EVENT_DETAIL,
USER_IMAGE_BY_ID,
USER_SUB_CAT  } from "./types";

import { API_PATHS } from "../constants";
import axios from "axios";


// Set loader
export const setLoader = loader => dispatch => {
  dispatch({ type: SET_LOADER, payload: loader });
};

export const userLogout = loader => dispatch => {
  console.log(loader);
  dispatch({ type: USER_LOGOUT, payload: loader });
};

export const addSessionForm = (setLoader, formValues) => async dispatch => {
  try {
    dispatch({ type: ADD_SESSION_FORM, payload: formValues });
  } catch (e) {
    setLoader(false);
    
  }
};

// Fetch list
export const fetchList = setLoader => async dispatch => {
  try {
    const response = [
      { code: "en", name: "English" },
      { code: "fr", name: "French" }
    ];
    dispatch({ type: MENU_LIST, payload: response });
  } catch (e) {
    setLoader(false);
  }
};




// Fetch page data
export const fetchCmsPage = (slug) => async dispatch => {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = await instance.get(API_PATHS.FETCH_CMS_PAGE +'/'+slug);
   
      dispatch({ type: FETCH_CMS_CONTENT, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
};


// Fetch FAQ list
export const fetchFaqList = (slug) => async dispatch => {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = await instance.post(API_PATHS.FETCH_FAQ);
   
      dispatch({ type: FETCH_FAQ, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
};


export const contactForm = (setLoader, formValues) => async dispatch => {
  try {
    dispatch({ type: CONTACT_FORM, payload: formValues });
  } catch (e) {
    setLoader(false);
  }
};


export const contactUsSubmit = (formValues) => async dispatch => {
  try {
    let response = [];
    const instance = axios.create();
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    response = await instance.post(API_PATHS.CONTACT_US, {
      email: formValues.email,
      first_name: formValues.first_name,
      last_name: formValues.last_name,
      phone_number: formValues.phone_number,
      message: formValues.message
    });

    dispatch({ type: CONTACT_US, payload: response.data });
  } catch (e) {
    setLoader(false);
    console.log(e);
  }
};

export const userLogin = ({ email, password }) => async dispatch => {
  try {
    console.log(email, password)
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    const response = await instance.post(API_PATHS.USER_LOGIN_API, {
      email: email,
      password: password
    });

    if (response.data.success) {

      console.log(response.data);

      await localStorage.setItem("access_token", response.data.data.token);
      await localStorage.setItem("isAuth", true);
    //  await localStorage.setItem("userMediumId", response.data.data.medium_id);
      await localStorage.setItem("user_id", response.data.data.id);
      await localStorage.setItem("userSubCat", response.data.data.user_subcat);
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: response.data,
        token: response.data.data.token
      });
    } else {
      dispatch({ type: USER_LOGIN_FAIL, payload: response.data, token: "" });
    }
  } catch (e) {
    // setLoader(false);
    dispatch({ type: CATCH_ERROR, payload: e.message });
    setLoader(false);
  }
};



// user registration 
export const userRegistration = formValues => async dispatch => {
 // const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";

  //  instance.defaults.headers.common["Authorization"] = "Bearer " + token;

    const response = await instance.post(
      API_PATHS.REGISTRATION_API,
      formValues
    );

    dispatch({ type: USER_REGISTER_SUCCESS, payload: response.data });

    // if (response.data.success) {
    //    dispatch({ type: USER_REGISTER_SUCCESS, payload: response.data });
    // } else {
    //   dispatch({ type: USER_REGISTER_FAIL, payload: response.data, token: "" });
    // }

   
  } catch (e) {
    dispatch({ type: CATCH_ERROR, payload: e.message });
    setLoader(false);
  }
};


export const forgotPassword = ({ email }) => async dispatch => {
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    const response = await instance.post(API_PATHS.FORGOT_PSWD_API, {
      email: email
    });
    console.log(response)
    dispatch({ type: FORGOT_PSWD, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

//Reset password 

export const resetPswd = formValues => async dispatch => {
  //console.log(formValues)
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    const response = await instance.post(API_PATHS.RESET_PSWD_API, formValues);
       dispatch({ type: RESET_PSWD, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};

// email verification

export const verifyEmail = formValues => async dispatch => {
  //console.log(formValues)
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    const response = await instance.post(API_PATHS.VERIFY_EMAIL_API, formValues);
       dispatch({ type: VERIFY_EMAIL, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};


// email verification lin send to user
export const verifyEmailSend = formValues => async dispatch => {
  console.log(formValues)
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    const response = await instance.post(API_PATHS.VERIFY_EMAIL_SEND_API, formValues);
       dispatch({ type: VERIFY_EMAIL_SEND, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};



// fetch user info
export const userInfo = formValues => async dispatch => {
  const token = await localStorage.getItem("access_token");
  const medium = await localStorage.getItem("access_token") || null;
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";

    instance.defaults.headers.common["Authorization"] = "Bearer " + token;

    const response = await instance.post(
      API_PATHS.USER_INFO_API, formValues
    );

    if (!medium) {
     //   localStorage.setItem("userMediumId", response.data.data.medium_id);
      } 

    dispatch({ type: USER_INFO, payload: response.data });
   
  } catch (e) {
    dispatch({ type: CATCH_ERROR, payload: e.message });
    setLoader(false);
  }
};


// update user profile

export const updateProfile = (input) => async dispatch => {

  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    // instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;

    const response = await instance.post(
      API_PATHS.UPDATE_INFO_API, input

    );

    dispatch({ type: UPDATE_INFO, payload: response.data });
  } catch (e) {
    setLoader(false);
  }
};


//Fetch sub category according to category

export const fetchSubCategory = (slug) => async dispatch => {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = await instance.post(API_PATHS.FETCH_SUB_CATEGORY_API, {slug:slug});
   
      dispatch({ type: FETCH_SUB_CATEGORY, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
};


export const fetchAllTopic = () => async dispatch => {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = await instance.post(API_PATHS.FETCH_ALL_TOPIC_API);
   
      dispatch({ type: FETCH_ALL_TOPIC, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
};


//Fetch topics according to category and sub category 

export const fetchTopic = (formvalues) => async dispatch => {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = await instance.post(API_PATHS.FETCH_TOPIC_API, formvalues);
   
      dispatch({ type: FETCH_TOPIC, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
};

//Fetch topics info according to topic id 

export const fetchTopicInfo = (formvalues) => async dispatch => {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = await instance.post(API_PATHS.FETCH_TOPIC_INFO_API, formvalues);
   
      dispatch({ type: FETCH_TOPIC_INFO, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
};


//Fetch all category 

export const fetchCategory = () => async dispatch => {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = await instance.post(API_PATHS.FETCH_CATEGORY_API);
   
      dispatch({ type: FETCH_CATEGORY, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
};


//User change password
export const changePassword = (formValues) => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    let response = [];
    const instance = axios.create();
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    response = await instance.post(API_PATHS.CHANGE_PASSWORD_API, {
      old_password: formValues.old_password,
      password: formValues.password
    });

    dispatch({ type: CHANGE_PASSWORD, payload: response });
  } catch (e) {
    setLoader(false);
    
  }
};


//Fetch All my topics

export const fetchMyTopic = () => async dispatch => {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = await instance.post(API_PATHS.FETCH_MY_TOPIC_API)
   
      dispatch({ type: FETCH_MY_TOPIC, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
};

//Delete  my topic
export const deleteMyTopic = (index) => async dispatch =>  {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      await instance.post(API_PATHS.REMOVE_MY_TOPIC_API, {
        topic_id:index
      })
   
      dispatch({
          type: DELETE_MY_TOPIC,
          payload: index
       })
    } catch (e) {
      setLoader(false);
    }
   
};


//Add topic in my topic
export const addMyTopic = (index) => async dispatch =>  {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      
      const response = await instance.post(API_PATHS.ADD_MY_TOPIC_API, {
                          topic_id:index
                        })
   
      dispatch({ type: ADD_MY_TOPIC, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
   
};


//Add topic in my topic
export const fetchRequestedSession = (index) => async dispatch =>  {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      
      const response = await instance.post(API_PATHS.REQUESTED_SESSION_API)
   
      dispatch({ type: FETCH_REQUESTED_SESSION, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
   
};

export const addSessionRequest = (formValues) => async dispatch =>  {

  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      
      const response = await instance.post(API_PATHS.ADD_SESSION_REQUEST_API, formValues)
   
      dispatch({ type: ADD_SESSION_REQUEST, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
   
};


// live session event for calendar
export const liveSessionEvent = (formValues) => async dispatch =>  {

  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      
      const response = await instance.post(API_PATHS.LIVE_SESSION_EVENT_API)
   
      dispatch({ type: LIVE_SESSION_EVENT, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
   
};



//fetch notification
export const fetchNotification = () => async dispatch =>  {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      
      const response = await instance.post(API_PATHS.FETCH_NOTIFICATION_API);
     
   
      dispatch({ type: FETCH_NOTIFICATION, payload: response.data });
    } catch (e) {
      if (e.response) {
        console.log(e.response.status);
        if (e.response.status === 401) {
          localStorage.clear()
        }
      }
      
      
      setLoader(false);
    }
   
};


// notification mark as read
export const markReadNotification = (id) => async dispatch =>  {
 
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      
      const response = await instance.post(API_PATHS.MARK_READ_NOTIFICATION_API, {
        'id':id
      })
   
      dispatch({ type: MARK_READ_NOTIFICATION, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
   
};




//fetch All dashbaord data
export const fetchDashboardData = () => async dispatch =>  {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      
      const response = await instance.post(API_PATHS.FETCH_DASHBOARD_DATA_API)
   
      dispatch({ type: DASHBOARD_DATA, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
   
};



export const fetchQuizInfo = (formvalues) => async dispatch => {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = await instance.post(API_PATHS.FETCH_QUIZ_INFO_API, formvalues);
   
      dispatch({ type: FETCH_QUIZ_INFO, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
};


export const getInitialTestData = (jsonData, id) => async dispatch => {
  dispatch({ type: INITIALTESTDATA, payload: jsonData });
}


export const updateTestAttempts = formValues => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;

    const response = await instance.post(
      API_PATHS.UPDATE_TEST_ATTEMPTS_API,
      formValues
    );
    dispatch({ type: UPDATE_TEST_ATTEMPTS, payload: response.data });
  } catch (e) {
    
    setLoader(false);
    // dispatch({ type: LOGOUT_FAIL, payload: [] });
  }
};

export const getQuizResult = formValues => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;

    const response = await instance.post(
      API_PATHS.GET_QUIZ_RESULT_API,
      formValues
    );
    dispatch({ type: GET_QUIZ_RESULT, payload: response.data });
  } catch (e) {
    
    setLoader(false);
    // dispatch({ type: LOGOUT_FAIL, payload: [] });
  }
};



// fetch all  practice test
export const fetchPracticeTest = () => async dispatch => {
  const token = await localStorage.getItem("access_token");
  try {
    const instance = axios.create();
    instance.defaults.headers.common["Content-Type"] = "application/json";
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;

    const response = await instance.post(
      API_PATHS.FETCH_PRACTICE_TEST_API
    );
    dispatch({ type: FETCH_PRACTICE_TEST, payload: response.data });
  } catch (e) {
    
    setLoader(false);
    // dispatch({ type: LOGOUT_FAIL, payload: [] });
  }
};


export const fetchAllMedium = () => async dispatch => {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = await instance.post(API_PATHS.FETCH_ALL_MEDIUM_API);
   
      dispatch({ type: FETCH_ALL_MEDIUM, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
};

export const updateMedium = (formValues) => async dispatch =>  {

  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      
      const response = await instance.post(API_PATHS.UPDATE_MEDIUM_API, formValues)
     
      if (response.data.success) {
        localStorage.setItem("userMediumId", response.data.data.medium_id);
        dispatch({ type: UPDATE_MEDIUM, payload: response });
      } 
    } catch (e) {
      setLoader(false);
    }
   
};


// Fetch session info data
export const fetchSessionInfo = (formValues) => async dispatch => {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = await instance.post(API_PATHS.SESSION_DETAIL_API, formValues);
   
      dispatch({ type: SESSION_DETAIL, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
};


// Fetch Wallet info data
export const fetchWalletInfo = () => async dispatch => {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = await instance.post(API_PATHS.FETCH_WALLET_API);
   
      dispatch({ type: FETCH_WALLET, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
};

// Fetch plans info data
export const fetchPlans = () => async dispatch => {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = await instance.post(API_PATHS.FETCH_PLANS_API);
   
      dispatch({ type: FETCH_PLANS, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
};


// Fetch plans info data
export const makeSubscription = () => async dispatch => {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = await instance.post(API_PATHS.MAKE_SUBSCRIPTION_API);
      dispatch({ type: MAKE_SUBSCRIPTION, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
};


// Fetch all subscription data for a user
export const fetchMySubscription = () => async dispatch => {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = await instance.post(API_PATHS.FETCH_MY_SUBSCRIPTION_API);
      dispatch({ type: FETCH_MY_SUBSCRIPTION, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
};


// Fetch session info data
export const fetchRequestedSessionInfo = (formValues) => async dispatch => {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = await instance.post(API_PATHS.REQUESTED_SESSION_DETAIL_API, formValues);
   
      dispatch({ type: REQUESTED_SESSION_DETAIL, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
};


// pay session request
export const paySession = (formValues) => async dispatch => {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = await instance.post(API_PATHS.PAY_SESSION_API, formValues);
   
      return dispatch({ type: PAY_SESSION, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
};




// Session event for session type = 3 means all
export const events = (formValues) => async dispatch =>  {
  console.log(API_PATHS.EVENT_API)

  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      
      const response = await instance.post(API_PATHS.EVENT_API)
   
      dispatch({ type: EVENT, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
   
};

// Fetch session info data
export const fetchEventInfo = (formValues) => async dispatch => {
  setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
     instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = await instance.post(API_PATHS.EVENT_DETAIL_API, formValues);
   
      dispatch({ type: EVENT_DETAIL, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
};

export const fetchUserImage = (formValues) => async dispatch => {
  setLoader(true);
 // const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
   //  instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = await instance.post(API_PATHS.USER_IMAGE_BY_ID_API, formValues);
   
      dispatch({ type: USER_IMAGE_BY_ID, payload: response.data });
    } catch (e) {
      setLoader(false);
    }
};



export const updateUserSubCat = (formValues) => async dispatch => {
 // setLoader(true);
  const token = await localStorage.getItem("access_token");
    try {
      const instance = axios.create();
      instance.defaults.headers.common["Content-Type"] = "application/json";
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      const response = await instance.post(API_PATHS.UPDATE_USER_SUB_CAT, formValues);
   
      dispatch({ type: USER_SUB_CAT, payload: response.data });
    } catch (e) {
    //  setLoader(false);
    }
};