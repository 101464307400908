import React from "react";
import * as Styled from "../pages/page.styled";
import * as ALL from "@material-ui/core";

type Props = {
  input: Object,
  label: string,
  type: string,
  meta: Object,
  showTime: string,
  children: Object,
  data: string,
  value: string
};

const renderInputNormal = ({
  placeholder,
  id,
  className,
  data,
  input,
  type,
  disabled,
  meta: { touched, error }
}: Props) => {
  return (
    <div className="input-group mb-3">
      <input
        {...input}
        type={type}
        placeholder={placeholder}
        id={id}
        className="form-control"
        disabled={disabled}
      />
      {touched && error && <Styled.Error>{error}</Styled.Error>}
    </div>
  );
};

const renderTextArea = ({
  placeholder,
  id,
  input,
  type,
  meta: { touched, error }
}: Props) => {
  // console.log('input ',input);
  return (
    <div className="input-group mb-3">
      <textarea
        {...input}
        rows="5"
        className="form-control"
        placeholder={placeholder}
      ></textarea>
      {touched && error && <Styled.Error>{error}</Styled.Error>}
    </div>
  );
};

const renderPassword = ({
  placeholder,
  id,
  input,
  label,
  type,
  meta: { touched, error }
}: Props) => (
  <div className="input-group mb-3">
    <input
      {...input}
      type={type}
      placeholder={placeholder}
      className="form-control"
      id={id}
    />
    {touched && error && <Styled.Error>{error}</Styled.Error>}
  </div>
);

const renderEmail = ({
  placeholder,
  id,
  input,
  label,
  type,
  meta: { touched, error }
}: Props) => (
  <div className="input-group mb-3">
    <input
      {...input}
      type={type}
      placeholder={placeholder}
      className="form-control"
      id={id}
    />
    {touched && error && <Styled.Error>{error}</Styled.Error>}
  </div>
);

const renderEmailWithIcon = ({
  icon,
  placeholder,
  id,
  input,
  label,
  type,
  meta: { touched, error }
}: Props) => (
  <div className="input-group mb-3">
    <div className="input-group-append">
      <span className="input-group-text">
        <i className={icon}></i>
      </span>
    </div>
    <input
      {...input}
      type={type}
      placeholder={placeholder}
      className="form-control"
      id={id}
    />
    {touched && error && <Styled.Error>{error}</Styled.Error>}
  </div>
);

const renderFile = ({
  placeholder,
  id,
  data,
  input,
  label,
  type,
  meta: { touched, error }
}: Props) => {
  return (
    <div>
      <input
        {...input}
        value={data}
        accept="image/x-png,image/gif,image/jpeg"
        type="file"
        placeholder={placeholder}
        id={id}
        style={{ marginBottom: "15px" }}
      />
      {touched && error && <Styled.Error>{error}</Styled.Error>}
    </div>
  );
};

const renderVideoFile = ({
  placeholder,
  id,
  data,
  input,
  label,
  type,
  meta: { touched, error }
}: Props) => {
  return (
    <div>
      <input
        {...input}
        value={data}
        accept="video/*"
        type="file"
        placeholder={placeholder}
        id={id}
        style={{ marginBottom: "15px" }}
      />
      {touched && error && <Styled.Error>{error}</Styled.Error>}
    </div>
  );
};

const SelectField = ({
  input,
  data,
  id,
  icon,
  disabled,
  meta: { touched, error },
  children
}: Props) => (
  <div className="input-group mb-3">
    <select {...input} id={id} className="form-control" disabled={disabled}>
      {children}
    </select>
    {touched && error && <Styled.Error>{error}</Styled.Error>}
  </div>
);

const renderRadioButton = ({
  input,
  label,
  index,
  id,
  value,
  type,
  meta: { touched, error },
  ...rest
}: Props) => (
  <div className="ob-row" key={index}>
    <input
      {...input}
      type={type}
      id={id}
      value={id}
      className="css-checkbox"
      checked={input.value === rest.value}
    />
    <label htmlFor={id} className="css-label cb0 radGroup1">
      {label}
    </label>
    {touched && error && <Styled.Error>{error}</Styled.Error>}
  </div>
);

const renderRadioButton4 = ({
  input,
  label,
  value,
  type,
  meta: { touched, error }
}: Props) => (
  <ALL.FormControl>
    <ALL.RadioGroup {...input}>
      <ALL.FormControlLabel
        className={touched && error && "error"}
        type={type}
        autoComplete="off"
        control={<ALL.Radio />}
        label={label}
        value={label}
      />
    </ALL.RadioGroup>
  </ALL.FormControl>
);

const renderRadioButton2 = ({
  input,
  label,
  label1,
  value,
  id,
  type,
  meta: { touched, error }
}: Props) => (
  <ALL.FormControl>
    {/* <ALL.RadioGroup {...input} >
      <ALL.FormControlLabel className={touched && error ? "error" : ""}
        type={type}
        autoComplete="off"
        control={<ALL.Radio />}
        label={label}
        value={label}
      />
    </ALL.RadioGroup> */}
    <ALL.RadioGroup {...input}>
      <ALL.FormControlLabel
        className={touched && error ? "error" : ""}
        type={type}
        //autoComplete="off"
        control={<ALL.Radio />}
        label={label}
        label1={label1}
        value={label1}
      />
    </ALL.RadioGroup>
  </ALL.FormControl>
);

const RenderCheckbox = ({
  type = "checkbox",
  name,
  value,
  checked = false,
  onChange
}) => (
  <input
    type={type}
    name={name}
    checked={checked}
    onChange={onChange}
    value={value}
  />
);

const renderPassWithIcon = ({
  icon,
  placeholder,
  id,
  input,
  label,
  type,
  click,
  meta: { touched, error }
}: Props) => (
  <div className="input-group mb-3 pwd">
    <div className="input-group-append">
      <span className="input-group-text">
        <i className={icon}></i>
      </span>
    </div>
    <span className="p-viewer" onClick={() => click("pass")}>
      <i className="fa fa-eye" aria-hidden="true"></i>
    </span>
    <input
      {...input}
      type={type}
      placeholder={placeholder}
      className="form-control"
      id={id}
    />

    {touched && error && <Styled.Error>{error}</Styled.Error>}
  </div>
);

export {
  renderInputNormal,
  renderTextArea,
  renderPassword,
  renderEmail,
  renderEmailWithIcon,
  renderFile,
  SelectField,
  renderRadioButton,
  renderRadioButton2,
  renderRadioButton4,
  RenderCheckbox,
  renderVideoFile,
  renderPassWithIcon
};
