
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { PATHS } from "../../constants";
import { Field, reduxForm } from "redux-form";
import { required, whiteSpaceNotAllowed } from "../../constants/validation";
import { renderInputNormal, renderTextArea, SelectField } from "../../constants/redux-form";

import "../../App.css";
import { LoaderScreen, NavOnDashboard, FooterOnDashboard, Sidebar } from "../includes";
import {  setLoader, userInfo,  addSessionForm, fetchAllTopic, addSessionRequest } from "../../actions";


/*
 TYPES
*/

type Props = {
  history: Object,
  fetchAllTopic: Function,
  addSessionForm: Function,
  userInfo: Function,
  setLoader: Function,
  isLoading: boolean
};

class AddSession extends Component<Props> {

  constructor(props){
       super(props)
       this.state = {
          alertMessage:'',
          alertClass:"",
          isAuth : false,
       }
      
    }

  componentDidMount() {
    this.props.reset();
    this.props.setLoader(true);
    const user = localStorage.getItem('isAuth') ? true : false;
    this.setState({ isAuth:user });

    this.props.fetchAllTopic();
    this.props.userInfo().then(result => {
        if (this.props.userDetail) {
            this.props.setLoader(false);
        }
    })
   
  }

 

  onSubmit = formValue => {
    formValue.is_demo = 1;
   
        setTimeout(() => {
            this.props.addSessionRequest(formValue).then(result => {
              console.log(this.props.response);
             
                this.props.setLoader(true);
                if (this.props.response.success) {
                    this.props.setLoader(false);
                    this.setState({ alertMessage: this.props.response.message, alertClass: "alert alert-success" });
                    this.props.reset();

                    this.props.history.push({
                      pathname: "/requested-session",
                      state: { alertMessage: this.props.response.message, alertClass: "alert alert-success" },
                    });
                } else {
                    this.props.setLoader(false);
                    this.setState({ alertMessage: this.props.response.message, alertClass: "alert alert-danger" });
                }


                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);             
            });
        }, 500);
    };

  render() {
   
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    return (
     <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
        data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <NavOnDashboard user={this.props.userDetail}/>
        <Sidebar />
       <div className="page-wrapper">
       <div className="page-m-height">
            <div className="container-fluid">
                <div className="row">
                <div className="col-lg-12 col-md-12 stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className=" brdrbtm-lblue mb-3 d-flex justify-content-between">
                                <h4 className="card-title pb-3">Add Session Request</h4>
                                <p className="card-title pb-3"><Link to={PATHS.REQUESTED_SESSION} className="btn btn-sm btn-danger float-right">Back</Link></p>
                               </div>
                             <form
                                name="addSessionForm"
                                onSubmit={this.props.handleSubmit(this.onSubmit)}
                                className="mt-4"
                              >   
                              {this.state.alertMessage ? (
                                            <div className={this.state.alertClass} role="alert">
                                                {this.state.alertMessage}
                                            </div>
                                        ) : null}                              
                                <div className="form-group row">
                                    <label htmlFor="name" className="col-md-2 col-form-label">Session Title<span className="required">*</span></label>
                                     <div className="col-md-10">
                                         <Field className="form-control input_user" name="title" type="text" id="title" validate={[required]} placeholder={`Session Title`} component={renderInputNormal} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="topic" className="col-md-2 col-form-label">Select topic<span className="required">*</span></label>

                                    <div className="col-md-10">
                                        <Field
                                          className="form-control input_user" 
                                          name="topic_id" type="text" 
                                          id="topic" validate={[required]} 
                                          component={SelectField} >
                                             <option value="">Please Select</option>
                                             {this.props.allTopics ? this.props.allTopics.map((data, index) => (<option value={data.id} key={index}>{data.title}</option>)) : ''}
                                          </Field>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="topic" className="col-md-2 col-form-label"> Session<span className="required">*</span></label>

                                    <div className="col-md-10">
                                        <Field
                                          className="form-control input_user" 
                                          name="is_demo" type="text"
                                          disabled={true} 
                                          id="is_demo" 
                                          component={SelectField} >
                                             <option value="1">Demo</option>
                                          </Field>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="description" className="col-md-2 col-form-label">Description<span className="required">*</span></label>

                                    <div className="col-md-10">
                                    <Field className="form-control input_user" name="description" type="text" id="description" placeholder={`Description`}  validate={[required]}  component={renderTextArea} />
                                        
                                    </div>
                                </div>
                                
                                <button className="btn btn-sm btn-primary float-right" type="submit">Create</button>
                             </form>
                            </div>
                        </div>
                    </div>
               
                </div>
            </div>
          </div>
          <FooterOnDashboard />
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
        isLoading: state.service.isLoading,
        userDetail: state.service.userDetailResponse ? state.service.userDetailResponse.data : '',
        allTopics: state.service.allTopicResponse ? state.service.allTopicResponse.data : '',
        response:  state.service.requestedSessionResponse ? state.service.requestedSessionResponse : '',
    };
};

export default connect(mapStateToProps, {
  setLoader,
  userInfo,
  addSessionForm,
  fetchAllTopic,
  addSessionRequest
})(reduxForm({ form: "addSessionForm" })(AddSession));