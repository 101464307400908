import React, { Component } from "react";
import { connect } from "react-redux";

import "../../App.css";
import {  setLoader, userInfo, changePassword } from "../../actions";

import { PATHS } from "../../constants";
import { LoaderScreen, NavOnDashboard, FooterOnDashboard, Sidebar } from "../includes";
import { Field, reduxForm } from "redux-form";
import { required, passwordsMatch, whiteSpaceNotAllowed, minLength6 } from "../../constants/validation";
import { renderInputNormal } from "../../constants/redux-form";

/*
 TYPES
*/

type Props = {
  history: Object,
};




class ChangePassword extends Component<Props> {

  constructor(props) {
    super(props);
    this.state = {
       alertMessage: "",
       alertClass: "",
      
    };
  }

  componentDidMount() {
    
    if (!localStorage.getItem("access_token")) {
      this.props.history.push(PATHS.LOGIN)
      localStorage.clear();
    }
   
    this.props.setLoader(true);
    this.props.userInfo().then(result => {
        if (this.props.userDetail) {
            this.props.setLoader(false);
        }
    })

  }



  onSubmit = formValue => {
       
        setTimeout(() => {
            this.props.changePassword(formValue).then(result => {
             
                this.props.setLoader(true);
                if (this.props.response.success) {
                    this.props.reset();
                    this.props.setLoader(false);
                    this.setState({ alertMessage: this.props.response.message, alertClass: "alert alert-success" });

                } else {
                    this.props.setLoader(false);
                    this.setState({ alertMessage: this.props.response.message, alertClass: "alert alert-danger" });
                }

                setTimeout(() => {
                    this.setState({ alertMessage: "", alertClass: "" })
                }, 3000);
            });
        }, 500);
    };

  render() {
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    return (
     <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
        data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <NavOnDashboard user={this.props.userDetail}/>
        <Sidebar />
        <div className="page-wrapper">
          <div className="page-m-height">
            <div className="container-fluid">
              <div className="row">
                    <div className="col-12 stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title brdrbtm-lblue pb-3 mb-3">Change Password</h4>

                            <form
                                name="changePasswordForm"
                                onSubmit={this.props.handleSubmit(this.onSubmit)}
                                className="mt-4"
                              >   
                              {this.state.alertMessage ? (
                                            <div className={this.state.alertClass} role="alert">
                                                {this.state.alertMessage}
                                            </div>
                                        ) : null}                              
                                <div className="form-group row">
                                    <label htmlFor="name" className="col-md-2 col-form-label">Current Password<span className="required">*</span></label>

                                    <div className="col-md-10">
                                         <Field className="form-control input_user" name="old_password" type="password" id="old_password" validate={[required,  whiteSpaceNotAllowed]} placeholder={`Current Password`} component={renderInputNormal} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="name" className="col-md-2 col-form-label">Password<span className="required">*</span></label>
                                    <div className="col-lg-10">
                                        <Field className="form-control input_pass" icon="fas fa-key" name="password" type="password" id="password" validate={[required, whiteSpaceNotAllowed, minLength6]} placeholder={`Password`} component={renderInputNormal} />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="name" className="col-md-2 col-form-label">Confirm Password<span className="required">*</span></label>
                                      <div className="col-lg-10">
                                          <Field className="form-control input_pass" icon="fas fa-key" name="confirm_password" type="password" id="confirmPassword" validate={[required, passwordsMatch, whiteSpaceNotAllowed]} placeholder={`Confirm Password`} component={renderInputNormal} />
                                      </div>
                                </div>
                                
                                <button className="btn btn-sm btn-primary float-right" type="submit">Update</button>
                             </form>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
            <FooterOnDashboard />
        </div>
    </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.service.isLoading,
  userDetail: state.service.userDetailResponse ? state.service.userDetailResponse.data : '',
  response: state.service.changePasswordResponse ? state.service.changePasswordResponse.data : '',   
});

export default connect(mapStateToProps, {
  setLoader,
  userInfo,
  changePassword
})(reduxForm({ form: "changePasswordForm" })(ChangePassword));
