import React from 'react';
import PropTypes from 'prop-types';


import { connect } from 'react-redux';
import { PATHS } from "../constants";
//import { addFlashMessage } from '../actions/flashMessage';

export default function (ComposedComponent) {
    class Authenticate extends React.Component<>{

        UNSAFE_componentWillMount() {
           
             if(this.props.isAuthenticated && this.props.mediumId === "false"){
                this.props.history.push(PATHS.UPDATE_MEDIUM);
            }
        }
        render() {
            return (
                <ComposedComponent {...this.props} />
            );
        }
    }

    Authenticate.propTypes = {
        isAuthenticated: PropTypes.bool.isRequired
    }

    Authenticate.contextTypes = {
        router: PropTypes.object.isRequired
    }

    function mapStateToProps(state) {


        if (localStorage.getItem('access_token') && localStorage.getItem('access_token') !== 'notAutherised') {
           let mediumId = localStorage.getItem('userMediumId');
            return {
                isAuthenticated: true,
                mediumId : mediumId
            }

        } else {
            return {
                isAuthenticated: false,
                mediumId : true
            }
        }
    }
    return connect(mapStateToProps)(Authenticate);
}