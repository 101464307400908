import React, { useEffect, useState} from "react";
import { LoaderScreen, NavOnDashboard, FooterOnDashboard, Sidebar, ModalStripe } from "../includes";
import { userInfo, setLoader, fetchEventInfo, paySession } from "../../actions";
import { useSelector, useDispatch } from "react-redux";
 import moment from "moment";
import ReactHtmlParser from 'react-html-parser';

import { PATHS, WEB_VIDEO_URL, appConfig } from "../../constants";
import { titleCase } from "../../utils/general"
import SweetAlert from "react-bootstrap-sweetalert";

const ViewEvent = (props) => { 

	const dispatch = useDispatch();
	const [alert, setAlert] = useState(null);

	const [show, setShow] = useState(false);
	


	useEffect(() => {
		console.log("console.log");
		let formValues = {
			'session_id' : props.match.params.id,
		}
		
	    dispatch(fetchEventInfo(formValues));
	    dispatch(userInfo());
	    dispatch(setLoader(true));
	 }, [dispatch, props]);

  	const userDetail = useSelector((state) =>
	     state.service.userDetailResponse ? state.service.userDetailResponse.data : '',
	  );

  	const sessionDetail = useSelector((state) =>
	     state.service.eventDetail ? state.service.eventDetail.data : '',
	  );

  	console.log(sessionDetail);

  	const isLoading = useSelector((state) =>
	      state.service.isLoading
	  );

  	const handleBack = e => {
		  e.preventDefault();
		 props.history.push(PATHS.LIVE_EVENT)
	}

	const sessionUrl = (e) => {
		// e.preventDefault();

		let meeting = sessionDetail.meeting_id;

		 let url = btoa(`${meeting}##${userDetail.id}`);

		 console.log(WEB_VIDEO_URL+url);

		return WEB_VIDEO_URL+url;
	}


	const paySessionData = useSelector((state) =>{
  		console.log(state.service.paySessionData)
  		 return state.service.paySessionData ? state.service.paySessionData : []
  	});




	const successAlert = () => {
    setAlert(
              <div>
                <SweetAlert
                    success
                    title="Woo!"
                    confirmBtnText="Ok"
                    onConfirm={() => hideAlert()}
                  >
                    Payment has been made successful !
                  </SweetAlert>
              </div>
          );

  }

  const errorAlert = (messsage) => {
    setAlert(
              <div>
                 <SweetAlert
                    danger
                    title="Oops!"
                    confirmBtnText="Ok"
                    onConfirm={() => hideAlert()}

                  >
                    {messsage}
                  </SweetAlert>
              </div>
          );
  }

  const hideAlert = () => {
  	let formValues = {
			'session_id' : props.match.params.id,
		}
		
	dispatch(fetchEventInfo(formValues))
	setAlert(false);
  }

  const payByWallet = (id) => {
  	setAlert(false);
    dispatch(paySession({session_id: id})).then((res)=>{
    	
    	if(res.payload.success){
  		
	  		successAlert()
	  	}else{
	  		errorAlert(res.payload.message) 
	  	}
	  	
    })

  }

  const returnToBack = () => {
	   setShow(false);
	   let formValues = {
			'session_id' : props.match.params.id,
		}
		
		dispatch(fetchEventInfo(formValues))
  }

  
	const payNow = e => {
		  e.preventDefault();
		setShow(true)
			// setAlert(
	  //             <div>
	  //             	<SweetAlert
			// 			warning
			// 			showCancel
			// 			title="Are you sure?"
			// 			confirmBtnText="Ok"
			// 			cancelBtnBsStyle="light"
			// 			onConfirm={() => payByWallet(sessionDetail.id)}
			// 			onCancel={hideAlert}
			// 			btnSize="sm"
			// 		>
			// 		Pay {sessionDetail ? sessionDetail.credits : ''} rupees to join the session 
				
			// 			</SweetAlert>
	  //             </div>
	  //         );

	//	 props.history.push(PATHS.LIVE_SESSION)
	}


	

  	
  	if (isLoading) {
      return <LoaderScreen />;
    }

	return (<>
			{alert}
			<div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
		        data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
		        <NavOnDashboard user={userDetail}/>
		        <Sidebar />
		        <div className="page-wrapper">
		          <div className="page-m-height">
		          <div className="page-breadcrumb">
                        <div className="row">
                            <div className="col-12 col-sm-8 align-self-center mb-3 mb-sm-0">
                                <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">Event Info</h3>
                            </div>
                        </div>
                    </div>
		            <div className="container-fluid">
		              <div className="row">
		              {sessionDetail ? 
		                    <div className="col-12 stretch-card">
		                        <div className="card">
		                            <div className="card-body">
		                                <div className=" brdrbtm-lblue mb-3 d-flex justify-content-between">
		                                    <h4 className="card-title pb-3">Session Detail</h4>
		                                    <p className="card-title pb-3"><a href="#!" onClick={(e)=>handleBack(e)}  className="btn btn-sm btn-danger float-right">Back</a></p>
		                                </div> 
		                                <table className="table table-hover">
							                <tr>
							                    <th>Session Name</th>
							                    <td>{sessionDetail.session_title ? titleCase(sessionDetail.session_title) : ""}</td>
							                </tr>
							                <tr>
							                    <th>Topic</th>
							                    <td>{sessionDetail.topic_info ? (sessionDetail.topic_info.title  ? sessionDetail.topic_info.title : ""): ""}</td>
							                </tr>

							                <tr>
							                    <th>Start Time</th>
							                    <td>{sessionDetail.start_time ? moment(sessionDetail.start_time).format('MMMM Do YYYY, h:mm:ss a') : "" }</td>
							                </tr>

							                <tr>
							                    <th>End Time</th>
							                     <td>{sessionDetail.end_time ? moment(sessionDetail.end_time).format('MMMM Do YYYY, h:mm:ss a') : "" }</td>
							                </tr>

							                <tr>
							                    <th>Faculty Name</th>
							                    <td>{sessionDetail.faculty ? (sessionDetail.faculty.name  ? sessionDetail.faculty.name : ""): ""} </td>
							                </tr>

							                <tr>
							                    <th>Faculty Experience</th>
							                    <td>{sessionDetail.faculty ? (sessionDetail.faculty.year_of_experience  ? sessionDetail.faculty.year_of_experience : ""): ""} yrs</td>
							                </tr>

							                <tr>
							                    <th>Faculty Subject</th>
							                     <td>{sessionDetail.faculty ? (sessionDetail.faculty.subject_expertise  ? sessionDetail.faculty.subject_expertise : ""): ""}</td>
							                </tr>

							                <tr>
							                    <th>Description</th>
							                    <td>{sessionDetail.session_description ? ReactHtmlParser(sessionDetail.session_description) : "" }</td>
							                </tr>
							                


							               {!sessionDetail.is_participated && sessionDetail.is_paid == 1 ? 
							                		<tr>
									                    <th>Session Credits</th>
									                    <td><a href="#!" className="btn btn-sm btn-primary" onClick={ (e) => payNow(e) }>Pay {sessionDetail ? sessionDetail.credits : ''}</a></td>
									                </tr>
							                	:
							                	 (sessionDetail && sessionDetail.session_url) ? 
							                		<tr>
									                    <th>Session Url</th>
									                    <td><a href={sessionDetail.session_url} className="btn btn-sm btn-primary" target="_blank">Join Session</a></td>
									                </tr>
									               :  
									               <tr>
									                    <th>Session Url</th>
									                   <td><a href={ sessionUrl() } className="btn btn-sm btn-primary" target="_blank">Join Session</a></td>
									                </tr>
							                	
							                	} 	
							                 
							            </table>
		                                        
		                            </div>
		                        </div>
		                    </div>
		                :
		                <div className="col-lg-12 col-md-12 stretch-card" data-aos="fade-up" data-aos-delay="100">
				              <div className="card">
				                  <div className="card-body">
				                  <p>No Record Found</p>
				                  </div>
				              </div>
				        </div>  
		               }    
		                </div>
		            </div>
		          </div>
		          <ModalStripe show={show} isSubscription={false} sessionId={sessionDetail ? sessionDetail.id : null} plan={''} price={sessionDetail ? sessionDetail.credits : null} returnToBack={returnToBack} user={userDetail}/>
		            <FooterOnDashboard />
		        </div>
		    </div>	
			</>
			);
	}

export default ViewEvent;

