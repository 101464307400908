import { MENU_LIST, SET_LOADER, FETCH_CMS_CONTENT, FETCH_FAQ, CONTACT_US,
  USER_LOGOUT,
CATCH_ERROR,
USER_LOGIN_SUCCESS,
USER_LOGIN_FAIL,
USER_REGISTER_SUCCESS,
USER_REGISTER_FAIL,
FORGOT_PSWD,
RESET_PSWD,
VERIFY_EMAIL,
USER_INFO,
UPDATE_INFO,
FETCH_SUB_CATEGORY,
FETCH_TOPIC,
FETCH_TOPIC_INFO,
FETCH_CATEGORY,
VERIFY_EMAIL_SEND,
CHANGE_PASSWORD,
FETCH_MY_TOPIC,
DELETE_MY_TOPIC,
ADD_MY_TOPIC,
FETCH_REQUESTED_SESSION,
FETCH_ALL_TOPIC,
ADD_SESSION_REQUEST,
LIVE_SESSION_EVENT,
FETCH_NOTIFICATION,
MARK_READ_NOTIFICATION,
DASHBOARD_DATA,
FETCH_QUIZ_INFO,
INITIALTESTDATA,
UPDATE_TEST_ATTEMPTS,
GET_QUIZ_RESULT,
FETCH_PRACTICE_TEST,
FETCH_ALL_MEDIUM,
UPDATE_MEDIUM,
SESSION_DETAIL,
FETCH_WALLET,
FETCH_PLANS,
MAKE_SUBSCRIPTION,
FETCH_MY_SUBSCRIPTION,
REQUESTED_SESSION_DETAIL,
PAY_SESSION,
EVENT,
EVENT_DETAIL,
USER_IMAGE_BY_ID,
USER_SUB_CAT   } from "../actions/types";


const INITIAL_STATE = {
  langList: [],
  isLoading: false,
  getCmsPageResponse:[],
  getFaqResponse:[],
  getContactResponse:[],
  error_response:[],
  updateProfileResponse:[],
  subCategoryResponse:[],
  categoryResponse:[],
  topicInfoResponse:[],
  userDetailResponse:[],
  verificationResponse:[],
  changePasswordResponse:[],
  myTopicResponse:[],
  requestedSessionResponse:[],
  allTopicResponse:[],
  sessionEventResponse:[],
  notificationResponse:[],
  dashboardResponse:[],
  quizInfoResponse:[],
  initialTestData:[],
  testAttemptResponse:[],
  quizResponse:[],
  praticeTestResponse:[],
  mediumResponse:[],
  updateMediumResponse:[],
  sessionDetail:[],
  walletInfo:[],
  plansInfo: [],
  subsInfo: [],
  mySubsInfo: [],
  reqSessionInfo:[],
  paySessionData:[],
  eventData: [],
  eventDetail:[],
  userImage:[],
  forgotPswdResponse:[],
  registerResponse:[],
  isLogout:false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MENU_LIST:
      return { ...state, langList: action.payload, isLoading: false };
    case USER_LOGOUT:
     console.log('USER_LOGOUT ', action.payload)
      return { ...state, isLogout: action.payload };
    case SET_LOADER:
      return { ...state, isLoading: action.payload };  
    case FETCH_CMS_CONTENT:
      return { ...state, getCmsPageResponse: action.payload, isLoading: false };  
    case FETCH_FAQ:
      return { ...state, getFaqResponse: action.payload, isLoading: false };    
    case CONTACT_US:
      return { ...state, getContactResponse: action.payload, isLoading: false };
    case CATCH_ERROR:
      return { ...state, error_response: action.payload, isLoading: false };
    case USER_LOGIN_SUCCESS:
      return { ...state, response: action.payload, token: action.token, isLoading: false };
    case USER_LOGIN_FAIL:
      return { ...state, response: action.payload, token: "", isLoading: false }; 
    case USER_REGISTER_SUCCESS:
      return { ...state, registerResponse: action.payload, isLoading: false };
    case USER_REGISTER_FAIL:
      return { ...state, registerResponse: action.payload, isLoading: false };    
    case FORGOT_PSWD:
   
      return { ...state, forgotPswdResponse: action.payload, isLoading: false };   
    case RESET_PSWD:
      return { ...state, response: action.payload, isLoading: false }; 
    case VERIFY_EMAIL:
      return { ...state, response: action.payload, isLoading: false }; 
    case VERIFY_EMAIL_SEND:
      return { ...state, verificationResponse: action.payload, isLoading: false };   
    case CHANGE_PASSWORD:
      return { ...state, changePasswordResponse: action.payload, isLoading: false };  
    case USER_INFO:
      return { ...state, userDetailResponse: action.payload, isLoading: false };   
    case UPDATE_INFO:
      return { ...state, updateProfileResponse: action.payload, isLoading: false };  
    case FETCH_SUB_CATEGORY:
      return { ...state, subCategoryResponse: action.payload, isLoading: false };  
    case FETCH_TOPIC:
      return { ...state, response: action.payload, isLoading: false };  
    case FETCH_TOPIC_INFO:
      return { ...state, topicInfoResponse: action.payload, isLoading: false };    
    case FETCH_CATEGORY:
      return { ...state, categoryResponse: action.payload, isLoading: false };    
    case FETCH_MY_TOPIC:
      return { ...state, myTopicResponse: action.payload, isLoading: false }; 
    case DELETE_MY_TOPIC:   
      return { ...state, 
        myTopicResponse: {
          data:state.myTopicResponse.data.filter((item, index) => item.topic_id !== action.payload)
        }, 
        isLoading: false 
      }; 
    case ADD_MY_TOPIC:
      return {
        ...state,    
          myTopicResponse: state.myTopicResponse.concat(action.payload.data),
          isLoading: false
        };

    case FETCH_REQUESTED_SESSION:
       return { ...state, requestedSessionResponse: action.payload, isLoading: false }; 
    case FETCH_ALL_TOPIC:
       return { ...state, allTopicResponse: action.payload, isLoading: false }; 
    case ADD_SESSION_REQUEST:
      return {
        ...state,    
          requestedSessionResponse: action.payload,
          isLoading: false
        };
    case LIVE_SESSION_EVENT:
      return {
        ...state,    
          sessionEventResponse: action.payload,
          isLoading: false
        };         

    case FETCH_NOTIFICATION: 
      return { ...state, notificationResponse: action.payload, isLoading: false }; 

    case MARK_READ_NOTIFICATION: 
      return { ...state,  isLoading: false };      
    case DASHBOARD_DATA: 
      return { ...state,  dashboardResponse: action.payload, isLoading: false };    
    case FETCH_QUIZ_INFO: 
      return { ...state,  quizInfoResponse: action.payload, isLoading: false };  
    case INITIALTESTDATA:
      return { ...state, initialTestData: action.payload, isLoading: false };      
    case UPDATE_TEST_ATTEMPTS:
      return { ...state, testAttemptResponse: action.payload, isLoading: false };  
    case GET_QUIZ_RESULT:
      return { ...state, quizResponse: action.payload, isLoading: false }; 
    case FETCH_PRACTICE_TEST:
      return { ...state, praticeTestResponse: action.payload, isLoading: false };   
    case  FETCH_ALL_MEDIUM:
      return { ...state, mediumResponse: action.payload, isLoading: false };   
    case UPDATE_MEDIUM:
      return { ...state, updateMediumResponse: action.payload, isLoading: false };   
   case SESSION_DETAIL:
      return { ...state, sessionDetail: action.payload, isLoading: false };      
   case FETCH_WALLET:
      return { ...state, walletInfo: action.payload, isLoading: false };
   case FETCH_PLANS:
      return { ...state, plansInfo: action.payload, isLoading: false };  
   case MAKE_SUBSCRIPTION:
      return { ...state, subsInfo: action.payload, isLoading: false };    
    case FETCH_MY_SUBSCRIPTION:
      return { ...state, mySubsInfo: action.payload, isLoading: false };      
   case REQUESTED_SESSION_DETAIL:
      return { ...state, reqSessionInfo: action.payload, isLoading: false };  
   case PAY_SESSION:
      return { ...state, paySessionData: action.payload, isLoading: false }; 
   case EVENT:
      return { ...state, eventData: action.payload, isLoading: false };     
   case EVENT_DETAIL:
      return { ...state, eventDetail: action.payload, isLoading: false };    
  case USER_IMAGE_BY_ID:
      return { ...state, userImage: action.payload, isLoading: false };        
  case USER_SUB_CAT:
      return { ...state, userImage: action.payload};    
        
    default:
      return state;
  }
};
