import React, { } from "react";
//import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
//import { Events, EventApiData } from "./EventData";
import "./Calendar.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
//import { liveSessionEvent } from "../../actions";

//import ReactPaginate from 'react-paginate';

import ReactHtmlParser from 'react-html-parser';
import  { add3dots, titleCase}  from "../../utils/general"
//BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

const TableElement = (props) => {

  // const dispatch = useDispatch();

  // useEffect(() => {
   
  //     dispatch(liveSessionEvent());
   
  //  }, [dispatch]);

  // const eventDetail = useSelector((state) =>
  //      state.service.sessionEventResponse ? state.service.sessionEventResponse.data : '',
  //   );



  const eventDetail = props.eventDetail
      

  return (
  eventDetail && eventDetail.length > 0? 
    eventDetail.map((event, i) =>
        <div className="col-lg-4 col-md-12 stretch-card" key={i}>
          <div className="card">
            <div className="card-body">
                <h4 className="card-title brdrbtm-lblue pb-3 mb-3 text-c-blue">{titleCase(event.title)}</h4>
                <p>
                   { ReactHtmlParser(add3dots(event.description,100)) }
                </p>
                <div className="cat-info-list">
                    <h5 className="card-title">Session info</h5>
                    <ul className="list-style-none pl-3 mb-3">
                        <li>
                            <a href="#!" style={{ pointerEvents:'none'}}>
                                <i className="fa fa-check text-info"></i>{' '}
                                Start Time - {moment(event.start).format('MMMM Do YYYY, h:mm a') }
                            </a>
                        </li>
                        <li>
                            <a href="#!" style={{ pointerEvents:'none'}}>
                                <i className="fa fa-check text-info"></i>{' '}
                                End Time - {moment(event.end).format('MMMM Do YYYY, h:mm a') }
                            </a>
                        </li>
                        <li>
                            <a href="#!" style={{ pointerEvents:'none'}}>
                                <i className="fa fa-check text-info"></i>{' '}
                                Faculty Name - {event.faculty_name ? event.faculty_name : '' }
                            </a>
                        </li>
                    </ul>
                </div>
                <a href="#!" className="btn waves-effect waves-light btn-rounded btn-primary pl-4 pr-4" onClick={()=>props.handleView(event)}>View more</a>
            </div>
          </div>
         </div>
      )
    
      :
      <div className="col-lg-12 col-md-12 stretch-card" data-aos="fade-up" data-aos-delay="100">
              <div className="card">
                  <div className="card-body">
                  <p>No Record Found</p>
                  </div>
              </div>
        </div> 
  );
};

export default TableElement;
