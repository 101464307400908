import * as styledComponents from "styled-components";

const { default: styled } = styledComponents;

// Navbar css
export const Navbar = styled.div`
    background-color: #77bb1f;
    text-align: center;
    & a {
        display: inline-block;
        padding: 10px;
        color: #fff;
        text-decoration: none;
`;

export const Wrapper = styled.div`
  display: block;
`;

// Container css

export const Container = styled.div``;

export const InnerContentPara = styled.p`
  margin: 0 0 15px 0;
  padding: 0;
`;

export const Error = styled.span`
  color: #f00;
  width: 100%;
  display: block;
`;

export const loaderSpinner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
`;

export const loaderSpinnerChild = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
