// @flow

import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { PATHS } from "./constants";
import { Home, Terms, Faqs, Contactus, Topic } from "./pages/home";
import HttpsRedirect from 'react-https-redirect';
import Login from "./pages/login";
import Register from "./pages/register";
import Forget from "./pages/forget";
import Reset from "./pages/reset";
import Verify from "./pages/verify";
import Error404 from "./pages/errors/404";
import Dashboard from "./pages/dashboard";
import Profile from "./pages/profile";
import TopicInfo from "./pages/topic";
import ChnagePassword from "./pages/change-password";
import PracticeTest from "./pages/practiceTest"
import { Quiz, QuizResult } from "./pages/quiz";
import { MyTopic } from "./pages/myTopic";
import { Medium, LandingInner } from "./pages/medium";
import { RequestedSession, AddSession, ViewRequestedSession } from "./pages/requestedSession";
import { Session, ViewSession, Event, ViewEvent } from "./pages/session";
import Wallet from "./pages/wallet";
import AOS from 'aos';
import 'aos/dist/aos.css';
import requireAuth from './utils/requireAuth';
import notRequiredAuth from './utils/notRequiredAuth';
import { Plan, Membership } from "./pages/membership";
import Chat from "./pages/chat";
import ChatWeb from "./pages/chatweb";
//import Stripe from './pages/stripe';
// import GuardedRoute from "./GuardedRoute";
import { AddDemoSession } from "./pages/demoSession";

import * as $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.min.js';

import { CHAT_BOT_API_URL } from './constants';


window.jQuery = window.$ = $


var loadjs = require('loadjs');

window.name = null;
window.api_url = null;
window.user_image = null;

/*
 TYPES
*/

type Props = {};


class App extends Component<Props> {

  constructor(props) {
    super(props)
    this.state = {
      user: false,
    }

  }



  async componentDidMount() {

    const user_id = await localStorage.getItem("user_id") || null;
    window.name = user_id
    window.api_url = CHAT_BOT_API_URL;


    AOS.init();

  }



  UNSAFE_componentWillMount() {
    loadjs('/assets/js/main.js', function () {
      loadjs('/assets/js/fontawesome-all.js', function () {

      });
    });


  }

  render() {


    return (
      <HttpsRedirect>
        <Router >

          <Switch>
            <Route exact path={PATHS.HOME} component={Home} />
            <Route path={PATHS.LOGIN} component={Login} />
            <Route path={PATHS.REGISTER} component={Register} />
            <Route path={PATHS.FORGET_PASSWORD} component={Forget} />
            <Route path={PATHS.RESET_PASSWORD} component={Reset} />
            <Route path={PATHS.VERIFY_EMAIL} component={Verify} />
            <Route path={PATHS.TERMS} component={Terms} />
            <Route path={PATHS.ABOUT} component={Terms} />
            <Route path={PATHS.PRIVACY} component={Terms} />
            <Route path={PATHS.PRACTICE} component={Terms} />
            <Route path={PATHS.FAQ} component={Faqs} />
            <Route path={PATHS.CONTACT_US} component={Contactus} />

            <Route path={PATHS.CHATBOATWEBVIEW} component={ChatWeb} />

            <Route path={PATHS.PRACTICE_TEST} component={requireAuth(PracticeTest)} />
            <Route path={PATHS.CATEGORY} component={notRequiredAuth(Topic)} />
            <Route path={PATHS.UPDATE_MEDIUM} component={requireAuth(LandingInner)} />

            {/*<Route path={PATHS.COMPETITIVE_TOPIC} component={Topic} />
                    <Route path={PATHS.ACADEMIC_TOPIC} component={Topic} />
                    <Route path={PATHS.SPOKEN_ENGLISH} component={Topic} />
                  <Route path={PATHS.ADD_MONEY} component={requireAuth(Stripe)} />*/}
            <Route path={PATHS.TOPIC_INFO} component={notRequiredAuth(TopicInfo)} />
            <Route path={PATHS.DASHBOARD} component={requireAuth(Dashboard)} />
            <Route path={PATHS.UPDATE_PROFILE} component={requireAuth(Profile)} />
            <Route path={PATHS.CHANGE_PASSWORD} component={requireAuth(ChnagePassword)} />
            <Route path={PATHS.MY_TOPIC} component={requireAuth(MyTopic)} />
            <Route path={PATHS.REQUESTED_SESSION} component={requireAuth(RequestedSession)} />
            <Route path={PATHS.ADD_REQUESTED_SESSION} component={requireAuth(AddSession)} />
            <Route path={PATHS.LIVE_SESSION} component={requireAuth(Session)} />
            <Route exact path={PATHS.START_QUIZ} component={requireAuth(Quiz)} />
            <Route exact path={PATHS.QUIZ_RESULT} component={requireAuth(QuizResult)} />
            <Route path={PATHS.SESSION_DETAIL} component={requireAuth(ViewSession)} />

            <Route path={PATHS.LIVE_EVENT} component={requireAuth(Event)} />
            <Route path={PATHS.EVENT_DETAIL} component={requireAuth(ViewEvent)} />

            <Route path={PATHS.MY_WALLET} component={requireAuth(Wallet)} />

            <Route path={PATHS.BUY_MEMBERSHIP} component={requireAuth(Plan)} />
            <Route path={PATHS.MY_MEMBERSHIP} component={requireAuth(Membership)} />
            <Route path={PATHS.REQUESTED_SESSION_DETAIL} component={requireAuth(ViewRequestedSession)} />

            {/* <Route path={PATHS.DEMO_SESSION} component={requireAuth(RequestedSession)} />
          <Route path={PATHS.DEMO_SESSION_DETAIL} component={requireAuth(ViewRequestedSession)} />
          */}

            <Route path={PATHS.ADD_DEMO_SESSION} component={requireAuth(AddDemoSession)} />
            <Route path={PATHS.CHATBOAT} component={requireAuth(Chat)} />




            <Route path={PATHS.ERROR} component={Error404} />

          </Switch>
        </Router>
      </HttpsRedirect>
    );
  }
}

export default App;
