 
import React, { useEffect } from "react";
import { withRouter } from "react-router";
import {  NavLink } from "react-router-dom";
import { PATHS } from "../../constants";
import { useSelector } from "react-redux";
//import { fetchCategory } from "../../actions";
import * as $ from 'jquery';
window.jQuery = window.$ = $

const Sidebar = (props) => { 

    const activeRoute = (routeName) => {
     // return props.location.pathname.indexOf(routeName) > -1 ? "selected" : "";
       let selectedDiv = "";
        for (const route of routeName) {
           if(props.location.pathname.indexOf(route) > -1){
                selectedDiv = "selected";
           }    
        } 
        return selectedDiv;
    }


  
    
     //const dispatch = useDispatch();
      useEffect(() => {
       
         document.querySelectorAll(".sidebar-item").forEach($element => $element.addEventListener("click", (event) => {
          //  return event.target.classList.contains("collapsed") ? event.target.setAttribute("aria-expanded","true") : "";
           return  document.querySelector('#sidebar').classList.contains("active") ? document.querySelector('#sidebar').classList.remove('active'): "";
                
         }));
       

      });

    const handleLogout = (e) => {
      e.preventDefault();
      localStorage.clear();
      props.history.push(PATHS.LOGIN);
      
    }

    const handleView = (e, param) =>  {
         e.preventDefault();

        props.history.push('/library/' + param);
    }    


    const category = useSelector(state => state.service.categoryResponse.data);

    let url = "";



 return (<aside id="sidebar" className="left-sidebar">
            <div className="scroll-sidebar">
                <nav className="sidebar-nav">
                    <ul id="sidebarnav">
                        <li className={activeRoute(['dashboard']) + " sidebar-item"}> 
                            {/*<Link className="sidebar-link sidebar-link active" to={PATHS.DASHBOARD}>
                                                            <i className="fas fa-home"></i>
                                                            <span className="hide-menu">Dashboard</span>
                                                        </Link>*/}
                            <NavLink className="sidebar-link sidebar-link"  activeClassName="active" to={PATHS.DASHBOARD}>  
                                <i className="fas fa-home"></i>
                                 <span className="hide-menu">Dashboard</span> 
                            </NavLink>
                              
                        </li>
                        
                         <li className={activeRoute(['library']) + " sidebar-item"}> 
                            <a className={activeRoute(['library']) ? "dropdown-toggle sidebar-link has-arrow collapsed" : "dropdown-toggle sidebar-link has-arrow"} aria-expanded={activeRoute(['library']) ? true : false} data-toggle="collapse" href="#allTopics">
                                <i className="fas fa-book-open"></i>
                                <span className="hide-menu">All Topics </span>
                            </a>

                                <ul className={activeRoute(['library']) ? "dp-submenu collapse show" : "dp-submenu collapse "}  id="allTopics">
                                   {category && category.map((cat, i)=>(
                                    <li className="sidebar-item" key={i}>
                                       
                                    <NavLink to={'/library/' + cat.slug} onClick={(e)=>handleView(e, cat.slug)} className="sidebar-link">
                                           <span className="hide-menu">{cat.name}</span>
                                        </NavLink>
                                    </li>
                                   ))} 
                                </ul>
                            </li>
                        <li className={activeRoute(['my-topics','requested-session', 'live-session', 'add-session-request', 'events', 'add-demo-session']) + " sidebar-item"}> 
                            <a className={activeRoute(['my-topics','requested-session', 'live-session', 'add-session-request', 'events', 'add-demo-session']) ? "dropdown-toggle sidebar-link has-arrow collapsed" : "dropdown-toggle sidebar-link has-arrow"} aria-expanded={ activeRoute(['my-topics','requested-session', 'live-session', 'add-session-request']) ? true : false } href="#myContent" data-toggle="collapse">
                                <i className="fas fa-dice-d6"></i>
                                <span className="hide-menu">My Content </span>
                            </a>
                            <ul className={activeRoute(['my-topics','requested-session', 'live-session', 'add-session-request', 'events', 'add-demo-session']) ? "dp-submenu collapse show" : "dp-submenu collapse "}  id="myContent">
                                <li className="sidebar-item">
                                    <NavLink to={PATHS.MY_TOPIC}  className="sidebar-link">
                                        <span className="hide-menu"> My Topics </span>
                                    </NavLink>
                                </li>
                                <li className="sidebar-item">
                                    <NavLink to={PATHS.REQUESTED_SESSION}  className={activeRoute(['add-session-request']) + " sidebar-link"}>
                                        <span className="hide-menu"> Request For Session </span>
                                    </NavLink>
                                </li>
                                 <li className="sidebar-item">
                                    <NavLink to={PATHS.ADD_DEMO_SESSION}  className={activeRoute(['add-demo-session']) + " sidebar-link"}>
                                        <span className="hide-menu">Demo Session Request </span>
                                    </NavLink>
                                </li>
                                <li className="sidebar-item">
                                    <NavLink to={PATHS.LIVE_SESSION} className="sidebar-link">
                                        <span className="hide-menu"> My Live Session </span>
                                    </NavLink>
                                </li>
                                <li className="sidebar-item">
                                    <NavLink to={PATHS.LIVE_EVENT} className="sidebar-link">
                                        <span className="hide-menu"> Events </span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className={activeRoute(['practice-test']) + " sidebar-item"}> 
                             
                            <NavLink className="sidebar-link"  activeClassName="active" to={PATHS.PRACTICE_TEST}>  
                                 <i className="fas fa-user-tie"></i>
                                  <span className="hide-menu">Practice Test Evaluation</span>  
                            </NavLink>
                        </li>
                        <li className={activeRoute(['plans']) + " sidebar-item"}>
                            <a className={activeRoute(['plans']) ? "dropdown-toggle sidebar-link has-arrow collapsed" : "dropdown-toggle sidebar-link has-arrow"} aria-expanded={activeRoute(['plans']) ? true : false} data-toggle="collapse" href="#membership">
                                <i className="fas fa-dice-d6"></i>
                                <span className="hide-menu">Membership</span>
                            </a>
                            <ul className={activeRoute(['plans', 'my-membership']) ? "dp-submenu collapse show" : "dp-submenu collapse "}  id="membership">
                                <li className="sidebar-item">
                                    <NavLink to={PATHS.BUY_MEMBERSHIP}  className="sidebar-link">
                                        <span className="hide-menu"> Buy Membership </span>
                                    </NavLink>
                                </li>
                                <li className="sidebar-item">
                                    <NavLink to={PATHS.MY_MEMBERSHIP}  className="sidebar-link">
                                        <span className="hide-menu"> My Membership </span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                       

                       <li className={activeRoute(['profile']) + " sidebar-item"}> 
                             
                            <NavLink className="sidebar-link sidebar-link"  activeClassName="active" to={PATHS.UPDATE_PROFILE}>  
                                 <i className="fas fa-user-tie"></i>
                                  <span className="hide-menu">My Profile </span>  
                            </NavLink>
                        </li>
                        
                        <li className="sidebar-item"> 
                            <NavLink className="sidebar-link sidebar-link"  activeClassName="active" to={PATHS.CHATBOAT}>  

                                <i className="fas fa-headset"></i>
                                <span className="hide-menu">Chatbot </span>
                            </NavLink>
                        </li>
                        <li className="sidebar-item"> 
                            <a className="sidebar-link sidebar-link" href={url} onClick={handleLogout}> 
                                <i className="fas fa-sign-out-alt"></i>
                                <span className="hide-menu">Logout </span>
                            </a>
                        </li>
                </ul>
                </nav>
            </div>
        </aside>)
}


export default withRouter((Sidebar));
