import React, { useEffect, useState} from "react";
import { LoaderScreen, NavOnDashboard, FooterOnDashboard, Sidebar, ModalStripe } from "../includes";
import { userInfo, setLoader, fetchWalletInfo } from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';



const Wallet = (props) => { 

	const dispatch = useDispatch();
    const [show, setShow] = useState(false);

	useEffect(() => {
	    dispatch(userInfo());
	    dispatch(setLoader(true));
        dispatch(fetchWalletInfo());
	 }, [dispatch]);


	const userDetail = useSelector((state) =>
	     state.service.userDetailResponse ? state.service.userDetailResponse.data : '',
	  );

    const walletDetail = useSelector((state) =>
         state.service.walletInfo ? state.service.walletInfo.data : '',
      );

  
  	const isLoading = useSelector((state) =>
	      state.service.isLoading
	  );

    const returnToBack = () => {
        setShow(false)
        dispatch(fetchWalletInfo());
    } 

    const handleClick = (e) => {
        setShow(true);
    }  

  	if (isLoading) {
      return <LoaderScreen />;
    }

    console.log(walletDetail)


    return (<>
			<div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
		        data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
		        <NavOnDashboard user={userDetail}/>
		        <Sidebar />
		        <div className="page-wrapper">
		          <div className="page-m-height">
		          <div className="page-breadcrumb">
                        <div className="row">
                            <div className="col-12 col-sm-8 align-self-center mb-3 mb-sm-0">
                                <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">My Transaction</h3>
                            </div>
                            
                        </div>
                    </div>
		            <div className="container-fluid">
                        <div className="row">
                        	{/*<div className="col-lg-12 col-md-12 stretch-card">
			                    <div className="card">
			                        <div className="card-body">
			                            <div className=" brdrbtm-lblue mb-3 d-flex justify-content-between">
			                                <h4 className="card-title pb-3">My Balance - {walletDetail ? walletDetail.balance : 0}</h4>
			                                <p className="card-title pb-3">
                                            <a href='#!' className="btn btn-sm btn-primary float-right" onClick={(e)=>handleClick(e) }>Add Credit</a>
                                            
                                            </p>
			                               
			                            </div>   
			                        </div>
			                    </div>
			                </div>
			                <div className="col-lg-12 col-md-12 stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title brdrbtm-lblue pb-3 mb-3">Credit Summery</h4>
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead className="bg-info text-white">
                                                    <tr>
                                                        <th scope="col">Credit</th>
                                                        <th scope="col">Reason</th>
                                                        <th scope="col">Transaction Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {walletDetail && walletDetail.transactions.length > 0 ? walletDetail.transactions.map((trans, index)=>
                                                    <tr key={index}>
                                                        <td>{trans.type === 1 ? `- ${trans.credits}` : `+ ${trans.credits}`}</td>
                                                        <td>{ trans.purchase_type === 1 ? `Add Balance` :  (trans.purchase_type === 2 ? `Join a topic` : `Join a Session`)  }</td>
                                                        <td>{moment(trans.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                                    </tr>
                                                ) :<tr className="text-center">
                                                        <td colspan="3">No record found</td>
                                                    </tr>
                                                }    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                            <div className="col-lg-12 col-md-12 stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title brdrbtm-lblue pb-3 mb-3">Order Summery</h4>
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead className="bg-info text-white">
                                                    <tr>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Reason</th>
                                                        <th scope="col">Transaction Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {walletDetail && walletDetail.orders.length > 0 ? walletDetail.orders.map((trans, index)=>
                                                    <tr key={index}>
                                                        <td>{trans.type === 1 ? `- ${trans.amount}` : `+ ${trans.amount}`}</td>
                                                        <td>{ trans.purchase_type === 1 ? `Add Balance` :  (trans.purchase_type === 2 ? `Join a topic` : `Join a Session`)  }</td>
                                                        <td>{moment(trans.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                                    </tr>
                                                ) :<tr className="text-center">
                                                        <td colspan="3">No record found</td>
                                                    </tr>
                                                }    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
		          </div>
                    <ModalStripe show={show} isSubscription={false} plan={''} price={''} returnToBack={returnToBack} user={userDetail}/>
		            <FooterOnDashboard />
		        </div>
		    </div>	
			</>
			);
    


}

export default Wallet;