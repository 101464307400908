import React, { useEffect, useState} from "react";
import { LoaderScreen, NavOnDashboard, FooterOnDashboard, Sidebar, ModalStripe } from "../includes";
import { userInfo, setLoader, fetchRequestedSessionInfo, paySession } from "../../actions";
import { useSelector, useDispatch } from "react-redux";
 import moment from "moment";
import ReactHtmlParser from 'react-html-parser';

import { PATHS, WEB_VIDEO_URL, appConfig } from "../../constants";
import { titleCase } from "../../utils/general";
import SweetAlert from "react-bootstrap-sweetalert";



const ViewRequestedSession = (props) => { 

	const dispatch = useDispatch();
	const [alert, setAlert] = useState(null);
	const [show, setShow] = useState(false);

	useEffect(() => {
		let formValues = {
			'requested_session_id' : props.match.params.id,
		}
		
	    dispatch(fetchRequestedSessionInfo(formValues));
	    dispatch(userInfo());
	    dispatch(setLoader(true));

	
	 }, []);

  	const userDetail = useSelector((state) =>
	     state.service.userDetailResponse ? state.service.userDetailResponse.data : '',
	  );

  	const sessionDetail = useSelector((state) =>
	     state.service.reqSessionInfo ? state.service.reqSessionInfo.data : '',
	  );

  	console.log(sessionDetail);

  	const paySessionData = useSelector((state) =>{
  		 return state.service.paySessionData ? state.service.paySessionData : []
  	});

  

  	const isLoading = useSelector((state) =>
	      state.service.isLoading
	  );

  	const handleBack = e => {
		  e.preventDefault();
		 props.history.push(PATHS.REQUESTED_SESSION)
	}

	const successAlert = () => {
    setAlert(
              <div>
                <SweetAlert
                    success
                    title="Woo!"
                    confirmBtnText="Ok"
                    onConfirm={() => hideSuccessAlert()}
                  >
                    Payment has been made successful !
                  </SweetAlert>
              </div>
          );

  }

  const errorAlert = (messsage) => {
    setAlert(
              <div>
                 <SweetAlert
                    danger
                    title="Oops!"
                    confirmBtnText="Ok"
                    onConfirm={() => hideAlert()}

                  >
                    {messsage}
                  </SweetAlert>
              </div>
          );
  }

  const hideSuccessAlert = () =>{
  	setAlert(false);
	props.history.push(PATHS.LIVE_SESSION)
  }

  const hideAlert = () => {
	setAlert(false);

  }

  const payByWallet = (id) => {
  	setAlert(false);
    dispatch(paySession({session_id: id})).then((res)=>{
    	
    	if(res.payload.success){
  		
	  		successAlert()
	  	}else{
	  		errorAlert(res.payload.message) 
	  	}
	  	
    })

  }

  
	const payNow = e => {
		  e.preventDefault();
		setShow(true)
			// setAlert(
	  //             <div>
	  //             	<SweetAlert
			// 			warning
			// 			showCancel
			// 			title="Are you sure?"
			// 			confirmBtnText="Ok"
			// 			cancelBtnBsStyle="light"
			// 			onConfirm={() => payByWallet(sessionDetail.session_id)}
			// 			onCancel={hideAlert}
			// 			btnSize="sm"
			// 		>
			// 		Pay {sessionDetail.session ? sessionDetail.session.credits : ''} credits to join the session 
				
			// 			</SweetAlert>
	  //             </div>
	  //         );

	//	 props.history.push(PATHS.LIVE_SESSION)
	}

	const sessionUrl = (e) => {
	//	e.preventDefault();

		//let user_id = userDetail.id;

		let meeting = sessionDetail.session.meeting_id;

	//	let url = btoa(`${meeting}##${appConfig.conference.server}`); 

		let url = btoa(`${meeting}##${userDetail.id}`);

		return WEB_VIDEO_URL+url;
	}

	 const returnToBack = () => {
		   setShow(false);
		   let formValues = {
				'requested_session_id' : props.match.params.id,
			}
			
			dispatch(fetchRequestedSessionInfo(formValues))
	  }


  	
  	if (isLoading) {
      return <LoaderScreen />;
    }

	return (<>
			 {alert}
			<div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
		        data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
		        <NavOnDashboard user={userDetail}/>
		        <Sidebar />
		        <div className="page-wrapper">
		          <div className="page-m-height">
		          <div className="page-breadcrumb">
                        <div className="row">
                            <div className="col-12 col-sm-8 align-self-center mb-3 mb-sm-0">
                               {/*	 <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">Requested Session</h3> */}
                            </div>
                        </div>
                    </div>
		            <div className="container-fluid">
		              <div className="row">
		              {sessionDetail ? 
		                    <div className="col-12 stretch-card">
		                        <div className="card">
		                            <div className="card-body">
		                                <div className=" brdrbtm-lblue mb-3 d-flex justify-content-between">
		                                    <h4 className="card-title pb-3">Requested Session Detail</h4>
		                                    <p className="card-title pb-3"><a href="#!" onClick={(e)=>handleBack(e)}  className="btn btn-sm btn-danger float-right">Back</a></p>
		                                </div> 
		                                <table className="table table-hover">
		                                <tbody>
							                <tr>
							                    <th>Requested Session Name</th>
							                    <td>{sessionDetail.title ? titleCase(sessionDetail.title) : ""}</td>
							                </tr>
							                <tr>
							                    <th>Topic</th>
							                    <td>{sessionDetail.topic_info ? (sessionDetail.topic_info.title  ? sessionDetail.topic_info.title : ""): ""}</td>
							                </tr>

							                {sessionDetail.session && sessionDetail.session.start_time ?  
							                	<>
							                	 <tr>
								                    <th>Start Time</th>
								                    <td>{sessionDetail.session ? moment(sessionDetail.session.start_time).format('MMMM Do YYYY, h:mm:ss a') : "" }</td>
								                </tr>

								                <tr>
								                    <th>End Time</th>
								                     <td>{sessionDetail.session ? moment(sessionDetail.session.end_time).format('MMMM Do YYYY, h:mm:ss a') : "" }</td>
								                </tr>
								                </>

							                	: ""}
								               
							              
							                
							                <tr>
							                    <th>Description</th>
							                    <td>{sessionDetail.description ? ReactHtmlParser(sessionDetail.description) : "" }</td>
							                </tr>

							                {sessionDetail && sessionDetail.session && sessionDetail.session.is_paid === 0 || (sessionDetail.participate_info && sessionDetail.participate_info.is_paid === 1) ? 
							                	(sessionDetail.session && sessionDetail.session.session_url) ? 
							                		<tr>
									                    <th>Session Url</th>
									                    <td><a href={sessionDetail.session.session_url} className="btn btn-sm btn-primary" target="_blank">Join Session</a></td>
									                </tr>
									               :  
									               <tr>
									                    <th>Session Url</th>
									                    <td><a href={sessionUrl() } className="btn btn-sm btn-primary" target="_blank">Join Session</a></td>
									                </tr>
							                	:
							                	""}

							               {sessionDetail && sessionDetail.session && sessionDetail.session.is_paid === 1 && (sessionDetail.participate_info && sessionDetail.participate_info.is_paid === 0) ? 
							                		<tr>
									                    <th>Session Amount</th>
									                    <td><a href="#!" className="btn btn-sm btn-primary" onClick={ (e) => payNow(e) }>Pay {sessionDetail.session ? sessionDetail.session.credits : ''}</a></td>
									                </tr>
							                	:
							                	""} 	
							                	</tbody>
							            </table>
		                                        
		                            </div>
		                        </div>
		                    </div>
		                :
		                <div className="col-lg-12 col-md-12 stretch-card" data-aos="fade-up" data-aos-delay="100">
				              <div className="card">
				                  <div className="card-body">
				                  <p>No Record Found</p>
				                  </div>
				              </div>
				        </div>  
		               }    
		                </div>
		            </div>
		          </div>
		          <ModalStripe show={show} isSubscription={false} sessionId={sessionDetail && sessionDetail.session ? sessionDetail.session.id : ""} plan={''} price={sessionDetail && sessionDetail.session ? sessionDetail.session.credits : ''} returnToBack={returnToBack} user={userDetail}/>
		            <FooterOnDashboard />
		        </div>
		    </div>	
			</>
			);
	}

export default ViewRequestedSession;

