import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import loginBanner from "../../images/big/login-banner.svg";
import webLogo from "../../images/web-logo.svg";
import "../../App.css";
import { LoaderScreen } from "../includes";
import { setLoader, userLogin } from "../../actions";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { PATHS } from "../../constants";
import { required, email } from "../../constants/validation";
import {
  renderEmailWithIcon,
  renderPassWithIcon
} from "../../constants/redux-form";

/*
 TYPES
*/

type Props = {
  history: Object,
  handleSubmit: Function,
  setLoader: Function
};

class Login extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      pass: true,
      alertMessage: this.props.location.state
        ? this.props.location.state.alertMessage
        : "",
      alertClass: this.props.location.state
        ? this.props.location.state.alertClass
        : ""
    };

    //document.getElementById('canonical').setAttribute('href', window.location.href); 
  }

  componentDidMount() {
    AOS.init();
    if (localStorage.getItem("access_token")) {
      this.props.history.push(PATHS.DASHBOARD);
    } else {
      localStorage.clear();
    }

    setTimeout(() => {
      this.setState({ alertMessage: "", alertClass: "" });
    }, 4000);
  }

  handleChange(value, type) {
    this.setState({ [type]: !value }, () => {
      console.log(this.state);
    });
  }

  onSubmit = formValue => {
    this.props.setLoader(true);
    const email = formValue.email;
    const password = formValue.password;

    setTimeout(() => {
      this.props.userLogin({ email, password }).then(result => {
        console.log(this.props.response);
        this.props.setLoader(false);
        if (this.props.response) {
          if (this.props.response.success) {
            this.props.setLoader(false);
            if (this.props.response.data) {
              this.props.reset();
              // window.location.href = PATHS.DASHBOARD;
              this.props.history.push(PATHS.DASHBOARD);
            }
          } else {
            this.setState({
              alertMessage: this.props.response.message,
              alertClass: "alert alert-danger"
            });
            this.props.setLoader(false);
          }
        }

        setTimeout(() => {
          this.setState({ alertMessage: "", alertClass: "" });
        }, 3000);
      });
    }, 500);
  };

  togglePass(type) {
    console.log(type);
    if (type == "pass") {
      this.setState({ pass: !this.state.pass });
    } else {
      this.setState({ confirm_pass: !this.state.confirm_pass });
    }
  }

  render() {
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    return (
      <div className="main-wrapper">
        <div className="auth-wrapper d-flex no-block justify-content-center align-items-center position-relative auth-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-11">
                <div
                  className="auth-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="auth-left modal-bg-img">
                    <img src={loginBanner} className="img-fluid" alt="logo" />
                  </div>
                  <div className="auth-right bg-white">
                    <div className="p-4 p-md-5 auth-sec">
                      <div className="text-center auth-logo mb-3 mb-md-4 mb-lg-5">
                        <a href={PATHS.HOME}>
                          <img src={webLogo} alt="wrapkit" />
                        </a>
                      </div>
                      <h2 className="mt-3 text-center">Sign In</h2>
                      <p className="text-center">
                        Enter your email address and password to access the
                        panel.
                      </p>
                      <form
                        name="loginForm"
                        onSubmit={this.props.handleSubmit(this.onSubmit)}
                        className="mt-4"
                      >
                        {this.state.alertMessage ? (
                          <div className={this.state.alertClass} role="alert">
                            {this.state.alertMessage}
                          </div>
                        ) : null}
                        <div className="row">
                          <div className="col-lg-12">
                            <Field
                              icon="fas fa-user"
                              name="email"
                              type="email"
                              id="email"
                              validate={[required, email]}
                              placeholder={`Email`}
                              component={renderEmailWithIcon}
                            />
                          </div>
                          <div className="col-lg-12">
                            <Field
                              icon="fas fa-key"
                              name="password"
                              type={this.state.pass ? "password" : "text"}
                              id="password"
                              validate={[required]}
                              placeholder={`Password`}
                              component={renderPassWithIcon}
                              click={() => this.togglePass("pass")}
                            />
                          </div>

                          <div className="col-lg-12 text-center mb-4">
                            <button
                              type="submit"
                              className="btn btn-block btn-purple"
                            >
                              Sign In
                            </button>
                          </div>
                          <div className="col-lg-12 auth-links text-center mb-2">
                            <div className="mb-2">
                              <Link to={PATHS.FORGET_PASSWORD}>
                                Forgot your password?
                              </Link>
                            </div>
                            Don't have an account?{" "}
                            <Link to={PATHS.REGISTER}>Sign Up</Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  response: state.service ? state.service.response : "",
  langList: state.service.langList,
  isLoading: state.service.isLoading
});
export default connect(mapStateToProps, {
  setLoader,
  userLogin
})(reduxForm({ form: "loginForm" })(Login));
