import React, { Component } from "react";
import  {randomClass,  add3dots, titleCase}  from "../../utils/general"
import ReactHtmlParser from 'react-html-parser';
import {  faQuestion, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Post(props) {

  console.log(props);
  const topic = props.data;

  const handleView = (e,topic_id) => {
    e.preventDefault();


    if (e.type === 'click') {
     props.history.push('/quiz/' + topic_id)
    } else if (e.type === 'contextmenu') {
      window.open("/quiz/"+ topic_id, "_blank");
    } else{
      props.history.push('/quiz/' + topic_id)
    }
  
    
  }

  const handleViewQuiz = (e,topic_id) => {
    e.preventDefault();

     if (e.type === 'click') {
     props.history.push('/quiz-result/' + topic_id)
    } else if (e.type === 'contextmenu') {
      window.open("/quiz-result/"+ topic_id, "_blank");
    } else{
      props.history.push('/quiz-result/' + topic_id)
    }
    
  }
  
  return (
    <div className="col-lg-4 col-md-12 stretch-card">
      <div className="card">
          <div className="card-body ck-editor">
              <h4 className={randomClass() + " card-title brdrbtm-lblue pb-3 mb-3"}>{(titleCase(add3dots(topic.quiz_title, 30)))}</h4>
              
                <div style={{ overflow: "auto", height:"60px" }}>
                  {topic.description ? ReactHtmlParser(add3dots(topic.description, 70)) : ""}
                </div>
              
              <div className="cat-info-list">
                  <h5 className="card-title">Test Info</h5>
                  <ul className="list-style-none pl-3 mb-3">
                      <li>
                          <a href="#!" style={{pointerEvents: "none"}}>
                              <FontAwesomeIcon icon={faQuestion} className="text-info"/>{' '}
                              No Of Question - {topic.quiz_questions_count}
                          </a>
                      </li>
                      <li>
                          <a href="#!" style={{pointerEvents: "none"}}>
                              <FontAwesomeIcon icon={faCheck} className="text-info"/>{ ' '}
                               Total Marks - {topic.quiz_marks}
                          </a>
                      </li>
                      <li>
                          <a href="#!" style={{pointerEvents: "none"}}>
                              <FontAwesomeIcon icon={faCheck} className="text-info"/>{ ' '}
                               Attempt Status - { topic.quiz_attempt_status === 1 ? "Attempted" : "Not Attempt" }
                          </a>
                      </li>
                  </ul>
              </div>
              {topic.quiz_attempt_status === 1 ?
                <a href="#!" onClick={(e)=>handleViewQuiz(e,topic.id)} onContextMenu={(e)=>handleViewQuiz(e,topic.id)} className="btn waves-effect waves-light btn-rounded btn-primary pl-4 pr-4">View Result</a>
                :
                <a href="#!" onClick={(e)=>handleView(e,topic.id)} onContextMenu={(e)=>handleView(e,topic.id)}  className="btn waves-effect waves-light btn-rounded btn-primary pl-4 pr-4">Take Test</a>
              }
              
          </div>
      </div>
  </div>  )
}