import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import loginBanner from "../../images/big/login-banner.svg"
import  webLogo  from "../../images/web-logo.svg"
import "../../App.css";
import { LoaderScreen } from "../includes";
import {  setLoader, resetPswd } from "../../actions";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { Link } from "react-router-dom";
import { PATHS } from "../../constants";
import { required, passwordsMatch, whiteSpaceNotAllowed, passwordValidation } from "../../constants/validation";
import { renderEmailWithIcon } from "../../constants/redux-form";


/*
 TYPES
*/

type Props = {
  history: Object,
  handleSubmit: Function,
  setLoader: Function
};




class Reset extends Component<Props> {

  constructor(props) {
    super(props);
    this.state = {
       alertMessage: "",
       alertClass: "",
    };
  }

  componentDidMount() {
    AOS.init();
    if (localStorage.getItem("access_token")) {
      this.props.history.push(PATHS.DASHBOARD)
    } else {
      localStorage.clear();
    }

  }

  onSubmit = formValues => {

   this.props.setLoader(true);
    
     let successMessage = "";
     let errorMessage = "";


      let formValue = {
        password: formValues.password,
        password_confirmation: formValues.password_confirmation,
        token: this.props.match.params.token
      }
      setTimeout(() => {
        
        this.props.resetPswd(formValue).then(result => {

          console.log(this.props.response)

          if (this.props.response.success) {

            successMessage = this.props.response.message;
            this.setState({ alertMessage: successMessage, alertClass: "alert alert-success" });
            this.props.reset('resetPasswordForm');

          } else {
            this.props.reset('resetPasswordForm');
            errorMessage = this.props.response.message;
            this.setState({ alertMessage: errorMessage, alertClass: "alert alert-danger" });
          }

          setTimeout(() => {
            this.setState({ alertMessage: "", alertClass: "" })
          }, 3000);

        });
      }, 1000);
    
  };

  render() {
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    return (
      <div className="main-wrapper">
        <div className="auth-wrapper d-flex no-block justify-content-center align-items-center position-relative auth-bg">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-md-11">
                        <div className="auth-box" data-aos="fade-up" data-aos-delay="400">
                            <div className="auth-left modal-bg-img">
                                <img src={ loginBanner } className="img-fluid" alt=""/>
                            </div>
                            <div className="auth-right bg-white">
                                <div className="p-4 p-md-5 auth-sec">
                                    <div className="text-center auth-logo mb-5 mb-md-4 mb-lg-5">
                                        <Link to={PATHS.HOME}>
                                            <img src={webLogo} alt="wrapkit" />
                                        </Link>
                                    </div>
                                    <h2 className="mt-3 text-center">Reset Password</h2>
                                    <p className="text-center">Please provide your password.</p>
                                     <form
                                        name="resetPasswordForm"
                                        onSubmit={this.props.handleSubmit(this.onSubmit)}
                                        className="mt-4"
                                      >
                                      {this.state.alertMessage ? (
                                        <div className={this.state.alertClass} role="alert">
                                          {this.state.alertMessage}
                                        </div>
                                      ) : null}
                                        <div className="row">
                                           
                                            <div className="col-lg-12">
                                                <Field icon="fas fa-key" name="password" type="password" id="password" validate={[required, whiteSpaceNotAllowed, passwordValidation]} placeholder={`Password`} component={renderEmailWithIcon} />
                                            </div>

                                            <div className="col-lg-12">
                                                <Field className="form-control input_pass" icon="fas fa-key" name="confirm_password" type="password" id="confirmPassword" validate={[required, passwordsMatch]} placeholder={`Confirmation Password`} component={renderEmailWithIcon} />
                               
                                            </div>

                                            <div className="col-lg-12 text-center mb-4">
                                                <button type="submit" className="btn btn-block btn-purple">Reset Password</button>
                                            </div>
                                            <div className="col-lg-12 auth-links text-center mb-2">
                                                <div className="mb-2"> 
                                                   <Link to={PATHS.LOGIN}>Sign In?</Link>
                                                </div>
                                                Don't have an account? <Link to={PATHS.REGISTER}>Sign Up</Link>
                                                
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>        
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  }
}



const mapStateToProps = state => {
    console.log(state.service.response);
    return {
        response: state.service.response ? state.service.response : '',
        isLoading: state.service.isLoading
    };
};

export default connect(mapStateToProps, {
  setLoader,
  resetPswd
})(reduxForm({ form: "resetPasswordForm" })(Reset));