import React from 'react';
//import { LoaderScreen, NavOnDashboard, FooterOnDashboard, Sidebar } from "../../includes";

// Components
import HomePage from './HomePage';
// Stripe
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
// Styles
import './index.css';

//console.log("process.env.REACT_APP_STRIPE_PUB_KEY", process.env.REACT_APP_STRIPE_PUB_KEY);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

function Stripe(props) {
	console.log(props)

	// const handelBack = (e) => {
	// 	e.preventDefault();
	// 	props.returnToBack();
	// }	

  return (
        <Elements stripe={stripePromise}>
	      <HomePage {...props} />
	    </Elements>
  );	
}

export default Stripe;