import React, { useEffect} from "react";
import { LoaderScreen, NavOnDashboard, FooterOnDashboard, Sidebar } from "../includes";
import { userInfo, setLoader, fetchSessionInfo } from "../../actions";
import { useSelector, useDispatch } from "react-redux";
 import moment from "moment";
import ReactHtmlParser from 'react-html-parser';

import { PATHS, WEB_VIDEO_URL, appConfig } from "../../constants";
import { titleCase } from "../../utils/general"

const ViewSession = (props) => { 


	const dispatch = useDispatch();


	useEffect(() => {
		console.log("ASdasdasdsad");

		let formValues = {
			'session_id' : props.match.params.id,
		}
		
	    dispatch(fetchSessionInfo(formValues));
	    dispatch(userInfo());
	    dispatch(setLoader(true));
	 }, [dispatch, props]);

  	const userDetail = useSelector((state) =>
	     state.service.userDetailResponse ? state.service.userDetailResponse.data : '',
	  );

  	const sessionDetail = useSelector((state) =>
	     state.service.sessionDetail ? state.service.sessionDetail.data : '',
	  );

  	const isLoading = useSelector((state) =>
	      state.service.isLoading
	  );

  	const handleBack = e => {
		  e.preventDefault();
		 props.history.push(PATHS.LIVE_SESSION)
	}

	const sessionUrl = (e) => {
	//	e.preventDefault();

	//	let user_id = userDetail.id;

		let meeting = sessionDetail.meeting_id;

		// let url = btoa(`${meeting}##${appConfig.conference.server}`); 

		 let url = btoa(`${meeting}##${userDetail.id}`);

		return WEB_VIDEO_URL+url;
	}


  	
  	if (isLoading) {
      return <LoaderScreen />;
    }

	return (<>
			<div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
		        data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
		        <NavOnDashboard user={userDetail}/>
		        <Sidebar />
		        <div className="page-wrapper">
		          <div className="page-m-height">
		          <div className="page-breadcrumb">
                        <div className="row">
                            <div className="col-12 col-sm-8 align-self-center mb-3 mb-sm-0">
                                <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">Session Info</h3>
                            </div>
                        </div>
                    </div>
		            <div className="container-fluid">
		              <div className="row">
		              {sessionDetail ? 
		                    <div className="col-12 stretch-card">
		                        <div className="card">
		                            <div className="card-body">
		                                <div className=" brdrbtm-lblue mb-3 d-flex justify-content-between">
		                                    <h4 className="card-title pb-3">Session Detail</h4>
		                                    <p className="card-title pb-3"><a href="#!" onClick={(e)=>handleBack(e)}  className="btn btn-sm btn-danger float-right">Back</a></p>
		                                </div> 
		                                <table className="table table-hover">
		                                <tbody>
							                <tr>
							                    <th>Session Name</th>
							                    <td>{sessionDetail.session_title ? titleCase(sessionDetail.session_title) : ""}</td>
							                </tr>
							                <tr>
							                    <th>Topic</th>
							                    <td>{sessionDetail.topic_info ? (sessionDetail.topic_info.title  ? sessionDetail.topic_info.title : ""): ""}</td>
							                </tr>

							                <tr>
							                    <th>Start Time</th>
							                    <td>{sessionDetail.start_time ? moment(sessionDetail.start_time).format('MMMM Do YYYY, h:mm:ss a') : "" }</td>
							                </tr>

							                <tr>
							                    <th>End Time</th>
							                     <td>{sessionDetail.end_time ? moment(sessionDetail.end_time).format('MMMM Do YYYY, h:mm:ss a') : "" }</td>
							                </tr>

							                <tr>
							                    <th>Faculty Name</th>
							                    <td>{sessionDetail.faculty ? (sessionDetail.faculty.name  ? sessionDetail.faculty.name : ""): ""} </td>
							                </tr>

							                <tr>
							                    <th>Faculty Experience</th>
							                    <td>{sessionDetail.faculty ? (sessionDetail.faculty.year_of_experience  ? sessionDetail.faculty.year_of_experience : ""): ""} yrs</td>
							                </tr>

							                <tr>
							                    <th>Faculty Subject</th>
							                     <td>{sessionDetail.faculty ? (sessionDetail.faculty.subject_expertise  ? sessionDetail.faculty.subject_expertise : ""): ""}</td>
							                </tr>


							                <tr>
							                    <th>No of Participants</th>
							                    <td>
							                        {sessionDetail.participants_count ? sessionDetail.participants_count : "" }
							                    </td>
							                </tr>
							                <tr>
							                    <th>Description</th>
							                    <td>{sessionDetail.session_description ? ReactHtmlParser(sessionDetail.session_description) : "" }</td>
							                </tr>
							                
							                 { sessionDetail.is_paid === 0 || (sessionDetail.participants && sessionDetail.participants[0].is_paid === 1) ? 
							                	(sessionDetail.session_url && sessionDetail.session_url) ? 
							                		<tr>
									                    <th>Session Url</th>
									                    <td><a href={sessionDetail.session_url} className="btn btn-sm btn-primary" target="_blank">Join Session</a></td>
									                </tr>
									               :  
									               <tr>
									                    <th>Session Url</th>
									                    <td><a href={ sessionUrl() } className="btn btn-sm btn-primary" target="_blank">Join Session</a></td>
									                </tr>
							                	:
							                	""}	
							              </tbody>   	
							            </table>
		                                        
		                            </div>
		                        </div>
		                    </div>
		                :
		                <div className="col-lg-12 col-md-12 stretch-card" data-aos="fade-up" data-aos-delay="100">
				              <div className="card">
				                  <div className="card-body">
				                  <p>No Record Found</p>
				                  </div>
				              </div>
				        </div>  
		               }    
		                </div>
		            </div>
		          </div>
		            <FooterOnDashboard />
		        </div>
		    </div>	
			</>
			);
	}

export default ViewSession;

