import React, { useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserImage } from "../../actions";
import useScript from '../../hooks/useScript';

import "./chatweb.css";

//import botAvatar from "../static/img/botAvatar.png";

 //import Jobs from "../../external-js/script.js"
import * as $ from 'jquery';

const ChatWeb = (props) => { 

    const jsFile = "/static/js/script.js";


	const dispatch = useDispatch();

    useScript(jsFile);

    useEffect(() => {

      let user_id = props.match.params.user_id;

      let id = {
        id:user_id
      }

      dispatch(fetchUserImage(id));

      window.name = user_id;

        // var elemsTap = document.querySelector('.tap-target') || null;
        // if( elemsTap){
        //     var instancesTap = window.M.TapTarget.init(elemsTap, {});
        //         instancesTap.open();
        //     setTimeout(function() { 
        //         instancesTap.close(); }, 4000);
        // }
     }, [dispatch]);
     
     const imagePath = useSelector(state => {
      return state.service.userImage ? state.service.userImage.data:[] 
    });
   
   
    if(imagePath){
      window.user_image = imagePath;
    }


    return (
        
                      <div className="container">
                                    <div className ="chatweb">
                                    <div className="widget" style={{display: "block"}}>
                                            <div className="chat_header">
                                                <span className="chat_header_title">
                                                <img className="imgcard" src="/static/img/web-logo.svg" />
                                                </span>
                                                 <div className="dropdown">
                                                 {/* <p className="dropdown-trigger" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="material-icons">
                                                                  more_vert
                                                                  </i>
                                                  </p> 
                                                 
                                                    <ul id='dropdown1' className='dropdown-menu' aria-labelledby="dropdownMenuButton">
                                                        <li className="dropdown-item" id="clear"><a href="#!" >Clear</a></li>
                                                    </ul> */}
                                                    <button id="clear" className="btn btn-primary">Clear</button>
                                                </div>               

                                            </div>

                                            <div className="chats" id="chats">
                                                <div className="clearfix"></div>
                                            </div>

                                            <div className="keypad">
                                                <textarea id="userInput" placeholder="Type a message..." className="usrInput"></textarea>
                                                <div id="sendButton"><i className="fa fa-paper-plane" aria-hidden="true"></i></div>
                                            </div>
                                        </div>
                                
                                        <div className="profile_div" id="profile_div" style={{display: "none"}}>
                                            <img className="imgProfile" src='/static/img/botAvatar.png' />
                                        </div>

                                        <div className="tap-target" data-target="profile_div">
                                            <div className="tap-target-content">
                                                <h5 className="white-text">Hello 👋</h5>
                                                <p className="white-text">What can i do for you</p>
                                            </div>
                                        </div>
                                    </div>
                                
                			</div>
                     
			);
    


}

export default ChatWeb;