import React, { Component } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import { PATHS } from "../../constants";

import { faHeart, faHeartBroken } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../../App.css";
import {
  LoaderScreen,
  NavOnIndex,
  FooterOnIndex,
  ModalChapter,
  ModalStripe
} from "../includes";
import {
  setLoader,
  fetchTopicInfo,
  userInfo,
  deleteMyTopic,
  addMyTopic,
  getQuizResult
} from "../../actions";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import { add3dots } from "../../utils/general";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";

/*
 TYPES
*/

type Props = {
  history: Object,
  fetchTopicInfo: Function,
  setLoader: Function,
  isLoading: boolean
};

class TopicInfo extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      isAuth: false,
      show: false,
      chapterDetails: [],
      prev: "",
      next: "",
      purchaseShow: false,
      purchasePrice: "",
      topicId: ""
    };
  }

  getTopicInfo = async () => {
    this.props.setLoader(true);

    const user = (await localStorage.getItem("isAuth")) ? true : false;

    if (user) {
      this.props.userInfo().then(result => {
        if (this.props.userDetail) {
          this.props.setLoader(false);
        }
      });
    }

    var formValue = {
      topic_id: this.props.match.params.topic,
      user_id: this.props.userDetail ? this.props.userDetail.id : ""
    };

    // if(medium_id){
    //   formValue.medium_id = medium_id;
    // }

    this.props.fetchTopicInfo(formValue).then(result => {
      if (this.props.topicInfo && this.props.topicInfo.success) {
        this.props.setLoader(false);
      } else {
        this.props.setLoader(false);
      }
    });

    this.setState({ isAuth: user });
  };

  async componentDidMount() {
    await this.getTopicInfo();
  }

  quizAttempt = async quiz_id => {
    const checkAuth = await this.checkIsValidUser();

    if (!checkAuth) {
      return;
    }

    this.props.setLoader(true);
    this.props.getQuizResult({ quiz_id: quiz_id }).then(result => {
      console.log(this.props.quizResponse);
      if (this.props.quizResponse && this.props.quizResponse.success) {
        this.props.setLoader(false);
        this.props.history.push("/quiz-result/" + quiz_id);
      } else {
        this.props.setLoader(false);
        this.props.history.push("/quiz/" + quiz_id);
      }
    });
  };

  dynamicIcon = type => {
    let icon = null;
    switch (type) {
      case 1:
        icon = "fas fa-sticky-note";
        break;
      case 2:
        icon = "fas fa-file";
        break;
      case 3:
        icon = "fas fa-play-circle";
        break;
      default:
        icon = "fas fa-sticky-note";
    }

    return icon;
  };

  checkIsValidUser = () => {
    if (!this.state.isAuth) {
      confirmAlert({
        title: "Not Logged in",
        message: "Please logged in to access the content?",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.props.history.push("/login")
          },
          {
            label: "No",
            onClick: () => ""
          }
        ]
      });
      return false;
    }

    let topicDetail = this.props.topicInfo.data;

    let topicPaidByUser = this.props.topicInfo.is_paid === true ? true : false;

    let subscriptionActive =
      this.props.topicInfo.is_subscription_active === true ? true : false;

    console.log(subscriptionActive, topicDetail, this.props.userDetail);

    let isUniversityStudent = this.props.userDetail
      ? this.props.userDetail.is_university_student
      : 0;

    if (isUniversityStudent === 1) {
      return true;
    }

    if (topicDetail.pay_type === 2 && !topicPaidByUser) {
      confirmAlert({
        title: "Paid Content",
        message: `You have to pay ${topicDetail.price} for read this content`,
        buttons: [
          {
            label: "Ok",
            onClick: () => ""
          }
        ]
      });
      return false;
    } else if (topicDetail.pay_type === 1 && !subscriptionActive) {
      confirmAlert({
        title: "Paid Content",
        message: `This is the paid content so please buy our membership to proceed further`,
        buttons: [
          {
            label: "Yes",
            onClick: () =>
              this.props.history.push({
                pathname: "/plans",
                state: true
              })
          },
          {
            label: "No",
            onClick: () => ""
          }
        ]
      });
      return false;
    } else {
      return true;
    }
  };

  renderModal = async (id, pay_type) => {
    const checkAuth = await this.checkIsValidUser();

    if (!checkAuth) {
      return;
    }

    let chapterDetails = this.props.topicInfo.data.chapters[id]
      .chapter_contents[pay_type];

    // let next = pay_type +1 < this.props.topicInfo.data.chapters[id].chapter_contents.length ? pay_type +1  : undefined;
    // let prev = ( pay_type - 1 ) === -1 ? undefined : pay_type - 1;
    let next = undefined;
    let prev = undefined;

    if (!this.props.topicInfo.added_in_my_topic) {
      this.props.addMyTopic(this.props.topicInfo.data.id);
    }

    this.setState({
      show: true,
      chapterDetails: chapterDetails,
      next: next,
      prev: prev
    });
  };

  showModal = async () => {
    await this.getTopicInfo();
    this.setState({ show: !this.state.show });
  };

  handleRemove = mytopic_id => {
    var formValue = {
      topic_id: this.props.match.params.topic,
      user_id: this.props.userDetail ? this.props.userDetail.id : ""
    };

    confirmAlert({
      title: "Delete",
      message: "Are you sure want to Delete this from My topic?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            this.props.deleteMyTopic(mytopic_id).then(result => {
              this.props.fetchTopicInfo(formValue);
            })
        },
        {
          label: "No",
          onClick: () => ""
        }
      ]
    });
  };

  handleAdd = mytopic_id => {
    var formValue = {
      topic_id: this.props.match.params.topic,
      user_id: this.props.userDetail ? this.props.userDetail.id : ""
    };

    confirmAlert({
      title: "Add",
      message: "Are you sure want to add this topic for later use?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            this.props.addMyTopic(mytopic_id).then(result => {
              this.props.fetchTopicInfo(formValue);
            })
        },
        {
          label: "No",
          onClick: () => ""
        }
      ]
    });
  };

  next = chapter => {
    let data = this.props.topicInfo.data.chapters.filter(function(item) {
      return item.id === chapter.chapter_id;
    });

    let index = data[0].chapter_contents.findIndex(
      obj => obj.id === this.state.chapterDetails.id
    );
    let nextIndex = index + 1;
    let chapterDetails = data[0].chapter_contents[nextIndex];

    let next =
      nextIndex + 1 < data[0].chapter_contents.length
        ? nextIndex + 1
        : undefined;
    let prev = nextIndex - 1 === -1 ? undefined : nextIndex - 1;

    this.setState({
      show: true,
      chapterDetails: chapterDetails,
      next: next,
      prev: prev
    });
  };

  prev = chapter => {
    let data = this.props.topicInfo.data.chapters.filter(function(item) {
      return item.id === chapter.chapter_id;
    });

    let index = data[0].chapter_contents.findIndex(
      obj => obj.id === this.state.chapterDetails.id
    );
    let prevIndex = index - 1;
    let chapterDetails = data[0].chapter_contents[prevIndex];

    let next =
      prevIndex + 1 < data[0].chapter_contents.length
        ? prevIndex + 1
        : undefined;
    let prev = prevIndex - 1 === -1 ? undefined : prevIndex - 1;

    this.setState({
      show: true,
      chapterDetails: chapterDetails,
      next: next,
      prev: prev
    });
  };

  returnToBack = () => {
    this.setState({
      purchaseShow: false,
      purchasePrice: ""
    });
    this.getTopicInfo();
  };

  handleClick = async (e, price) => {
    e.preventDefault();

    if (!this.state.isAuth) {
      confirmAlert({
        title: "Not Logged in",
        message: "Please logged in to access the content?",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.props.history.push("/login")
          },
          {
            label: "No",
            onClick: () => ""
          }
        ]
      });
      return false;
    }

    this.setState({
      purchaseShow: true,
      purchasePrice: this.props.topicInfo.data.price,
      topicId: this.props.topicInfo.data.id
    });
  };

  render() {
    let topicInfo = this.props.topicInfo ? this.props.topicInfo.data : null;
    let chapterCount = this.props.topicInfo
      ? this.props.topicInfo.chapter_count
      : 0;
    let alreadyAdded = this.props.topicInfo
      ? this.props.topicInfo.added_in_my_topic
      : null;
    let alreadyPaid = this.props.topicInfo
      ? this.props.topicInfo.is_paid
      : false;

    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    return topicInfo ? (
      <div className="main-wrapper white-bg">
        <NavOnIndex inner="true" user={this.props.userDetail} />
        <div className="innr-banner inn-pt">
          <div className="container pt-4 pb-4">
            <div className="row">
              <div className="col-md-9 col-lg-10">
                <nav
                  aria-label="breadcrumb"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <ol className="breadcrumb p-0">
                    <li className="breadcrumb-item">
                      <a href={PATHS.HOME}>Home</a>
                    </li>
                    <li className="breadcrumb-item"> {topicInfo.title}</li>
                  </ol>
                </nav>
                <div className="mb-3" data-aos="fade-up" data-aos-delay="200">
                  <h1>{topicInfo.title}</h1>
                  <strong>
                    {/* ReactHtmlParser(add3dots(topicInfo.description,100)) */}

                    <HTMLEllipsis
                      isClamped={false}
                      unsafeHTML={topicInfo.description}
                      maxLine="2"
                      ellipsis="..."
                      ellipsisHTML="<i>... read more</i>"
                      basedOn="letters"
                    />
                  </strong>
                </div>
                <div
                  className="mb-3 ib-update"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <span>
                    <i className="fas fa-user-edit"></i>
                    &nbsp;Last updated{" "}
                    {moment(topicInfo.created_at).format("MM/YYYY")}
                  </span>
                  {/*<span>
                                <i className="fas fa-globe"></i>
                                &nbsp;English
                            </span>
                            {topicInfo.tags ? 
                            <span>
                                <i className="fas fa-language"></i>
                               &nbsp; {topicInfo.tags}
                            </span>
                            : ''}
                            */}
                </div>
              </div>
              {!this.state.isAuth ? (
                ""
              ) : alreadyAdded ? (
                <div className="col-md-3 col-lg-2 text-right">
                  <a
                    href="#0"
                    className="btn waves-effect waves-light btn-rounded btn-danger pl-4 pr-4"
                    onClick={() => this.handleRemove(topicInfo.id)}
                  >
                    <FontAwesomeIcon icon={faHeartBroken} />
                  </a>
                </div>
              ) : (
                <div className="col-md-3 col-lg-2 text-right">
                  <a
                    href="#0"
                    className="btn waves-effect waves-light btn-rounded btn-cyan pl-4 pr-4"
                    onClick={() => this.handleAdd(topicInfo.id)}
                  >
                    <FontAwesomeIcon icon={faHeart} />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="section py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="black-heading mb-4">
                  <h2 className="text-left">What you'll learn</h2>
                </div>
              </div>
              <div className="col-md-6">
                <div className="section-bg p-3 brdr-dark brdr-5radius mb-3 maxheight">
                  {ReactHtmlParser(topicInfo.learning_content_1)}
                </div>
              </div>
              <div className="col-md-6">
                <div className="section-bg p-3 brdr-dark brdr-5radius mb-3 maxheight">
                  {ReactHtmlParser(topicInfo.learning_content_2)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="course-content section section-bg brdr-tp-dark brdr-btm-dark py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="black-heading mb-3 mb-md-4">
                  <h2 className="text-left">Course content</h2>
                </div>

                <div className="mb-3 d-flex justify-content-between flex-column flex-md-row">
                  <strong>
                    {topicInfo.chapters.length > 0
                      ? topicInfo.chapters.length > 1
                        ? `${topicInfo.chapters.length} Sections`
                        : `${topicInfo.chapters.length} Section`
                      : ""}{" "}
                    {chapterCount > 0
                      ? chapterCount > 1
                        ? `• ${chapterCount} Modules`
                        : `• ${chapterCount} Module`
                      : ""}{" "}
                  </strong>

                  {this.props.userDetail &&
                  this.props.userDetail.is_university_student === 0 &&
                  !alreadyPaid && topicInfo.pay_type === 2 ? (
                    <a
                      href="#!"
                      className="btn btn-sm btn-primary float-right"
                      onClick={e => this.handleClick(e, topicInfo.price)}
                    >
                      Pay {topicInfo.price}
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="course-list">
                  <ul>
                    {topicInfo.chapters &&
                      topicInfo.chapters.map((chapter, i) => (
                        <li key={i}>
                          <a
                            data-toggle="collapse"
                            className="collapsed"
                            href={`#course-list-${i}`}
                          >
                            <div className="cc-title">
                              <strong>{chapter.title}</strong>
                              {chapter.chapter_contents.length > 0 ? (
                                <span>
                                  {chapter.chapter_contents.length > 1
                                    ? `${chapter.chapter_contents.length} Chapters`
                                    : `${chapter.chapter_contents.length} Chapter`}{" "}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <i className="fas fa-chevron-down icon-show"></i>
                            <i className="fas fa-chevron-up icon-close"></i>
                          </a>
                          <div
                            id={`course-list-${i}`}
                            className="collapse pt-3"
                            data-parent=".course-list"
                          >
                            {chapter.chapter_contents &&
                              chapter.chapter_contents.map((content, ind) => (
                                <div className="cc-detail" key={ind}>
                                  <div>
                                    <i
                                      className={this.dynamicIcon(
                                        content.content_type
                                      )}
                                    ></i>
                                    &nbsp;
                                    <span
                                      onClick={() => this.renderModal(i, ind)}
                                    >
                                      {content.title}
                                    </span>
                                  </div>
                                  {/* <span className="ml-auto">{content.content_paid === 1 ? 'Paid' : 'Free'}</span> */}
                                </div>
                              ))}

                            {chapter.quiz && (
                              <div className="cc-detail">
                                <div>
                                  <i className="fas fa-question"></i>&nbsp;
                                  <span
                                    onClick={() =>
                                      this.quizAttempt(chapter.quiz.id)
                                    }
                                  >
                                    {chapter.quiz && chapter.quiz_name
                                      ? chapter.quiz_name
                                      : chapter.quiz.quiz_title}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="black-heading mb-4">
                  <h2 className="text-left">Description</h2>
                </div>
              </div>
              <div className="col-md-12">
                {ReactHtmlParser(topicInfo.description)}
              </div>
            </div>
          </div>
        </div>
        <ModalChapter
          onClose={this.showModal}
          show={this.state.show}
          chapterDetails={this.state.chapterDetails}
          next={this.state.next === undefined ? undefined : this.next}
          prev={this.state.prev === undefined ? undefined : this.prev}
        />

        <ModalStripe
          show={this.state.purchaseShow}
          isSubscription={false}
          topicId={this.state.topicId}
          plan={""}
          price={this.state.purchasePrice}
          returnToBack={this.returnToBack}
          user={this.props.userDetail}
        />
        <FooterOnIndex />
      </div>
    ) : (
      ""
    );
  }
}

const mapStateToProps = state => {
  // console.log(state.service);
  return {
    isLoading: state.service.isLoading,
    userDetail: state.service.userDetailResponse
      ? state.service.userDetailResponse.data
      : "",
    topicInfo: state.service.topicInfoResponse
      ? state.service.topicInfoResponse.data
      : null,
    quizResponse: state.service ? state.service.quizResponse : ""
  };
};

export default connect(mapStateToProps, {
  setLoader,
  userInfo,
  fetchTopicInfo,
  deleteMyTopic,
  addMyTopic,
  getQuizResult
})(TopicInfo);
