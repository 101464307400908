import React, {useState} from "react";
import { Modal, Button } from 'react-bootstrap';
import Stripe from './Stripe';


const ModalStripe = (props) => { 

	console.log(props);

	if(!props.show){
          return null;
      }

	return (<>
				<Modal
				  size="lg"	
	              show={props.show}
	              onHide={() => props.returnToBack()}
	              aria-labelledby="example-modal-sizes-title-lg"
	            >
	              <Modal.Header closeButton>
	                <Modal.Title id="example-modal-sizes-title-lg">
	                 {props.isSubscription ? 'Purchase Plan'  : 'Purchase ' }
	                </Modal.Title>
	              </Modal.Header>
	                <Modal.Body>
	              		<Stripe {...props} />
	              	</Modal.Body>
	            </Modal>
			</>
			);
	}	

export default ModalStripe;
