import React, { Component } from "react";
import { connect } from "react-redux";
 import { Link } from "react-router-dom";
 import { PATHS } from "../../constants";


import "../../App.css";
import { LoaderScreen, NavOnIndex, FooterOnIndex } from "../includes";
import { fetchFaqList, setLoader } from "../../actions";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import Faq from "react-faq-component";

/*
 TYPES
*/

type Props = {
  history: Object,
  langList: Object,
  fetchCmsPage: Function,
  setLoader: Function,
  isLoading: boolean
};


const styles = {
    bgColor: 'white',
    titleTextColor: "blue",
    rowTitleColor: "blue",
    rowContentColor: 'grey',
    arrowColor: "red",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};

class Faqs extends Component<Props> {

  constructor(props){
       super(props)
       this.state = {
       
       }
    }

  componentDidMount() {
 
   this.props.fetchFaqList();
    AOS.init();
   // document.getElementById('canonical').setAttribute('href', window.location.href); 
   
  }

  


  render() {
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    return (
      <div className="main-wrapper white-bg">
        <NavOnIndex inner="true"/>
            <div className="innr-banner inn-pt">
              <div className="container pt-4 pb-4">
                  <div className="row">
                      <div className="col-md-10">
                          <nav aria-label="breadcrumb" data-aos="fade-up" data-aos-delay="100" className="aos-init aos-animate">
                            <ol className="breadcrumb p-0">
                                <li className="breadcrumb-item"><Link to={PATHS.HOME}>Home</Link></li>
                                <li className="breadcrumb-item" aria-current="page">FAQs</li>
                            </ol>
                        </nav>
                        <div className="mb-2 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <h1>
                                FAQs
                            </h1>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            <div className="section py-5">
                  <div className="container">
                    <Faq
                        data={this.props.response}
                        styles={styles}
                        config={config}
                    />
                </div>
            </div>
        <FooterOnIndex />

      </div>
    );
  }
}

const mapStateToProps = state => {
    console.log(state.service.getFaqResponse);
    return {
        response: state.service.getFaqResponse ? state.service.getFaqResponse.data : '',
        isLoading: state.service.isLoading
    };
};

export default connect(mapStateToProps, { fetchFaqList, setLoader })(Faqs);
