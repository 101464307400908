import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { setLoader, contactForm, contactUsSubmit } from "../../actions";
import { required, email, whiteSpace, phoneNumber, number } from "../../constants/validation";
import {  renderInputNormal, renderTextArea } from "../../constants/redux-form";


class Contact extends Component<Props> {

	constructor(props) {
        super(props);
        this.state = {
            alertMessage: "",
            alertClass: "",
        };
        //document.getElementById('canonical').setAttribute('href', window.location.href); 
    }

    componentWillMount() {
        this.props.initialize('contactForm');
    }



    onSubmit = formValues => {
        let errorMessage = "";
        setTimeout(() => {

            this.props.contactUsSubmit(formValues)
                .then(result => {
                    this.props.setLoader(false);
                    if (this.props.response.success) {
                        this.props.setLoader(false);
                        this.props.reset('contactForm');
                        this.setState({ alertMessage: this.props.response.message, alertClass: "alert alert-success" });
                    } else {
                        this.props.setLoader(false);
                        errorMessage = this.props.response.message;
                        this.setState({ alertMessage: errorMessage, alertClass: "alert alert-danger" });
                    }

                    setTimeout(() => {
                        this.setState({ alertMessage: "", alertClass: "" })
                    }, 3000);
                })
                .catch(e => {
                    this.props.setLoader(false);
                });
        }, 500);
    };

	render() {

        return (
        		<div className="col-md-10">
	        		<form
	                    name="contactForm"
	                    onSubmit={this.props.handleSubmit(this.onSubmit)}
	                >
	                {this.state.alertMessage ? (
                                        <div className={this.state.alertClass} role="alert">
                                            {this.state.alertMessage}
                                        </div>
                                    ) : null}
                        <div className="row">
                            <div className="col-md-6" data-aos="fade-up" data-aos-delay="200">
                                <div className="form-group">
                                    <Field name="first_name" type="text" id="first_name" validate={[required, whiteSpace]} placeholder={`First Name`} component={renderInputNormal} />
                                </div>
                            </div>

                            <div className="col-md-6" data-aos="fade-up" data-aos-delay="500">
                                <div className="form-group">        
                                    <Field name="last_name" type="text" id="last_name" validate={[required, whiteSpace]} placeholder={`Last Name`} component={renderInputNormal} />                              
                                </div>
                            </div>
                            <div className="col-md-6" data-aos="fade-up" data-aos-delay="300">
                                <div className="form-group">        
                                     <Field name="email" type="email" id="email" validate={[required, email]} placeholder={`Email`} component={renderInputNormal} />
                                </div>
                            </div>
                            <div className="col-md-6" data-aos="fade-up" data-aos-delay="400">
                                <div className="form-group">        
                                   <Field name="phone_number" id="phone_number" type="text" validate={[number, phoneNumber, required]} placeholder={`Phone Number`} component={renderInputNormal} />
                                </div>
                            </div>
                            <div className="col-md-12" data-aos="fade-up" data-aos-delay="600">
                                <div className="form-group">        
                                     <Field name="message" id="message" validate={[required]} placeholder={`Please write something for us`} component={renderTextArea} />
                                </div>
                            </div>
                            <div className="col-md-12 text-center" data-aos="fade-up" data-aos-delay="700">
                                <button type="submit" className="btn btn-primary pl-4 pr-4">Submit</button>
                            </div>
                        </div>
                     </form>
                </div>
        	);
    }

}

const mapStateToProps = state => {
    return {
        response: state.service.getContactResponse,
        isLoading: state.service.isLoading
    };
};

export default connect(mapStateToProps, {
    setLoader,
    contactForm,
    contactUsSubmit
})(reduxForm({ form: "contactForm"})(Contact));	