import React, { Component } from "react";
import { connect } from "react-redux";

import loginBanner from "../../images/big/login-banner.svg"
import  webLogo  from "../../images/web-logo.svg"
import "../../App.css";
import { LoaderScreen } from "../includes";
import {  setLoader, userRegistration } from "../../actions";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { required, email, passwordsMatch, phoneNumberValidation,whiteSpaceNotAllowed, passwordValidation } from "../../constants/validation";
import { renderEmailWithIcon, RenderCheckbox, renderPassWithIcon } from "../../constants/redux-form";
import { Link } from "react-router-dom";
import { PATHS } from "../../constants";
import { Field, reduxForm } from "redux-form";

/*
 TYPES
*/
type Props = {
    history: Object,
    handleSubmit: Function,
    setLoader: Function
};



class Register extends Component<Props> {

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      is_university_student:false,
      pass:true,
      confirm_pass:true
    
    };
    //document.getElementById('canonical').setAttribute('href', window.location.href); 
  }

  componentDidMount() {
     this.props.reset();
    AOS.init();

  }

  onSubmit = formValue => {
    
     this.props.setLoader(true);
     setTimeout(() => {
      this.props.userRegistration(formValue).then(result => {
        
        if (this.props.response) {
          if (this.props.response.success) {
            this.props.reset();
             this.setState({ alertMessage: this.props.response.message, alertClass: "alert alert-success" });
             this.props.setLoader(false);
          } else {
            // if(this.props.response.error){
            //   this.setState({ alertMessage: this.props.response.error.email, alertClass: "alert alert-danger" });
            // }else{
              this.setState({ alertMessage: this.props.response.message, alertClass: "alert alert-danger" });
           // }
            
          //   this.props.setLoader(false);
          }
        }
        this.props.setLoader(false);
        setTimeout(() => {
          this.setState({ alertMessage: "", alertClass: "" })
        }, 3000);
      });
    }, 500);
  };


  handleChange(value, type) {
     this.setState({[type]: !value}, ()=>{
        console.log(this.state)
     })
  }

  togglePass(type){
    console.log(type);
      if(type == 'pass'){
        this.setState({pass: !this.state.pass})
      }else{
        this.setState({confirm_pass: !this.state.confirm_pass})
      }
  }

  render() {

    console.log(this.state.pass);
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    return (
      <div className="main-wrapper">
        <div className="auth-wrapper d-flex no-block justify-content-center align-items-center position-relative auth-bg">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-11">
                        <div className="auth-box" data-aos="fade-up" data-aos-delay="400">
                            <div className="auth-left modal-bg-img">
                                <img src={ loginBanner } className="img-fluid" alt=""/>
                            </div>
                            <div className="auth-right bg-white">
                                <div className="p-4 p-md-5 auth-sec">
                                    <div className="text-center auth-logo mb-3 mb-md-4 mb-lg-5">
                                        <Link to={PATHS.HOME}>
                                            <img src={webLogo} alt="wrapkit" />
                                        </Link>
                                    </div>
                                    <h2 className="mt-3 text-center">Sign Up</h2>
                                    <p className="text-center">Enter your information to access the panel.</p>
                                    <form
                                        name="registerForm"
                                        onSubmit={this.props.handleSubmit(this.onSubmit)}
                                        className="mt-4"
                                      >
                                      {this.state.alertMessage ? (
                                        <div className={this.state.alertClass} role="alert">
                                          {this.state.alertMessage}
                                        </div>
                                      ) : null}
                                        <div className="row">
                                            <div className="col-lg-12">
                                                
                                                <Field className="form-control input_user" icon="fas fa-user" name="name" type="text" id="name" validate={[required]} placeholder={`Name`} component={renderEmailWithIcon} />
                                            </div>
                                            <div className="col-lg-12">
                                                <Field className="form-control input_user" icon="fas fa-envelope" name="email" type="email" id="email" validate={[required, email]} placeholder={`Email`} component={renderEmailWithIcon} />
                                            </div>
                                            <div className="col-lg-12">
                                                <Field className="form-control input_user" icon="fas fa-phone" name="mobile_number" type="text" id="mobile_number" validate={[required, phoneNumberValidation]} placeholder={`Phone Number`} component={renderEmailWithIcon} />
                                            </div>
                                            <div className="col-lg-12">
                                                <Field className="form-control input_pass" icon="fas fa-key" name="password" type={this.state.pass ? "password" : "text"} id="password" validate={[required, whiteSpaceNotAllowed, passwordValidation]} placeholder={`Password`} component={renderPassWithIcon} click={()=>this.togglePass('pass')} />
                                            </div>

                                            <div className="col-lg-12">
                                                <Field className="form-control input_pass" icon="fas fa-key" name="confirm_password" type={this.state.confirm_pass ? "password" : "text"} id="confirmPassword" validate={[required, passwordsMatch]} placeholder={`Confirmation Password`} component={renderPassWithIcon} click={()=>this.togglePass('confirm_pass')} />
                                             
                                            </div>

                                             <div className="col-lg-12 mb-2">
  
                                              <Field 
                                                component="input" 
                                                type="checkbox" 
                                                name="is_university_student" 
                                                id="is_university_student"
                                                value={this.state.is_university_student || false}
                                              />{`  Are you a SBEC student ? `}
                                              
                                            </div>

                                            

                                            <div className="col-lg-12 text-center mb-4">
                                                <button type="submit" className="btn btn-block btn-purple">Sign Up</button>
                                            </div>
                                            <div className="col-lg-12 auth-links text-center mb-2">
                                                <div className="mb-2"> 
                                                   <Link to={PATHS.FORGET_PASSWORD}>Forgot your password?</Link>
                                                </div>
                                                Already have an account? <Link to={PATHS.LOGIN}>Sign In</Link> 
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>        
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.service.isLoading,
  response: state.service ? state.service.registerResponse : "",
});

export default connect(mapStateToProps, {
  setLoader,
  userRegistration
})(reduxForm({ form: "registerForm" })(Register));