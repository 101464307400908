import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import loginBanner from "../../images/big/login-banner.svg"
import  webLogo  from "../../images/web-logo.svg"
import "../../App.css";
import { LoaderScreen } from "../includes";
import {  setLoader, verifyEmail, verifyEmailSend } from "../../actions";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { Link } from "react-router-dom";
import { PATHS } from "../../constants";
import { required, email } from "../../constants/validation";
import { renderEmailWithIcon } from "../../constants/redux-form";


/*
 TYPES
*/

type Props = {
  history: Object,
  handleSubmit: Function,
  setLoader: Function
};




class Verify extends Component<Props> {

  constructor(props) {
    super(props);
    this.state = {
       alertMessage: "",
       alertClass: "",
    };
  }

  componentDidMount() {

    AOS.init();
    if (localStorage.getItem("access_token")) {
      this.props.history.push(PATHS.DASHBOARD)
    } else {

      this.props.setLoader(true);
    
     let successMessage = "";
     let errorMessage = "";

      let formValue = {
        token: this.props.match.params.id
      }

      setTimeout(() => {
        
        this.props.verifyEmail(formValue).then(result => {

          if (this.props.response.success) {

            successMessage = this.props.response.message;
           // this.setState({ alertMessage: successMessage, alertClass: "alert alert-success" });
           this.props.history.push({
              pathname: "/login",
              state: { alertMessage: successMessage, alertClass: "alert alert-success" },
            });
         
          } else {
            errorMessage = this.props.response.message;
            this.setState({ alertMessage: errorMessage, alertClass: "alert alert-danger" });
          }

          setTimeout(() => {
            this.setState({ alertMessage: "", alertClass: "" })
          }, 3000);

        });
      }, 1000);
    }

  }

  onSubmit = formValues => {

   this.props.setLoader(true);
    
     let successMessage = "";
     let errorMessage = "";


      let formValue = {
         email: formValues.email,
      }
      setTimeout(() => {
        
        this.props.verifyEmailSend(formValue).then(result => {

          if (this.props.verificationResponse.success) {

            successMessage = this.props.verificationResponse.message;
            this.setState({ alertMessage: successMessage, alertClass: "alert alert-success" });
            this.props.reset('verfiEmailForm');

          } else {
            this.props.reset('verfiEmailForm');
            errorMessage = this.props.verificationResponse.message;
            this.setState({ alertMessage: errorMessage, alertClass: "alert alert-danger" });
          }

          setTimeout(() => {
            this.setState({ alertMessage: "", alertClass: "" })
          }, 3000);

        });
      }, 1000);
    
  };

  render() {
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    return (
      <div className="main-wrapper">
        <div className="auth-wrapper d-flex no-block justify-content-center align-items-center position-relative auth-bg">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-md-11">
                        <div className="auth-box" data-aos="fade-up" data-aos-delay="400">
                            <div className="auth-left modal-bg-img">
                                <img src={ loginBanner } className="img-fluid" alt=""/>
                            </div>
                            <div className="auth-right bg-white">
                                <div className="p-4 p-md-5 auth-sec">
                                    <div className="text-center auth-logo mb-5 mb-md-4 mb-lg-5">
                                        <Link to={PATHS.HOME}>
                                            <img src={webLogo} alt="wrapkit" />
                                        </Link>
                                    </div>
                                    <h2 className="mt-3 text-center">Verify Email</h2>
                                    <p className="text-center">Please verfiy your email.</p>
                                     <form
                                        name="verfiEmailForm"
                                        onSubmit={this.props.handleSubmit(this.onSubmit)}
                                        className="mt-4"
                                      >
                                      {this.state.alertMessage ? (
                                        <div className={this.state.alertClass} role="alert">
                                          {this.state.alertMessage}
                                        </div>
                                      ) : null}
                                        <div className="row">
                                           
                                            <div className="col-lg-12">
                                               <Field className="form-control input_user" icon="fas fa-envelope" name="email" type="email" id="email" validate={[required, email]} placeholder={`Email`} component={renderEmailWithIcon} />
                                            </div>

                                            <div className="col-lg-12 text-center mb-4">
                                                <button type="submit" className="btn btn-block btn-purple">Verify Email</button>
                                            </div>
                                            <div className="col-lg-12 auth-links text-center mb-2">
                                                <div className="mb-2"> 
                                                   <Link to={PATHS.LOGIN}>Sing In?</Link>
                                                </div>
                                                Don't have an account? <Link to={PATHS.REGISTER}>Sign Up</Link>
                                                
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>        
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  }
}



const mapStateToProps = state => {
    console.log(state.service.response);
    return {
        response: state.service.response ? state.service.response : '',
        verificationResponse: state.service.verificationResponse ? state.service.verificationResponse : '',
        isLoading: state.service.isLoading
    };
};

export default connect(mapStateToProps, {
  setLoader,
  verifyEmail,
  verifyEmailSend
})(reduxForm({ form: "verfiEmailForm" })(Verify));