import React, { useState, useEffect} from "react";
import "./Pagination.css";



const Pagination = ({ data, RenderComponent, imagePath, title, pageLimit, dataLimit, history }) => {
    const [pages, setPages] = useState(Math.round(data.length / dataLimit) + 1);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
      window.scrollTo({ behavior: 'smooth', top: '0px' });
    }, [currentPage]);

    useEffect(() => {
      console.log('data.length', data.length, Math.ceil(data.length / dataLimit))
      setPages(Math.ceil(data.length / dataLimit))
      setCurrentPage(1)
    }, [data]);

    function goToNextPage() {
       setCurrentPage((page) => page + 1);
    }

    function goToPreviousPage() {
       setCurrentPage((page) => page - 1);
    }

    function changePage(event) {
       const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
    }

    const getPaginatedData = () => {
      const startIndex = currentPage * dataLimit - dataLimit;
      const endIndex = startIndex + dataLimit;
      return data.slice(startIndex, endIndex);
    };

    const getPaginationGroup = () => {
       let start = Math.floor((currentPage - 1) / pages) * pages;
        return new Array(pages).fill().map((_, idx) => start + idx + 1);
    };

    return (
         <>
          
          <div className="row">
            {getPaginatedData().map((d, idx) => (
              <RenderComponent key={idx} data={d} history={history}/>
            ))}
          </div>

        {getPaginationGroup().length > 1 && 
          <div class="row">
            <div className="pagination">
              <button
                onClick={goToPreviousPage}
                className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
              >
                prev
              </button>

              {getPaginationGroup().map((item, index) => (
                <button
                  key={index}
                  onClick={changePage}
                  className={`paginationItem ${currentPage === item ? 'active' : null}`}
                >
                  <span>{item}</span>
                </button>
              ))}

              <button
                onClick={goToNextPage}
                className={`next ${currentPage === pages ? 'disabled' : ''}`}
              >
                next
              </button>
            </div>
          </div>
        }
         
        </>
    );
  }



  export default Pagination;
