
import React, {  } from "react";
import { Link, NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import { PATHS } from "../../constants";
import { useSelector, } from "react-redux";
//import { fetchCategory } from "../../actions";




const FooterOnIndex = (props) => { 


    const style = {
      color: 'red'
    };

    const notStyle = {

    }


  const activeRoute = (routeName) => {
     // return props.location.pathname.indexOf(routeName) > -1 ? "selected" : "";
       let selectedDiv = "";
      for (const route of routeName) {
           if(props.location.pathname.indexOf(route) > -1){
                selectedDiv = style;
           } else {
            selectedDiv = notStyle
           }
        }

        
        return selectedDiv;
    }

    // const dispatch = useDispatch();
    //   useEffect(() => {
    //     dispatch(fetchCategory());
    //   }, [dispatch]);

    const handleView = (e, param) =>  {

        e.preventDefault();
      //  dispatch(fetchCategory());
        props.history.push('/library/' + param);
    }    
    

   const category = useSelector(state => state.service.categoryResponse.data);


  return (<>
     <div className="f-top-bg">
            <img src="/assets/images/footer-top-bg.svg" alt="FooterTop" />
        </div>
        <div className="footer-btm dark-blue-bg pb-4">
            <div className="container">
                <div className="row">
                    <div className="col-3 d-none d-lg-block col-sm-4 mb-3">
                       <h4 className="f-heading mb-3">
                           Contact us
                       </h4>
                       <div className="f-address">
                           <div className="ads-bar">
                               <i className="fas fa-map-marker-alt"></i>
                               <span>Sr.No. 54(1+2/1) Nere Dattawadi, Near Hinjawadi IT Park, Pune</span>
                           </div>
                           <div className="ads-bar">
                               <i className="fas fa-envelope"></i>
                               <span><a href="mailto:info@myedumaster.com">info@myedumaster.com</a></span>
                           </div>
                           <div className="ads-bar">
                               <i className="fas fa-phone-alt"></i>
                               <span><a href="tel:+020-66547500">020-66547500</a></span>
                           </div>
                       </div>
                       <div className="f-social">
                           <a href="/#">
                               <i className="fab fa-facebook-square"></i>
                           </a>
                           <a href="/#">
                               <i className="fab fa-twitter-square"></i>
                           </a>
                           <a href="/#">
                               <i className="fab fa-linkedin"></i>
                           </a>
                       </div>
                    </div>

                    {/*<div className="col mb-3">
                        <h4 className="f-heading mb-3">
                           Learning
                       </h4>
                       <ul>                           
                            <li><a href="/#">Free courses</a></li>
                            <li><a href="/#">Membership</a></li>
                            <li><a href="/#">Online tutoring</a></li>
                            <li><a href="/#">Get the app</a></li>
                       </ul>
                    </div>*/}

                    <div className="col mb-3 d-none d-lg-block">
                        <h4 className="f-heading mb-3">
                           Our Courses
                       </h4>
                       <ul>    
                          {/*<li><Link to={PATHS.GENERAL_ENGLISH_TOPIC}>General English</Link></li>
                          <li><Link to={PATHS.ACADEMIC_TOPIC}>Academic co-curricular Activities</Link></li>
                          <li><Link to={PATHS.COMPETITIVE_TOPIC}>Competitive Exams</Link></li>*/}
                       {category && category.map((cat, i)=>(
                          <li key={i}>
                              
                              <NavLink to={'/library/' + cat.slug} onClick={(e)=>handleView(e, cat.slug)}>
                                   {cat.name}
                                </NavLink>
                          </li>
                         ))} 
                       </ul>
                    </div>

                    <div className="col mb-3">
                        <h4 className="f-heading mb-3">
                           Quick Links
                       </h4>

                       <ul>          
                            <li><Link to={PATHS.ABOUT} style={activeRoute(['about-us'])}>About us </Link></li>
                            <li><Link to={PATHS.CONTACT_US} style={activeRoute(['contact-us'])}>Contact us</Link></li>
                            <li><Link to={PATHS.FAQ} style={activeRoute(['faq'])}>FAQ</Link></li>
                            <li><Link to={PATHS.PRIVACY} style={activeRoute(['privacy-policy'])}>Privacy Policy</Link></li>
                            <li><Link to={PATHS.TERMS } style={activeRoute(['terms-and-conditions'])}>Terms & Condition</Link></li>
                       </ul>
                    </div>

                    <div className="col mb-3">
                        <h4 className="f-heading mb-3">
                           Download
                       </h4>
                       <div className="app-links">
                           <a href="/#"><img src="/assets/images/app-store-btn.png" alt="App Store" className="img-fluid" /></a>
                          <a href="https://play.google.com/store/apps/details?id=com.myedumaster.myedumaster"><img src="/assets/images/google-play-btn.png" alt="Google Play" className="img-fluid" /></a>
                       </div>
                    </div>

                </div>
            </div>
        </div>
        <div className="copyright black-bg py-3">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p className="mb-0">
                            © Copyright {new Date().getFullYear()} MyEduMaster. All Rights Reserved
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </>); 
}


export default withRouter((FooterOnIndex));