import React, { Component } from "react";
import { connect } from "react-redux";

import {
  setLoader,
  fetchQuizInfo,
  getInitialTestData,
  updateTestAttempts
} from "../../actions";

//import { PATHS } from "../../constants";
import {
  LoaderScreen,
  NavOnDashboard,
  FooterOnDashboard,
  Sidebar
} from "../includes";
import { Field, reduxForm } from "redux-form";
import ReactHtmlParser from "react-html-parser";

import {
  renderRadioButton2,
  RenderCheckbox,
  renderTextArea,
  renderVideoFile
} from "../../constants/redux-form";
import ReactPlayer from "react-player";

import { required, validVideoFile } from "../../constants/validation";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";


/*
 TYPES
*/

type Props = {
  history: Object
};

class Quiz extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      alertMessage: "",
      alertClass: "",
      active: false,
      questionIndex: 0,
      nextVisible: false,
      checkedItems: new Map(),
      checkedValue: []
    };
  }

  componentDidMount() {
    this.props.setLoader(true);

    var formValue = {
      quiz_id: this.props.match.params.id
    };

    this.props.reset("submitQuizForm" + this.state.questionIndex);
    this.props.fetchQuizInfo(formValue);

    const user = localStorage.getItem("isAuth") ? true : false;

    this.props.getInitialTestData({}, "");
    this.setState({ isAuth: user });
  }

  viewResult = () => {
    if (
      this.state.questionName === undefined ||
      this.state.questionName === ""
    ) {
      if (
        this.props.quizTempData.quiz_questions[this.state.questionIndex]
          .attemptStatus === undefined ||
        this.props.quizTempData.quiz_questions[this.state.questionIndex]
          .attemptStatus === "" ||
        this.props.quizTempData.quiz_questions[this.state.questionIndex]
          .attemptStatus === "FORREVIEW"
      ) {
        this.props.quizTempData.quiz_questions[
          this.state.questionIndex
        ].attemptStatus = "NOTATTEMPT";
        delete this.props.quizTempData.quiz_questions[this.state.questionIndex]
          .attemptAnswer;
      }
    } else {
      this.props.quizTempData.quiz_questions[
        this.state.questionIndex
      ].attemptStatus = "ATTEMPT";
      this.props.quizTempData.quiz_questions[
        this.state.questionIndex
      ].attemptAnswer = this.state.questionValue;
    }

    let attemptData = [];
    let notAttempt = "";
    let test_type = this.props.quizTempData
      ? this.props.quizTempData.quiz_type
      : 1;

    this.props.quizTempData.quiz_questions.map((p, i) => {
      // let attemptWeight = p.options.filter(name => name.optionValue == p.attemptAnswer).map(optionObject => optionObject.weightMarks);
      if (p.attemptAnswer === undefined || p.attemptAnswer === "") {
        notAttempt = "1";
      }

      attemptData.push({
        questionId: p.id,
        //'rightAnswer': p.rightAnswer,
        question_type: p.question_type,
        attemptAnswer: p.attemptAnswer !== undefined ? p.attemptAnswer : "",
        attemptAnswerVideo:
          p.attemptAnswerVideo !== undefined ? p.attemptAnswer : "",
        marks: p.marks,
        attemptStatus:
          p.attemptStatus !== undefined && p.attemptStatus === "ATTEMPT"
            ? p.attemptStatus
            : "NOTATTEMPT"
      });
    });

    // let formValuesData = {
    //   quiz_id: this.props.match.params.id,
    //   attemptData
    // }

    let input = new FormData();
    input.append("quiz_id", this.props.match.params.id);

    attemptData.map((p, index) => {
      input.append("attemptData[" + index + "][questionId]", p.questionId);
      input.append(
        "attemptData[" + index + "][question_type]",
        p.question_type
      );
      input.append(
        "attemptData[" + index + "][attemptAnswer]",
        p.attemptAnswer
      );
      input.append("attemptData[" + index + "][marks]", p.marks);
      input.append(
        "attemptData[" + index + "][attemptStatus]",
        p.attemptStatus
      );
      input.append(
        "attemptData[" + index + "][attemptAnswerVideo]",
        p.attemptAnswerVideo
      );
    });

    if (notAttempt === "1") {
      Store.addNotification({
        title: "Mandatory!",
        message: "Please attempt all question",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1000,
          onScreen: true
        }
      });

      return false;
    }

    confirmAlert({
      title: "Exam Submit",
      message: "Do you want to submit exam ?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            this.props.updateTestAttempts(input).then(result => {
              this.props.setLoader(false);
              if (this.props.testAttemptResponse.success) {
                this.props.setLoader(false);
                // let formValues = {
                //   testId: this.props.userTestResponse.data.testList[0].test._id ? this.props.userTestResponse.data.testList[0].test._id : 0
                // };

                // let testId = this.props.userTestResponse.data.testList[0].test._id ? this.props.userTestResponse.data.testList[0].test._id : 0
                // this.props.getUserTestInfo(formValues).then(result => {
                //   if (this.props.userTestInfo.success) {
                //     this.props.history.push('/quiz-one-result' + '/' + testId)
                //   }
                // })
                if (test_type === 1) {
                  this.props.history.push(
                    "/quiz-result/" + this.props.match.params.id
                  );
                } else {
                  this.props.history.push("/practice-test");
                }
              } else {
                this.props.setLoader(false);
              }
            })
        },
        {
          label: "No",
          onClick: () => ""
        }
      ]
    });
  };

  next = e => {
    e.preventDefault();

    if (
      this.state.questionName === undefined ||
      this.state.questionName === ""
    ) {
      if (
        this.props.quizTempData.quiz_questions[this.state.questionIndex] ===
          undefined ||
        this.props.quizTempData.quiz_questions[this.state.questionIndex]
          .attemptAnswer === undefined ||
        this.props.quizTempData.quiz_questions[this.state.questionIndex]
          .attemptAnswer === ""
      ) {
        this.props.quizTempData.quiz_questions[
          this.state.questionIndex
        ].attemptStatus = "NOTATTEMPT";
        delete this.props.quizTempData.quiz_questions[this.state.questionIndex]
          .attemptAnswer;
      } else {
        this.props.quizTempData.quiz_questions[
          this.state.questionIndex
        ].attemptStatus = "ATTEMPT";
      }
    } else {
      this.props.quizTempData.quiz_questions[
        this.state.questionIndex
      ].attemptStatus = "ATTEMPT";

      this.props.quizTempData.quiz_questions[
        this.state.questionIndex
      ].attemptAnswer = this.state.questionValue;
    }

    if (
      this.props.quizTempData.quiz_questions.length >
      parseInt(this.state.questionIndex) + 1
    ) {
      this.setState({ questionIndex: parseInt(this.state.questionIndex) + 1 });
    } else {
      this.setState({ nextInvisible: true });
      this.viewResult();
      return false;
    }

    this.setState({
      questionName: "",
      questionValue: "",
      checkedValue: []
    });

    // this.setState({ questionIndex: parseInt(this.state.questionIndex) + 1 })
    // return false;
  };

  prev = e => {
    e.preventDefault();

    if (
      this.props.quizTempData.quiz_questions.length >
      parseInt(this.state.questionIndex) + 1
    ) {
      this.setState({ questionIndex: parseInt(this.state.questionIndex) - 1 });
    } else {
      this.setState({ nextInvisible: false });

      this.setState({ questionIndex: parseInt(this.state.questionIndex) - 1 });

      return false;
    }
  };

  questionAttempt = e => {
    if (e.target.type === "file") {
      let reader = new FileReader();
      let file = e.target.files[0];

      if (file && !file.name.match(/.(mp4|mov|avi|wmv)$/i)) {
        return false;
      }

      if (file) {
        reader.onloadend = () => {
          this.props.quizTempData.quiz_questions[
            this.state.questionIndex
          ].attemptStatus = "ATTEMPT";
          this.props.quizTempData.quiz_questions[
            this.state.questionIndex
          ].attemptAnswer = e.target.files[0];
          this.props.quizTempData.quiz_questions[
            this.state.questionIndex
          ].attemptAnswerVideo = reader.result;
          this.setState({ questionName: e.target.name, questionValue: file });
        };
        reader.readAsDataURL(file);
      }

      // event.target.files[0]
    } else {
      console.log(e.target.name);

      this.setState({
        questionName: e.target.name,
        questionValue: e.target.value
      });
      this.props.quizTempData.quiz_questions[
        this.state.questionIndex
      ].attemptStatus = "ATTEMPT";
      this.props.quizTempData.quiz_questions[
        this.state.questionIndex
      ].attemptAnswer = e.target.value;
    }
  };

  questionClick = e => {
    e.preventDefault();
    if (e.target.getAttribute("data-value") !== null) {
      this.setState({
        questionIndex: e.target.getAttribute("data-value")
      });
    }
  };

  review = e => {
    e.preventDefault();

    if (
      this.props.quizTempData.quiz_questions !== undefined &&
      this.props.quizTempData.quiz_questions.length >
        parseInt(this.state.questionIndex) + 1
    ) {
      this.props.quizTempData.quiz_questions[
        this.state.questionIndex
      ].attemptStatus = "FORREVIEW";
      this.props.quizTempData.quiz_questions[
        this.state.questionIndex
      ].attemptAnswer = this.state.questionValue;
      this.setState({ questionIndex: parseInt(this.state.questionIndex) + 1 });
    } else {
      this.props.quizTempData.quiz_questions[
        this.state.questionIndex
      ].attemptStatus = "FORREVIEW";
      this.props.quizTempData.quiz_questions[
        this.state.questionIndex
      ].attemptAnswer = this.state.questionValue;
    }

    this.setState({
      questionName: "",
      questionValue: ""
    });
  };

  handleChange = e => {
    const item = e.target.value;
    const isChecked = e.target.checked;
    let values = this.state.checkedValue;

    if (!isChecked) {
      values.filter(person => person !== item);
    } else {
      values.push(item);
    }

    this.setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
      checkedValue: values
    }));

    this.props.quizTempData.quiz_questions[
      this.state.questionIndex
    ].attemptStatus = "ATTEMPT";
    this.props.quizTempData.quiz_questions[
      this.state.questionIndex
    ].attemptAnswer = values;

    console.log(this.props.quizTempData);
  };

  videoPathFunction = site => {
    let basePath = this.props.path.base_path;
    let path = basePath.replace(/\/$/, "") + site;

    return path;
  };

  render() {
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    let forreview = 0;
    let attempt = 0;
    let notanswered = 0;
    if (this.props.quizTempData.quiz_questions) {
      this.props.quizTempData.quiz_questions.map((e, index) => {
        if (e.attemptStatus === "FORREVIEW") {
          forreview = forreview + 1;
        }
        if (e.attemptStatus === "ATTEMPT") {
          attempt = attempt + 1;
        }
        if (e.attemptStatus === "NOTATTEMPT") {
          notanswered = notanswered + 1;
        }
      });
    }

    const questionList = () => {
      var question = [];
      if (
        this.props.quizInfoResponse &&
        this.props.quizInfoResponse.quiz_questions
      ) {
        console.log("this.props.quizInfoResponse", this.props.quizInfoResponse);

        this.props.getInitialTestData(this.props.quizInfoResponse, "");

        return (
          (question = this.props.quizInfoResponse.quiz_questions
            ? this.props.quizInfoResponse.quiz_questions[
                this.state.questionIndex
              ]
            : 0),
          (
            <div className="ques-h mb-4" key={question ? question.id : ""}>
              <div className="ques-head mb-3">
                <h4 className="mb-3 mb-md-0 ques-heading">
                  Q{parseInt(this.state.questionIndex) + 1 + ". "}
                  {question ? ReactHtmlParser(question.question_title) : ""}
                </h4>
                <div className="ml-md-auto">
                  <span>Question Type :</span>{" "}
                  <strong>
                    {" "}
                    {question && question.question_type === 1
                      ? "SCQ"
                      : question && question.question_type === 2
                      ? "MCQ"
                      : question && question.question_type === 3
                      ? "Essay"
                      : "Video"}
                  </strong>
                </div>
              </div>
              <strong className="ques-marks ml-3 mb-2 d-block">
                {question && question.marks == 1 ? 'Question Mark : ' : "Question Marks : "}
                {question && question.marks ? question.marks : ""}
              </strong>
              <div className="option-box">
                {question &&
                (question.question_type === 3 ||
                  question.question_type === 4) ? (
                  question.question_type === 3 ? (
                    <div className="col-md-12 mb-3">
                      <Field
                        name={`field-${question.id}`}
                        placeholder={`Please write here`}
                        component={renderTextArea}
                        onChange={this.questionAttempt}
                      />
                    </div>
                  ) : (
                    <div>
                      {question && question.video_path ? (
                        <div className="col-md-12 mb-3">
                          <ReactPlayer
                            url={this.videoPathFunction(question.video_path)}
                            controls
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="col-md-12 mb-3">
                        <Field
                          type="file"
                          name={`field-${question.id}`}
                          component={renderVideoFile}
                          onChange={this.questionAttempt}
                          validate={[validVideoFile]}
                        />
                      </div>
                      {question && question.attemptAnswerVideo ? (
                        <div className="col-md-12 mb-3">
                          <ReactPlayer
                            url={question.attemptAnswerVideo}
                            controls
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )
                ) : question && question.question_options ? (
                  question.question_options.map((result, index) => {
                    if (question.question_type === 1) {
                      return (
                        <div key={index} className="test-options">
                          <Field
                            component={renderRadioButton2}
                            name={`field-${result.question_id}`}
                            label={result.option_value}
                            options={result.option_value}
                            onChange={this.questionAttempt}
                            label1={result.id.toString()}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div key={index} className="test-options">
                          <label key={result.key}>
                            <RenderCheckbox
                              name={result.question_id}
                              checked={this.state.checkedItems.get(
                                String(result.id)
                              )}
                              onChange={this.handleChange}
                              value={result.id}
                            />{" "}
                            {result.option_value.toString()}
                          </label>
                        </div>
                      );
                    }
                  })
                ) : (
                  ""
                )}
              </div>
            </div>
          )
        );
      }
    };

    return (
      <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <ReactNotifications />
        <NavOnDashboard user={this.props.userDetail} />
        <Sidebar />


        <div className="page-wrapper" id="check">
          <div className="page-m-height">
            <div className="page-breadcrumb">
              <div className="row">
                <div className="col-7 align-self-center">
                  <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                    Questionnaire{" "}
                  </h3>
                  <div className="d-flex align-items-center">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb m-0 p-0">
                      
                        <li className="breadcrumb-item">
                          <a href="#">
                            {this.props.quizInfoResponse &&
                            this.props.quizInfoResponse.quiz_title
                              ? this.props.quizInfoResponse.quiz_title
                              : ""}
                          </a>
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-9 col-lg-8">
                  <div className="card">
                    <div className="card-body">
                      {this.props.quizInfoResponse &&
                      Object.keys(this.props.quizInfoResponse).length > 0 ? (
                        <form
                          name={"submitQuizForm" + this.state.questionIndex}
                        >
                          <div>
                            {questionList()}

                            <div className="ques-btns">
                              {parseInt(this.state.questionIndex) !== 0 ? (
                                <a
                                  href={" "}
                                  className="btn btn-dark btn-rounded pl-3 pr-3"
                                  onClick={this.prev}
                                >
                                  <i className="fas fa-chevron-left"></i> Prev
                                </a>
                              ) : (
                                ""
                              )}

                              <a
                                href={" "}
                                className="btn btn-cyan btn-rounded pl-3 pr-3"
                                onClick={this.review}
                              >
                                <i className="fas fa-check"></i> Mark for review
                              </a>
                              {this.props.quizInfoResponse.quiz_questions &&
                              this.props.quizInfoResponse.quiz_questions
                                .length >
                                parseInt(this.state.questionIndex) + 1 ? (
                                <a
                                  href={" "}
                                  className="btn btn-success btn-rounded pl-3 pr-3"
                                  onClick={this.next}
                                >
                                  Next <i className="fas fa-chevron-right"></i>
                                </a>
                              ) : (
                                <button
                                  onClick={this.next}
                                  className="btn btn-success btn-rounded pl-3 pr-3"
                                >
                                  Finish{" "}
                                </button>
                              )}
                            </div>
                          </div>
                        </form>
                      ) : (
                        <div> No Quiz found </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="p-2 bg-primary text-center mb-3">
                        <h1 className="font-light text-white">
                          {this.props.quizInfoResponse
                            ? this.props.quizInfoResponse.quiz_marks
                            : 0}
                        </h1>
                        <h6 className="text-white">Total Marks</h6>
                      </div>
                      <div className="p-2 bg-success text-center mb-3">
                        <h1 className="font-light text-white">{attempt}</h1>
                        <h6 className="text-white">Answered</h6>
                      </div>
                      <div className="p-2 bg-danger text-center mb-3">
                        <h1 className="font-light text-white">{notanswered}</h1>
                        <h6 className="text-white">Not Answered</h6>
                      </div>
                      <div className="p-2 bg-cyan text-center">
                        <h1 className="font-light text-white">{forreview}</h1>
                        <h6 className="text-white">Marked for Review</h6>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-4">
                    <div className="card-body">
                      <h4 className="card-title brdrbtm-lblue pb-3 mb-3">
                        Choose a question
                      </h4>
                      <div className="ques-number">
                        {this.props.quizTempData.quiz_questions
                          ? this.props.quizTempData.quiz_questions.map(
                              (e, i) => {
                                return (
                                  <div key={i} onClick={this.questionClick}>
                                    <a
                                      data-value={i}
                                      href={" "}
                                      className={
                                        e.attemptStatus === "ATTEMPT"
                                          ? "btn btn-success btn-circle"
                                          : e.attemptStatus === "NOTATTEMPT"
                                          ? "btn btn-danger btn-circle"
                                          : e.attemptStatus === "FORREVIEW"
                                          ? "btn btn-cyan btn-circle"
                                          : "btn btn-danger btn-circle"
                                      }
                                    >
                                      {i + 1}
                                    </a>
                                  </div>
                                );
                              }
                            )
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterOnDashboard />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.service.isLoading,
    userDetail: state.service.userDetailResponse
      ? state.service.userDetailResponse.data
      : "",
    quizInfoResponse: state.service.quizInfoResponse
      ? state.service.quizInfoResponse.data
      : "",
    quizTempData: state.service.initialTestData
      ? state.service.initialTestData
      : "",
    testAttemptResponse: state.service ? state.service.testAttemptResponse : "",
    path: state.service.userDetailResponse
      ? state.service.userDetailResponse.path
      : ""
  };
};

export default connect(mapStateToProps, {
  setLoader,
  fetchQuizInfo,
  getInitialTestData,
  updateTestAttempts
})(reduxForm({ form: "submitQuizForm" })(Quiz));
