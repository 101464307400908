import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../../App.css";
import {  setLoader, userInfo, fetchDashboardData, liveSessionEvent, fetchCategory } from "../../actions";

import { PATHS } from "../../constants";
import { LoaderScreen, NavOnDashboard, FooterOnDashboard, Sidebar, LandingScreen } from "../includes";

import  { greetings, randomButtonClass }  from "../../utils/general";
import MyCalendar from "../calendar-element/CalendarElement";
import {RunningCourse} from "../myTopic";
import ReactTooltip from 'react-tooltip';

/*
 TYPES
*/

type Props = {
  history: Object,
};




class Dashboard extends Component<Props> {

  constructor(props) {
    super(props);
    this.state = {
       alertMessage: "",
       alertClass: "",
       mount:false,
       data:[]
    };
  }

  componentDidMount() {
    
    if (!localStorage.getItem("access_token")) {
      this.props.history.push(PATHS.LOGIN)
      localStorage.clear();
    }
    this.props.liveSessionEvent();
    this.props.fetchCategory()
    this.props.setLoader(true);
    this.props.fetchDashboardData().then(result => {
        this.setState({data : this.props.dashboardData})
    });
    this.props.userInfo().then(result => {
        if (this.props.userDetail) {
            this.props.setLoader(false);
            this.setState({mount:true})
        }
    })

  }

  subjectFormat = (sub) => {
    let val = null;
    if(sub.indexOf(' ') >= 0){
       let s = sub.indexOf(' ') >= 0 ? sub.split(' '):sub;
       let a = s[0].split('');
       let b = s[1].split('');

       let twoWord = (a[0]+(b[0] ? b[0] : ''));
      twoWord =  twoWord.length < 2 ? (a[0]+a[1]+(b[0] ? b[0] : '')) : twoWord

       return twoWord.toUpperCase();
    }else{
       val = sub.substring(0, 2).toUpperCase();
    } 
    return val;

  }

  renderSubExpert = (subject) => {
        let data = subject ? subject.split(',') : null;
       if(!data){
         return '';
       }else{
         return data.map((sub, i) => (
                
                <a key={i} className={`btn ${randomButtonClass()} rounded-circle btn-circle font-12 popover-item `}
                    href="/#" >{this.subjectFormat(sub)}</a>
            ));

       }

    
  }

   handleView = (event) => {
         
        this.props.history.push('session-info/'+event.id)
    }

    

  render() {
   

   const {completed_courses, running_courses, attempted_test, upcoming_session, top_faculty } = this.state.data;
   
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    return (
     <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
        data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <NavOnDashboard user={this.props.userDetail} />
        <Sidebar />
        <div className="page-wrapper">
            <div className="page-breadcrumb">
                <div className="row mt-3">
                    <div className="col-10 align-self-center">
                        <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">{greetings()} {this.props.userDetail ? this.props.userDetail.name : ''}!</h3>
                        <div className="d-flex align-items-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb m-0 p-0">
                                    <li className="breadcrumb-item"><Link to={PATHS.DASHBOARD}>Dashboard</Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {(!localStorage.getItem("userMediumId") || localStorage.getItem("userMediumId") === null || localStorage.getItem("userMediumId")  === "null") ?
               <LandingScreen />
               :   
            <div className="container-fluid">
                <div className="row">
                  {/*  <div className="col-md-6 col-xl-3 stretch-card">
                        <div className="card widget-card-1">
                            <div className="card-block-small">
                                <div className="bg-c-blue card1-icon">
                                    <i className="fas fa-book"></i>    
                                </div>                                                                  
                                <span className="text-c-blue f-w-600 topbox-head">Completed Courses</span>
                                <h4>{completed_courses}</h4>
                                <div>
                                    <span className="f-left m-t-10 text-dark">
                                        <span className="text-c-blue f-16 icofont icofont-refresh m-r-10"><i className="fas fa-layer-group"></i></span>
                                        Lorem ipsum dolor
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-md-6 col-xl-4 stretch-card">
                        <div className="card widget-card-1">
                            <div className="card-block-small">                                
                                <div className="bg-c-purple card1-icon">
                                    <i className="fas fa-book-reader"></i>
                                </div>                                  
                                <span className="text-c-purple f-w-600 topbox-head">Running Courses</span>
                                <h4>{running_courses}</h4>
                                {/* <div>
                                    <span className="f-left m-t-10 text-dark">
                                        <span className="text-c-purple f-16 icofont icofont-refresh m-r-10"><i className="fas fa-layer-group"></i></span>
                                        Lorem ipsum dolor
                                    </span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-4 stretch-card">
                        <div className="card widget-card-1">
                            <div className="card-block-small">
                                <div className="bg-c-green card1-icon">
                                    <i className="fas fa-brain"></i>
                                </div>                                  
                                <span className="text-c-green f-w-600 topbox-head">Attempted Test</span>
                                <h4>{attempted_test}</h4>
                                <div>
                                 {/*  <span className="f-left m-t-10 text-dark">
                                        <span className="text-c-green f-16 icofont icofont-refresh m-r-10"><i className="fas fa-layer-group"></i></span>
                                        Lorem ipsum dolor
                                    </span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-4 stretch-card">
                        <div className="card widget-card-1">
                            <div className="card-block-small">                                
                                <div className="bg-c-yellow card1-icon">
                                    <i className="fas fa-chalkboard-teacher"></i>
                                </div>                                
                                <span className="text-c-yellow f-w-600 topbox-head">Upcoming Sessions </span>
                                <h4>{upcoming_session}</h4>
                                <div>
                                  {/*  <span className="f-left m-t-10 text-dark">
                                        <span className="text-c-yellow f-16 icofont icofont-refresh m-r-10"><i className="fas fa-layer-group"></i></span> 
                                        Lorem ipsum dolor
                                    </span>  */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-12 stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title brdrbtm-lblue pb-3 mb-3">Sessions</h4>
                                <div className="b-l calender-sidebar">
                                    <MyCalendar mount={this.state.mount}  eventDetail={this.props.eventDetail} handleView={this.handleView}/> 
                                </div>
                                    
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 stretch-card">
                        <RunningCourse mount={this.state.mount} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title brdrbtm-lblue pb-3 mb-3">Top Faculty</h4>                                
                             
                                <div className="table-responsive">
                                    <table className="table no-wrap v-middle mb-0">
                                        <thead>
                                            <tr className="border-0">
                                                <th className="border-0 font-14 font-weight-medium text-muted">
                                                    Faculty
                                                </th>
                                                <th className="border-0 font-14 font-weight-medium text-muted px-2">
                                                    Qualification
                                                </th>
                                                <th className="border-0 font-14 font-weight-medium text-muted">
                                                    Subject expertise
                                                </th>                                                
                                                <th className="border-0 font-14 font-weight-medium text-muted text-center">
                                                    Year of experience
                                                </th>  
                                                <th className="border-0 font-14 font-weight-medium text-muted text-center">
                                                    Description
                                                </th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {top_faculty && top_faculty.map((top, i) =>(

                                            <tr key={i}>
                                                <td className="border-top-0 px-2 py-4">
                                                    <div className="d-flex no-block align-items-center">
                                                        <div className="mr-3"><img
                                                                src={top.image}
                                                                alt="user" className="rounded-circle" width="45"
                                                                height="45" /></div>
                                                        <div className="">
                                                            <h5 className="text-dark mb-0 font-16 font-weight-medium">{top.name}</h5>
                                                            
                                                            <span className="text-muted font-14">{top.email}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="border-top-0 text-muted px-2 py-4 font-14 w-50"><div className="quali" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word',width: '450px' }}>{top.qualification}</div></td>
                                                <td className="border-top-0 px-2 py-4">
                                                    <div className="popover-icon">
                                                        {this.renderSubExpert(top.subject_expertise)}
                                                    </div>
                                                </td>
                                                
                                                <td
                                                    className="border-top-0 text-center font-weight-medium text-muted px-2 py-4">
                                                    {top.year_of_experience ? top.year_of_experience+"+" : ''}
                                                </td>

                                                <td
                                                    className="border-top-0 text-center font-weight-medium text-muted px-2 py-4" data-tip={top.description ? top.description : "Description not found" }>
                                                    <p>View</p>
                                                    <ReactTooltip place="top" type="info" multiline={true}/>
                                                </td>
                                                
                                            </tr>
                                        ))} 

                                           
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
            <FooterOnDashboard />
        </div>
    </div>
    );
  }
}

const mapStateToProps = state => ({
  userDetail: state.service.userDetailResponse ? state.service.userDetailResponse.data : '',
  isLoading: state.service.isLoading,
  dashboardData: state.service.dashboardResponse ? state.service.dashboardResponse.data : '',
  eventDetail:state.service.sessionEventResponse ? state.service.sessionEventResponse.data : '',
});

export default connect(mapStateToProps, {  setLoader , userInfo, fetchDashboardData, liveSessionEvent, fetchCategory})(Dashboard);
