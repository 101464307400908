import React, { useEffect} from "react";
import { LoaderScreen, NavOnDashboard, FooterOnDashboard, Sidebar } from "../includes";
import { userInfo, setLoader, fetchMySubscription } from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import  { makeTitle }  from "../../utils/general";


const Membership = (props) => { 

	const dispatch = useDispatch();
   

	useEffect(() => {
	    dispatch(userInfo());
	    dispatch(setLoader(true));
        dispatch(fetchMySubscription());
	 }, [dispatch]);


	const userDetail = useSelector((state) =>
	     state.service.userDetailResponse ? state.service.userDetailResponse.data : '',
	  );

    const subsDetail = useSelector((state) =>
         state.service.mySubsInfo ? state.service.mySubsInfo.data : '',
      );

  
  	const isLoading = useSelector((state) =>
	      state.service.isLoading
	  );

   

  	if (isLoading) {
      return <LoaderScreen />;
    }

 
    return (<>
			<div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
		        data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
		        <NavOnDashboard user={userDetail}/>
		        <Sidebar />
		        <div className="page-wrapper">
		          <div className="page-m-height">
		          <div className="page-breadcrumb">
                        <div className="row">
                            <div className="col-12 col-sm-8 align-self-center mb-3 mb-sm-0">
                                <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">My Membership</h3>
                            </div>
                            
                        </div>
                    </div>
		            <div className="container-fluid">
                        <div className="row">
                        
			                <div className="col-lg-12 col-md-12 stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title brdrbtm-lblue pb-3 mb-3">Plan Status</h4>
                                    <div className="table-responsive">
                                        <table className="table mb-0">
                                            <thead className="bg-info text-white">
                                                <tr>
                                                    <th scope="col">Plan Name</th>
                                                    <th scope="col">Subscription Id</th>
                                                    <th scope="col">Start date</th>
                                                    <th scope="col">End Date</th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {subsDetail && subsDetail.length > 0 ? subsDetail.map((trans, index)=>
                                                <tr key={index}>
                                                    <td>{trans.plan_name ? makeTitle(trans.plan_name) : trans.stripe_plan}</td>
                                                    <td>{trans.stripe_id}</td>
                                                    <td>{moment(trans.created_at).format('MMMM Do YYYY')}</td>
                                                    <td>{moment(trans.ends_at).format('MMMM Do YYYY')}</td>
                                                    <td>{makeTitle(trans.stripe_status)}</td>
                                                </tr>
                                            ) :<tr className="text-center">
                                                    <td colspan="3">Currently there is no plan</td>
                                                </tr>
                                            }    
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
		          </div>
		            <FooterOnDashboard />
		        </div>
		    </div>	
			</>
			);
    


}

export default Membership;