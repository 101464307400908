import React from "react";


const FooterOnDashboard = (props) => { 
	return (<>
				<footer className="footer text-center">
				    © Copyright {new Date().getFullYear()} MyEduMaster. All Rights Reserved                    
				</footer>
			</>
			);
	}	

export default FooterOnDashboard;
