// @flow

export const DEFAULT_LANGUAGE = "en-us";
export const DEFAULT_COUNTRY_CODE = "us";
export const SUBMODULE_NAME = "skeleton";

// export const IMAGE_BASE_PATH = "http://localhost:8000/";
 //const API_BASE_PATH = "http://127.0.0.1:8000/api/";
// export const VIDEO_BASE_PATH = "http://localhost:8000";
// export const WEB_VIDEO_URL = "http://localhost:8000/videochats/join/";

//export const CHAT_BOT_API_URL = "https://chatbotmyedumaster.24livehost.com/";

export const CHAT_BOT_API_URL = "https://chatbot.myedumaster.com/";

//const API_BASE_PATH = "https://myedumaster.24livehost.com/api/";
// export const IMAGE_BASE_PATH = "https://myedumaster.24livehost.com/";
// // export const VIDEO_BASE_PATH = "https://myedumaster.24livehost.com";
//export const WEB_VIDEO_URL = "https://myedumaster.24livehost.com/connect/";

const API_BASE_PATH = "https://admin.myedumaster.com/api/";
export const IMAGE_BASE_PATH = "https://myedumaster.com/";
// export const VIDEO_BASE_PATH = "https://myedumaster.24livehost.com";
export const WEB_VIDEO_URL = "https://admin.myedumaster.com/connect/";

export const VIDEO_BASE_PATH =
  "https://myedumasterdata.s3.ap-south-1.amazonaws.com";

export const WIRIS_PATH =
  "https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image";

export const appConfig = {
  conference: { server: "wss://janus-europe-1.connectycube.com:8989" }
};

export const PATHS = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  FORGET_PASSWORD: "/forget-password",
  RESET_PASSWORD: "/reset-password/:token",
  VERIFY_EMAIL: "/verify/:id",
  TERMS: "/terms-and-conditions",
  ABOUT: "/about-us",
  PRIVACY: "/privacy-policy",
  FAQ: "/faq",
  PRACTICE: "/practice",

  DASHBOARD: "/dashboard",
  UPDATE_PROFILE: "/update-profile",
  CONTACT_US: "/contact-us",
  // GENERAL_ENGLISH_TOPIC: '/general-english',
  // ACADEMIC_TOPIC: '/academic-co-curricular-activities',
  // COMPETITIVE_TOPIC: '/competitive-exams',
  // SPOKEN_ENGLISH: '/spoken-english',
  CATEGORY: "/library/:slug",

  TOPIC_INFO: "/topic/:topic",
  CHANGE_PASSWORD: "/change-password",
  MY_TOPIC: "/my-topics",
  REQUESTED_SESSION: "/requested-session",
  ADD_REQUESTED_SESSION: "/add-session-request",
  LIVE_SESSION: "/live-session",
  START_QUIZ: "/quiz/:id",
  QUIZ_RESULT: "/quiz-result/:id",

  PRACTICE_TEST: "/practice-test",
  UPDATE_MEDIUM: "/update-medium",
  SESSION_DETAIL: "/session-info/:id",

  MY_WALLET: "/my-wallet",
  ADD_MONEY: "/add-money",
  BUY_MEMBERSHIP: "/plans",
  MY_MEMBERSHIP: "/my-membership",
  REQUESTED_SESSION_DETAIL: "/requested-session-detail/:id",
  CHATBOAT: "/chat",
  CHATBOATWEBVIEW: "/chatbot/:user_id",
  LIVE_EVENT: "/events",
  EVENT_DETAIL: "/event-info/:id",
  ADD_DEMO_SESSION: "/add-demo-session",

  // TODO: Should go to Ipifny's 404 page by using command.navigateTo(PAGE_NOT_FOUND)
  PAGE_NOT_FOUND: "/errors/404"
};

export const DEFAULT_CURRENCY_OPTIONS = {
  symbol: "$"
};

// NOTE: Make sure the event keys are exactly the same as they are in the shell:
// https://bitbucket.org/accordogroup/csa.frontend/src/master/src/common/constants.js
export const MF_COMMAND_EVENTS = {
  ERROR_404: "ERROR_404"
};

export const API_PATHS = {
  FETCH_CMS_PAGE: `${API_BASE_PATH}content`,
  FETCH_FAQ: `${API_BASE_PATH}faq`,
  CONTACT_US: `${API_BASE_PATH}contact-us`,
  USER_LOGIN_API: `${API_BASE_PATH}login`,
  REGISTRATION_API: `${API_BASE_PATH}register`,
  FORGOT_PSWD_API: `${API_BASE_PATH}forget-password`,
  USER_LOGOUT_API: `${API_BASE_PATH}logout`,
  RESET_PSWD_API: `${API_BASE_PATH}reset-password`,
  VERIFY_EMAIL_API: `${API_BASE_PATH}verify`,
  VERIFY_EMAIL_SEND_API: `${API_BASE_PATH}verify-email`,
  USER_INFO_API: `${API_BASE_PATH}user-info`,
  UPDATE_INFO_API: `${API_BASE_PATH}update-profile`,
  FETCH_SUB_CATEGORY_API: `${API_BASE_PATH}get-sub-category`,
  FETCH_TOPIC_API: `${API_BASE_PATH}get-all-topics`,
  FETCH_TOPIC_INFO_API: `${API_BASE_PATH}get-topic-info`,
  FETCH_CATEGORY_API: `${API_BASE_PATH}get-category`,
  CHANGE_PASSWORD_API: `${API_BASE_PATH}change-password`,
  FETCH_MY_TOPIC_API: `${API_BASE_PATH}get-my-topic`,
  REMOVE_MY_TOPIC_API: `${API_BASE_PATH}remove-my-topic`,
  ADD_MY_TOPIC_API: `${API_BASE_PATH}add-my-topic`,
  REQUESTED_SESSION_API: `${API_BASE_PATH}session-request`,
  FETCH_ALL_TOPIC_API: `${API_BASE_PATH}get-topic`,
  ADD_SESSION_REQUEST_API: `${API_BASE_PATH}add-session-request`,
  LIVE_SESSION_EVENT_API: `${API_BASE_PATH}live-session-event`,
  FETCH_NOTIFICATION_API: `${API_BASE_PATH}get-notification`,
  MARK_READ_NOTIFICATION_API: `${API_BASE_PATH}mark-as-read`,
  FETCH_DASHBOARD_DATA_API: `${API_BASE_PATH}get-dashboard-data`,
  FETCH_QUIZ_INFO_API: `${API_BASE_PATH}get-quiz-info`,
  UPDATE_TEST_ATTEMPTS_API: `${API_BASE_PATH}quiz-attempt-data`,
  GET_QUIZ_RESULT_API: `${API_BASE_PATH}quiz-result`,
  FETCH_PRACTICE_TEST_API: `${API_BASE_PATH}get-practice-test`,
  FETCH_ALL_MEDIUM_API: `${API_BASE_PATH}get-medium`,
  UPDATE_MEDIUM_API: `${API_BASE_PATH}update-medium`,
  SESSION_DETAIL_API: `${API_BASE_PATH}session-detail`,

  EVENT_API: `${API_BASE_PATH}events`,
  EVENT_DETAIL_API: `${API_BASE_PATH}event-detail`,

  STRIPE_PAY_API: `${API_BASE_PATH}pay`,
  FETCH_WALLET_API: `${API_BASE_PATH}get-transactions`,
  FETCH_PLANS_API: `${API_BASE_PATH}get-plans`,
  MAKE_SUBSCRIPTION_API: `${API_BASE_PATH}subscription`,
  MAKE_PAYMENT_API: `${API_BASE_PATH}pay`,
  MAKE_PAYMENT_CREATE_API: `${API_BASE_PATH}make-pay`,
  FETCH_MY_SUBSCRIPTION_API: `${API_BASE_PATH}my-subscriptions`,
  REQUESTED_SESSION_DETAIL_API: `${API_BASE_PATH}requested-session-detail`,
  PAY_SESSION_API: `${API_BASE_PATH}pay-session`,
  USER_IMAGE_BY_ID_API: `${API_BASE_PATH}get-usr-info`,
  UPDATE_USER_SUB_CAT: `${API_BASE_PATH}update-user-subcat`
};
