import React, { useEffect, useState} from "react";
import { LoaderScreen, NavOnDashboard, FooterOnDashboard, Sidebar } from "../includes";
import { userInfo, setLoader, events } from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import MyCalendar from "../calendar-element/CalendarElement";
import LiveSessionTable from "../calendar-element/TableElement";
 


const Event = (props) => { 

	const dispatch = useDispatch();

	const [calendarView, setCalendarView] = useState(false);

	useEffect(() => {
	    dispatch(userInfo());
	    dispatch(setLoader(true));
	    dispatch(events());
	 }, [dispatch]);

  	const userDetail = useSelector((state) =>
	     state.service.userDetailResponse ? state.service.userDetailResponse.data : '',
	  );

  	const eventDetail = useSelector((state) =>
       state.service.eventData ? state.service.eventData.data : '',
    );

  	const isLoading = useSelector((state) =>
	      state.service.isLoading
	  );

  	const handleViewType = e => {
		  e.preventDefault();
		// setCalendarView(!calendarView);
		return;
	}
	

	const handleView = (event) => {
		// e.preventDefault();
		props.history.push('event-info/'+event.id)
	}

  	
  	if (isLoading) {
      return <LoaderScreen />;
    }

	return (<>
			<div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
		        data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
		        <NavOnDashboard user={userDetail}/>
		        <Sidebar />
		        <div className="page-wrapper">
		          <div className="page-m-height">
		          <div className="page-breadcrumb">
                        <div className="row">
                            <div className="col-12 col-sm-8 align-self-center mb-3 mb-sm-0">
                                <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">Events</h3>
                            </div>
                            {/*<div className="col-12 col-sm-4 align-self-center search-form">
                                <a href="#!" className="btn btn-sm btn-primary float-right" onClick={handleViewType}>{calendarView ? 'Card View' :'Calendar View' }</a>
                            </div>*/}
                        </div>
                    </div>
		            <div className="container-fluid">
                        <div className="row">
                        { calendarView ?
	                       <div className="col-12 stretch-card">
                                <div className="card">
                                    <div className="card-body">
	                         			<MyCalendar eventDetail={eventDetail} height="80vh" handleView={handleView}/> 
	                         		</div>
	                         	</div>	
	                        </div>
			             : 
			             	<LiveSessionTable eventDetail={eventDetail} handleView={handleView}/>
                            
                         }  
                        </div>
                    </div>
		          </div>
		            <FooterOnDashboard />
		        </div>
		    </div>	
			</>
			);
	}

export default Event;

