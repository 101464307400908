import React, {useState} from 'react';
import axios from 'axios';
// MUI Components
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
// stripe
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
// Util imports
import {makeStyles} from '@material-ui/core/styles';
// Custom Components
import CardInput from './CardInput';

import { API_PATHS } from "../../../constants";
//import { makeSubscription } from "../../../actions"
import { useSelector, useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
    margin: '10vh auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
  },
  div: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'space-between',
  },
  button: {
    margin: '2em auto 1em',
  },
});


const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#000"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);





function HomePage(props) {
  const dispatch = useDispatch();

  const classes = useStyles();
  // State
  const [email, setEmail] = useState(props.user.email);
  const [alerts, setAlert] = useState(null);
  const [price, setPrice] = useState(props.price);
  const [topic, setTopic] = useState(props.topicId || '');

  const [session, setSession] = useState(props.sessionId || '');

  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);


  const stripe = useStripe();
  const elements = useElements();
  const [fromTopic, setFromTopic] = useState(props.fromTopic);

  const successAlert = () => {
    setAlert(
              <div>
                <SweetAlert
                    success
                    title="Woo!"
                    confirmBtnText="Ok"
                    onConfirm={() => hideAlert()}
                  >
                    {fromTopic ? 'You have successfully purchased the membership so now you can access the course.':'Payment has been made successful !'}
                  </SweetAlert>
              </div>
          );

  }

  const errorAlert = (props) => {
    setAlert(
              <div>
                 <SweetAlert
                    danger
                    title="Oops!"
                    confirmBtnText="Ok"
                    onConfirm={() => hideAlert()}
                  >
                   { props ? props : `Technical issue, Please try again!` }
                  </SweetAlert>
              </div>
          );
  }

  //console.log(session);

  const handleSubmitPay = async (event) => {
   
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    if(!props.plan){
      if (!price) {
        let er = {
          message : 'Please add credits'
        }
        setError(er);
        return;
      }

      if (price > 1000) {
        let er = {
          message : "Credit between 1 to 1000"
        }
        setError(er);
        return;
      }
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }else{
      setError( {
        message : 'Please fill card details'
      });
       return;
    }


      try {

        const token = await localStorage.getItem("access_token");
        const instance = axios.create();
        instance.defaults.headers.common["Content-Type"] = "application/json";
        instance.defaults.headers.common["Authorization"] = "Bearer " + token;
        //console.log(price);
        let formData = {price:price, topic_id:topic, session_id:session};
        let apiPath = API_PATHS.MAKE_PAYMENT_API;
        if(props.plan){
          formData  =  {plan_id:props.plan};
          apiPath   =  API_PATHS.MAKE_SUBSCRIPTION_API;
        }

        const response = await instance.post(apiPath, formData);

        //console.log('response',response);
    
        const clientSecret = response.data.data['client_secret'];

        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              email: email,
            },
          },
        });

        //console.log("result data ", result);

        //debugger;

        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          setProcessing(false)
          errorAlert(result.error.message);
      
           
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            //console.log(result.paymentIntent)
            setProcessing(false);

            let paymentIntent = result.paymentIntent;

           const res =  await instance.post(API_PATHS.MAKE_PAYMENT_CREATE_API, {paymentIntentId : paymentIntent.id });
            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback
            // execution. Set up a webhook or plugin to listen for the
            // payment_intent.succeeded event that handles any business critical
            // post-payment actions.
            successAlert();
          }else if(result.paymentIntent.status == "action_requried"){
            alert("hit");
          }
        }


      } catch (e) {
        console.log(e)
       // setLoader(false);
        errorAlert();
      }
    
  };


  const subsInfo = useSelector((state) =>
         state.service.subsInfo ? state.service.subsInfo : '',
      );


  const hideAlert = () => {
   
      setAlert(null);

      props.returnToBack();
  };


  const handleSubmitSub = async (event) => {
    
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }else{
      setError( {
        message : 'Please fill card details'
      });
       return;
    }
    

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: email,
      },
    });

   

    if (result.error) {
      setProcessing(false)
      errorAlert(result.error.message);
      
    } else {
      let formValues = {'payment_method': result.paymentMethod.id, plan_id:props.plan}
      const token = await localStorage.getItem("access_token");
      try {
        const instance = axios.create();
        instance.defaults.headers.common["Content-Type"] = "application/json";
        instance.defaults.headers.common["Authorization"] = "Bearer " + token;
        const response = await instance.post(API_PATHS.MAKE_SUBSCRIPTION_API, formValues);

        if(response.status === false){
          errorAlert('Something went wrong, Please try again');
          setProcessing(false)
        }else{

            const {client_secret, status} = response.data;

           /* if (response.data.success === 'action_required') {
              stripe.confirmCardPayment(client_secret).then(function(result) {
                if (result.error) {
                  errorAlert(result.error);
                  setProcessing(false)
                } else { 

                
                }
              });
            } else {
              successAlert();
              setProcessing(false)
            }*/

             if (result.paymentIntent.status === 'succeeded') {
            //console.log(result.paymentIntent)
            setProcessing(false);

            let paymentIntent = result.paymentIntent;

           const res =  await instance.post(API_PATHS.MAKE_PAYMENT_CREATE_API, {paymentIntentId : paymentIntent.id });
            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback
            // execution. Set up a webhook or plugin to listen for the
            // payment_intent.succeeded event that handles any business critical
            // post-payment actions.
            successAlert();
          }else if(result.paymentIntent.status == "action_requried"){
            alert("hit");
          }


        }
    
      } catch (e) {
       // setLoader(false);
        errorAlert();
        setProcessing(false)
      }

     

        
    }
  };



  return (
    <div>
    {alerts}
    <Card className={classes.root}>
      <CardContent className={classes.content}>
      {!props.isSubscription && !props.price? 
        <>
        <div>You can use wallet credits only for purchase a session </div>
        <TextField
          label='Add Credit'
          id='outlined-email-input'
          helperText={``}
          margin='normal'
          variant='outlined'
          type='number'
          inputProps={{min:"1", max:"1000"}}
          required
          value={price}
          onChange={(e) => ( console.log(e.target.value), setPrice(e.target.value), setError(e.target.value && (e.target.value <= 1000)? null : (e.target.value > 1000) ? {message: "Credit between 1 to 1000"} : {message: "Please add credits"}) ) } 
          fullWidth
        />
        </>
        : '' }
        <CardInput onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}/>
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
        <div className={classes.div}>
          
          {props.isSubscription ? 
          <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmitPay} disabled={processing || !stripe || error}>
            {processing ? "Processing..." : 'Subscribe '}
          </Button>

          : <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmitPay} disabled={processing || !stripe || error}>
            {processing ? "Processing..." : "Pay"}
          </Button>}
        </div>
      </CardContent>
    </Card>
    </div>
  );
}

export default HomePage;