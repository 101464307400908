
import React, { Component } from "react";
import { connect } from "react-redux";
//import { Link } from "react-router-dom";
import { PATHS } from "../../constants";
import { Field, reduxForm } from "redux-form";
import { required } from "../../constants/validation";
import {  SelectField } from "../../constants/redux-form";

import "../../App.css";
import { LoaderScreen, NavOnDashboard, FooterOnDashboard, Sidebar } from "../includes";
import {  setLoader, userInfo,  fetchAllMedium, updateMedium } from "../../actions";


/*
 TYPES
*/

type Props = {
  history: Object,
  userInfo: Function,
  setLoader: Function,
  isLoading: boolean
};

const bgImage = {
  '1':'ge-bg',
  '2':'mr-bg'
}

class LandingInner extends Component<Props> {

  constructor(props){
       super(props)
       this.state = {
          alertMessage:'',
          alertClass:"",
          isAuth : false,
       }
      
    }

  componentDidMount() {

    this.props.setLoader(true);
    const user = localStorage.getItem('isAuth') ? true : false;
    this.setState({ isAuth:user });

    this.props.fetchAllMedium();
    this.props.userInfo().then(result => {
        if (this.props.userDetail) {
            this.props.setLoader(false);
        }
    })
   
  }

 

  onSubmit = formValue => {
       
        setTimeout(() => {
            this.props.updateMedium(formValue).then(result => {
              console.log(this.props.response);
             
                this.props.setLoader(true);
                if (this.props.response.success) {
                    this.props.setLoader(false);
                    this.setState({ alertMessage: this.props.response.message, alertClass: "alert alert-success" });
                //    this.props.reset();

                } else {
                    this.props.setLoader(false);
                    this.setState({ alertMessage: this.props.response.message, alertClass: "alert alert-danger" });
                }


                setTimeout(() => {
                   this.props.history.push('/library/english-1-to-12');
                }, 1000);             
            });
        }, 500);
    };

  render() {
   
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    return (
     <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
        data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <NavOnDashboard user={this.props.userDetail}/>
        <Sidebar />
       <div className="page-wrapper">
       <div className="page-m-height">
            <div className="container-fluid">
                    <div className="row">
              {this.props.allMedium ? this.props.allMedium.map((data, index) => (
                <div className="col-md-6 col-xl-6 stretch-card" key={data.id}>
                            <div className={`card box-h ${bgImage[data.id]}`}>
                                <a href="javascript:void(0)" onClick={(e)=>this.onSubmit({'medium_id':data.id})}>
                                    <div className="bh-overlay">
                                        <h2>                                            
                                            {data.title}                                          
                                        </h2>
                                    </div>
                                </a>
                            </div>
                        </div>
                        ))
               : ''}      
                        
                        {/* <div className="col-md-6 col-xl-6 stretch-card">
                                                    <div className="card box-h mr-bg">
                                                        <a href="javascript:void(0)">
                                                            <div className="bh-overlay">
                                                                <h2>                                        
                                                                    Marathi
                                                                </h2>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div> */}
              
                        
                    </div>
                </div>
          </div>
          <FooterOnDashboard />
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
        isLoading: state.service.isLoading,
        userDetail: state.service.userDetailResponse ? state.service.userDetailResponse.data : '',
        allMedium : state.service.mediumResponse ? state.service.mediumResponse.data : '',
        response : state.service.updateMediumResponse ? state.service.updateMediumResponse.data : '',
    };
};

export default connect(mapStateToProps, {
  setLoader,
  userInfo,
  fetchAllMedium,
  updateMedium
})(LandingInner);