import React, { Component } from "react";
import { connect } from "react-redux";
 import { Link } from "react-router-dom";
 import { PATHS } from "../../constants";


import "../../App.css";
import { LoaderScreen, NavOnIndex, FooterOnIndex } from "../includes";
import { fetchCmsPage, setLoader } from "../../actions";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';
/*
 TYPES
*/

type Props = {
  history: Object,
  langList: Object,
  fetchCmsPage: Function,
  setLoader: Function,
  isLoading: boolean
};

class Terms extends Component<Props> {

  constructor(props){
       super(props)
       this.state = {
         titleCms : "",
         descriptionCms : ""
       }
       //creates a reference for your element to use
       this.myDivToFocus = React.createRef();
    //document.getElementById('canonical').setAttribute('href', window.location.href); 
    }

  componentDidMount() {
 
     let pathname = this.props.location.pathname;
     let slug = null; 
     switch(pathname){
          case '/terms-and-conditions':
              slug = 'terms-of-use';
              this.setState({ titleCms: "Terms and condition | MyEduMaster"}); 
              this.setState({ descriptionCms: "These terms and conditions outline the rules and regulations for the use of Edumastertrik LLP's website, located at www.myedumaster.com. Read carefully."}); 
              break;
          case '/about-us':
              slug = 'about-us';
         this.setState({ titleCms: "About Us | MyEduMaster" });
         this.setState({ descriptionCms: "Learning english is easy now. Our team of skilled and pleasant teachers develop english courses for quick learning." }); 
              break;
          case '/privacy-policy':
              slug = 'privacy-policy'; 
         this.setState({ titleCms: "Privacy policy | MyEduMaster" });
         this.setState({ descriptionCms: "We safeguard the user's personal information and never share it  with third party. MyEduMaster never sells your information to a third party." });    
              break; 
          case '/practice':
              slug = 'practice';
         this.setState({ titleCms: "Practice Test for learner | MyEduMaster" });
         this.setState({ descriptionCms: "MyEduMaster provides general examination practice tests to assess your knowledge. Sign up now to get command on english!" });     
              break; 
          default:
              slug = 'terms-of-use';
              this.setState({ titleCms: "Terms and condition | MyEduMaster" });
              this.setState({ descriptionCms: "These terms and conditions outline the rules and regulations for the use of Edumastertrik LLP's website, located at www.myedumaster.com. Read carefully." }); 
              break;       
       
     }

    //document.getElementById('canonical').setAttribute('href', window.location.href); 

   this.props.fetchCmsPage(slug);

    AOS.init();
   
  }

  

  componentDidUpdate(prevProps) {  
      let pathname = this.props.location.pathname;
       let slug = null;
       switch(pathname){
            case '/terms-and-conditions':
                slug = 'terms-of-use'; 
                break;
            case '/about-us':
                slug = 'about-us';  
                break;
            case '/privacy-policy':
                slug = 'privacy-policy';   
                break;   
            case '/practice':
              slug = 'practice';    
              break;      
            default:
              slug = 'terms-of-use';
              break;   
       }
      if (prevProps.location.pathname !== this.props.location.pathname) {
        this.props.fetchCmsPage(slug);
       
              if (slug === 'about-us') {
                this.setState({ titleCms: "About Us | MyEduMaster" });
                this.setState({ descriptionCms: "Learning english is easy now. Our team of skilled and pleasant teachers develop english courses for quick learning." });
              } else if (slug === 'practice') {
                this.setState({ titleCms: "Practice Test for learner | MyEduMaster" });
                this.setState({ descriptionCms: "MyEduMaster provides general examination practice tests to assess your knowledge. Sign up now to get command on english!" }); 
               } else if (slug === 'privacy-policy') {
                this.setState({ titleCms: "Privacy policy | MyEduMaster" });
                this.setState({ descriptionCms: "We safeguard the user's personal information and never share it  with third party. MyEduMaster never sells your information to a third party." });
              } else if (slug === 'terms-and-conditions') {
                this.setState({ titleCms: "Terms and condition | MyEduMaster" });
                this.setState({ descriptionCms: "These terms and conditions outline the rules and regulations for the use of Edumastertrik LLP's website, located at www.myedumaster.com. Read carefully." }); 
              } else if (slug === 'terms-of-use') {
                this.setState({ titleCms: "Terms and condition | MyEduMaster" });
                this.setState({ descriptionCms: "These terms and conditions outline the rules and regulations for the use of Edumastertrik LLP's website, located at www.myedumaster.com. Read carefully." });
              }
      }
          //document.getElementById('canonical').setAttribute('href', window.location.href); 
    }


  render() {


    if (this.props.isLoading) { 
      return <LoaderScreen />; 
    }

    

    return (
      <div className="main-wrapper white-bg">
        <Helmet>
          <meta charSet="utf-8" />
 
          
          <title> { this.state.titleCms }</title>
          <meta name="description" content={this.state.descriptionCms } />
          {/* <link rel="canonical" href={window.location.href} /> */}
        </Helmet>
        <NavOnIndex inner="true"/>
            <div className="innr-banner inn-pt">
              <div className="container pt-4 pb-4">
                  <div className="row">
                      <div className="col-md-10">
                          <nav aria-label="breadcrumb" data-aos="fade-up" data-aos-delay="100" className="aos-init aos-animate">
                            <ol className="breadcrumb p-0">
                                <li className="breadcrumb-item"><Link to={PATHS.HOME}>Home</Link></li>
                                <li className="breadcrumb-item" aria-current="page">{this.props.response ? this.props.response.title : ''}</li>
                            </ol>
                        </nav>
                        <div className="mb-2 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <h1>
                                { this.props.response ? this.props.response.title : '' }
                            </h1>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            <div className="section py-5">
                  <div className="container">
                    <div className="row">
                       {/* <div className="col-md-12">
                            <div className="black-heading mb-2 aos-init" data-aos="fade-up" data-aos-delay="300">
                              <h2 className="text-left">Lorem ipsum dolor</h2>
                            </div>
                        </div> */}
                        <div className="col-md-12 aos-init" data-aos="fade-up" data-aos-delay="400">
                            {this.props.response ? ReactHtmlParser(this.props.response.content) : ''}

                        </div>
                    </div>
                </div>
            </div>
            {this.props.location.pathname === "/practice" && 
               <div className="text-center py-2">
                 <Link to={PATHS.PRACTICE_TEST} className="btn btn-primary pl-4 pr-4">Practice Test</Link>
              </div>
            }
        <FooterOnIndex />

      </div>
    );
  }
}

const mapStateToProps = state => {

    return {
        response: state.service.getCmsPageResponse ? state.service.getCmsPageResponse.data : '',
        isLoading: state.service.isLoading
    };
};

export default connect(mapStateToProps, { fetchCmsPage, setLoader })(Terms);
