import React, {  useEffect} from "react";
import { useSelector, useDispatch,  } from "react-redux";

import { getQuizResult, userInfo } from "../../actions";
//import moment from "moment";
import { LoaderScreen, NavOnDashboard, FooterOnDashboard, Sidebar } from "../includes";
import ReactPlayer from 'react-player';
import ReactHtmlParser from 'react-html-parser';


const QuizResult = (props) => { 
    

     const dispatch = useDispatch();
     useEffect(() => {
            
             dispatch(userInfo(true));
             dispatch(
                  getQuizResult({'quiz_id' : props.match.params.id})
              )
             
          
       }, [dispatch, props.match.params.id]);

    
     const [quizResponse, isLoading, userDetail, path] = useSelector((state) => [
        state.service.quizResponse ? state.service.quizResponse : "",
        state.service.isLoading,
        state.service.userDetailResponse ? state.service.userDetailResponse.data : '',
        state.service.userDetailResponse.path ? state.service.userDetailResponse.path : "",
      ]);

     const videoPathFunction = (site)  => {

        let basePath = path.base_path;
       let paths = basePath.replace(/\/$/, "") + site;

       console.log(site);

        return paths
      }   


     
  
     if (isLoading) {
      return <LoaderScreen />;
    }


    let data = quizResponse.success ? quizResponse.data : "";

    const ansList = () => {


      let attemptData = data.attempted_data;
      return (
         <div className="rr-section">

             {data.quiz.quiz_questions.map((question, index) => {
               let filterDatas = attemptData.filter((datas)=> datas.question_id === question.id);
               
               let filterData = filterDatas ? filterDatas[0] : "";
            
               if(filterData){
                return (
              
                    <div className="ques-panel mb-3" key={index}>
                        <div className="ques-head mb-2">
                            <h4 className="mb-3 mb-md-0 ques-heading">
                                {/* {`${index + 1}. ${ReactHtmlParser(question.question_title)}`}    */}
                                { index + 1}. { ReactHtmlParser(question.question_title) }
                                                                               
                            </h4>
                            <div className="ml-md-auto"><span>Question Type :</span> <strong> {question.question_type === 1 ? 'SCQ' : question.question_type === 2 ? 'MCQ' : question.question_type === 3 ? 'Essay' : 'Video'}</strong></div>

                           
                        </div>
                        {/* <strong className="ques-marks ml-3 mb-2 d-block">Question Marks : {question.marks }</strong> */}
                        <strong className="ques-marks ml-3 mb-2">
                          <span>Question Marks : {question.marks }</span>
                         { (data.quiz_status === 1)  && <span>Obtain Marks : {filterData.get_marks}</span> }
                        </strong>

                        <div className="result-review">
                            <ul>                              
                                 {question && ( question.question_type === 3 ||  question.question_type === 4 ) ?
                                     question.question_type === 3 ? 
                                       <li>Ans.{filterData.attempt_answer_essay}</li>
                                      :
                                      <>
                                         <li>
                                         <div className="row">
                                           <div className="col-sm-5">
                                             Que.<br></br>
                                             <ReactPlayer url={videoPathFunction(question.video_path)}
                                                controls
                                                width="380px"
                                                height="200px"

                                             />
                                             </div>
                                             <div className="col-sm-5">
                                             Ans.<br></br>
                                             <ReactPlayer url={path.base_path+'uploads/video/'+filterData.attempt_answer_video}
                                                controls
                                                width="380px"
                                                height="200px"
                                             />
                                             </div>
                                           </div>
                                         </li>
                                         
                                      </>
                                   : 
                                   question.question_options ? question.question_options.map((result, i) => {
                                    let wrongAns = "";
                                    let ansArray = [];

                                    if(data.quiz_status === 1){

                                        if (filterData.attempt_answer.indexOf(',') > -1) { 
                                           ansArray = filterData.attempt_answer.split(',');
                                        }else{
                                           ansArray = Array(filterData.attempt_answer);
                                        }
             
                                        if( ansArray.indexOf(String(result.id)) > -1 ) {
                                          wrongAns = (result.is_right_answer ? "green-text" : "red-text" );
                                        }else{
                                          wrongAns = result.is_right_answer ?  "green-text" : "";
                                        }
                                    }else{
                                        wrongAns = (result.is_right_answer ? "blue-text" : "" );
                                    }
                                    return (                                       
                                     <li key={i} className={wrongAns }>{(i + 10).toString(36)}.{result.option_value} </li>

                                   )}) : ""}   
                            </ul>
                        </div>    
                    </div>               
                  )
               }             

              }
              )}
               
          </div>
        )
    }

    return (
        data ?
            <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
              data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
              
              <NavOnDashboard user={userDetail}/>
              <Sidebar />

              <div className="page-wrapper">
                  <div className="page-m-height">                
                  
                      <div className="page-breadcrumb">
                          <div className="row">
                              <div className="col-7 align-self-center">
                                  <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">Quiz Result</h3>                            
                              </div>
                             
                          </div>
                      </div>
                      <div className="container-fluid">                    
                          <div className="row">
                              <div className="col-xl-12">
                                  <div className="card">
                                      <div className="card-body">
                                      {data.quiz_status === 1 ?
                                      <div className="row mb-3">
                                              <div className="col-md-6 col-lg-4">
                                                  <div className="p-2 bg-primary text-center mb-3">
                                                      <h1 className="font-light text-white">{data.total_marks}</h1>
                                                      <h6 className="text-white">Total Marks</h6>
                                                  </div>
                                              </div>
                                             
                                              <div className="col-md-6 col-lg-4">
                                                  <div className="p-2 bg-danger text-center mb-3">
                                                      <h1 className="font-light text-white">{data.get_marks ? data.get_marks : 0}</h1>
                                                      <h6 className="text-white">Obtain Marks</h6>
                                                  </div>
                                              </div>
                                              <div className="col-md-6 col-lg-4">
                                                  <div className="p-2 bg-warning text-center">
                                                      <h1 className="font-light text-white">{data.percentage}%</h1>
                                                      <h6 className="text-white">Percentage</h6>
                                                  </div>
                                              </div>
                                          </div>
                                          :
                                          <div className="row mb-3">
                                              <div className="col-md-6 col-lg-4">
                                                  <div className="p-2 bg-primary text-center mb-3">
                                                      <h1 className="font-light text-white">{data.total_marks}</h1>
                                                      <h6 className="text-white">Total Marks</h6>
                                                  </div>
                                              </div>
                                             
                                              <div className="col-md-6 col-lg-4">
                                                  <div className="p-2 bg-warning text-center">
                                                      <h1 className="font-light text-white">{data.quiz_status === 0 ? "Unchecked" : "Need to Check"}</h1>
                                                      <h6 className="text-white">Quiz Status</h6>
                                                  </div>
                                              </div>
                                          </div>
                                          
                                          }

                                            {ansList()}              
                                         
                                      </div>
                                  </div>
                              </div>                        
                          </div>
                          
                      </div>
                  </div>
                  <FooterOnDashboard />           
              </div>
          </div>
          :
          <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
              data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
              
              <NavOnDashboard user={userDetail}/>
              <Sidebar />

              <div className="page-wrapper">
                  <div className="page-m-height">                
                  
                      <div className="page-breadcrumb">
                          <div className="row">
                              <div className="col-7 align-self-center">
                                  <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">Quiz Result</h3>                            
                              </div>
                             
                          </div>
                      </div>
                      <div className="container-fluid">                    
                          <div className="row">
                              <div className="col-xl-12">
                                  <div className="card">
                                      <div className="card-body">
                                          <div className="rr-section">
                                              <div className="ques-panel mb-3">
                                                  No result found   
                                              </div>

                                              
                                          </div>
                                      </div>
                                  </div>
                              </div>                        
                          </div>
                          
                      </div>
                  </div>
                  <FooterOnDashboard />           
              </div>
          </div>
        )
}

export default QuizResult ;