
const required = value => ((value || value === 0)  ? undefined : 'This field is required');

const minLength = max => value =>
  value && value.length < max ? `Password Length must be ${max} characters or more` : undefined;
const minLength6 = minLength(6);

const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    'Invalid email address' : undefined;

const passwordValidation = value =>
  value && !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$/.test(value) ?
    'Not a strong password (Ex. 12345@aA)' : undefined;

const nondecimal = value => value && !/^[0-9]{1,9}$/i.test(value) ?
  'Must be a non decimal number' : undefined;

const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined;
const onlyChar = value => (value && !/^[a-zA-Z ]*$/i.test(value) ? 'Please insert only characters' : undefined);
const phoneNumber = value =>
  (value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined);

const phoneNumberValidation = value =>
  (value && !/^(0|[1-9][0-9]{7,14})$/i.test(value)
    ? 'Invalid phone number, must be between 8 to 15 digits'
    : undefined);

 const whiteSpace = value => value && !/^([a-z]+\s)*[a-z]+$/i.test(value) ? 'White space not allowed' : undefined;
// const whiteSpace = value => value && !/^\S{3,}$/i.test(value) ? 'Please enter valid name' : undefined;

// const whiteSpaceNotAllowed = value => value && !/^\S{1,}$/i.test(value) ? 'White space not allowed' : undefined;

const whiteSpaceNotAllowed = value => value && !/^([A-Z0-9.#?!@$%^&*-]+\s)*([A-Z0-9.#?!@$%^&*-])+$/i.test(value) ? 'Please enter valid value' : undefined;

const normalizePhone = value => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 6) {
    return onlyNums
  }
}

const normalizeSchool = value => {
  if (!value) {
    return value
  }
  const schoolName = value.trimLeft(/^[a-z\d\-_\s]+$/g)
  return schoolName;
}

const pinLength = max => value =>
  value && value.length < max ? `Pin Length must be ${max} digits` : undefined;
const pinLength6 = pinLength(6);

const passwordsMatch = (value, allValues) => 
  value !== allValues.password ? 'Passwords don\'t match' : undefined;

const validVideoFile = (videoFile) => {
  console.log(videoFile)
  var _validFileExtensions = ["mp4", "mov", "avi", "wmv"];
   return (videoFile && videoFile.length > 0 && !videoFile[0].name.match(/.(mp4|mov|avi|wmv)$/i) ) ? 'Please provide valid video file' : undefined
}


export { required, minLength6, email, nondecimal, number, onlyChar, phoneNumber, passwordValidation, whiteSpace,whiteSpaceNotAllowed, normalizePhone, normalizeSchool, pinLength6, phoneNumberValidation, passwordsMatch, validVideoFile};
