import React, { useEffect, useState} from "react";
import { LoaderScreen, NavOnDashboard, FooterOnDashboard, Sidebar, ModalStripe } from "../includes";
import { userInfo, setLoader, fetchPlans } from "../../actions";
import { useSelector, useDispatch } from "react-redux";


import ReactHtmlParser from 'react-html-parser';
// import Stripe from '../stripe';
import SweetAlert from "react-bootstrap-sweetalert";




const Plan = (props) => { 

  console.log(props);
	const dispatch = useDispatch();

	useEffect(() => {
	    dispatch(userInfo());
	    dispatch(setLoader(true));
        dispatch(fetchPlans());
	 }, [dispatch]);

    const [show, setShow] = useState(false);
    const [plan, setPlan] = useState('');
    const [alert, setAlert] = useState(null);
    const [fromTopic, setFromTopic] = useState(props.location.state);


	const userDetail = useSelector((state) =>
	     state.service.userDetailResponse ? state.service.userDetailResponse.data : '',
	  );

    const plansInfo = useSelector((state) =>
         state.service.plansInfo ? state.service.plansInfo.data : '',
      );

     const subscriptionActive = useSelector((state) =>
         state.service.plansInfo ? state.service.plansInfo.is_subscription_active : false,
      );

  
  	const isLoading = useSelector((state) =>
	      state.service.isLoading
	  );



    const planDuration = (type) => {
        var val = '';
          switch (type) {
            case 1:
                val = 'Week';
                break;
            case 2:
                val = 'Month';
                break;
            case 3:
                val = 'Year';
                break;
            case 4:
                val = 'Day'; 
                break;
            default:
                val = 'Day';  
                break;             
          }
          return val;
    }

    const handleClick = (e) => {
        e.preventDefault();


        if(subscriptionActive){
           setAlert(
              <div>
                <SweetAlert
                    warning
                    title="Yehh!"
                    confirmBtnText="Ok"
                    onConfirm={() => hideAlert()}
                  >
                    You have already subscribed a plan, Be patience!
                  </SweetAlert>
              </div>
          );
           return;
        }

        setShow(true);
        setPlan(e.target.dataset.plan_id);
    }  

    const hideAlert = () => {
       dispatch(fetchPlans());
   
      setAlert(false);
    
  };


    const returnToBack = () => {
        console.log(props);
        setShow(false)
        setPlan('');
        if(fromTopic){
          props.history.goBack()
        }
    } 


  	if (isLoading) {
      return <LoaderScreen />;
    }



    return (<>

      {alert}
       
			<div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
		        data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
		        <NavOnDashboard user={userDetail}/>
		        <Sidebar />
		        <div className="page-wrapper">
		          <div className="page-m-height">
    		          <div className="page-breadcrumb">
                            <div className="row">
                                <div className="col-12 col-sm-8 align-self-center mb-3 mb-sm-0">
                                    <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">Membership</h3>
                                </div>
                                
                            </div>
                        </div>
    		            <div className="container-fluid">
                            <div className="row">
                            	 <div className="col-md-12 stretch-card">
                                      <section className="premium-plan">
                                        <div>
                                          <div className="section-title mb-3 mb-lg-5">
                                            <p className="pr-5">
                                              Choose an au pair with the market leader. Premium gives host families full access to the largest selection of high-quality au pair profiles available anywhere on the Internet − with more than 30,000 au pairs actively searching on the platform.
                                            </p>
                                          </div>
                                          <div className="row justify-content-center">
                                          {plansInfo && plansInfo.map((plan, index)=>
                                                <div className="col-lg-4 col-md-12 mb-4 mb-md-0" data-aos="fade-up" data-aos-delay="100" key={plan.id}>
                                                      
                                                      <div className={`box mb-3  ${plan.billing_type === 2 ? 'featured blue-brdr big-box': 'mt-3' }`}>
                                                      {plan.billing_type === 2 ? <span className="advanced"> Best Seller </span> : '' }
                                                        <h3>{plan.title}</h3>
                                                        <h4>
                                                          <strong className="fw-400">₹</strong> {plan.price} <br />
                                                          <span> 1 {planDuration(plan.billing_type)}</span>
                                                        </h4>
                                                        {ReactHtmlParser(plan.description)}
                                                        <div className="btn-wrap">
                                                          <a href="#!" onClick={(e)=>handleClick(e)} className="btn waves-effect waves-light btn-rounded btn-primary pl-4 pr-4" data-plan_id={plan.plan_id}>Buy Now <i className="fas fa-shopping-cart"></i></a>
                                                        </div>
                                                      </div>
                                                 </div>

                                            )}
                                            
                                            {/*<div className="col-lg-4 col-md-12 mb-4 mb-md-0" data-aos="fade-up" data-aos-delay="200">
                                              <div className="box featured mb-3 blue-brdr big-box">
                                                 <span className="advanced">Best Seller</span>
                                                <h3>Premium Plus</h3>
                                                <h4>
                                                   <strong className="fw-400">€</strong> 69.90 <br />
                                                  <span> 3 month</span>
                                                </h4>
                                                <ul>
                                                  <li>Our all-round favourite for easy and convenient planning</li>
                                                  <li>(Monthly cost: 23.30 €)</li>                      
                                                </ul>
                                                <div className="btn-wrap">
                                                  <a href="#" className="btn waves-effect waves-light btn-rounded btn-primary pl-4 pr-4">Buy Now <i className="fas fa-shopping-cart"></i></a>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-lg-4 col-md-12 mb-4 mb-md-0" data-aos="fade-up" data-aos-delay="300">
                                              <div className="box mb-3 mt-3">
                                                <h3>Premium 365</h3>
                                                <h4>
                                                  <strong className="fw-400">€</strong> 129.90<br />
                                                  <span> 12 month</span>
                                                </h4>
                                                <ul>
                                                  <li>The all year solution for uninterrupted searching flexibility</li>
                                                  <li>(Monthly cost: 10.83 €)</li>                      
                                                </ul>
                                                <div className="btn-wrap">
                                                  <a href="#" className="btn waves-effect waves-light btn-rounded btn-primary pl-4 pr-4">Buy Now <i className="fas fa-shopping-cart"></i></a>
                                                </div>
                                              </div>
                                            </div> */}
                                          </div>
                                          <div className="row justify-content-center">
                                            <div className="col-md-6 text-center mt-2 mt-md-5">
                                              <p>
                                                Select one of our Premium Packages to fit your family's searching needs and choose your au pair from the largest selection on the Internet.
                                              </p>
                                              <p>
                                                <strong>All purchases are one-time transactions.</strong> <br />
                                                <strong>You can relax: There is no automatic renewal. </strong>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </section>
                                  </div>
                            </div>
                            </div>
                    </div>

                  {/*<ModalStripe show={show} isSubscription={true} plan={plan} price={''} returnToBack={returnToBack} user={userDetail} fromTopic={ fromTopic}/>*/}
		              <ModalStripe show={show} isSubscription={true}  plan={plan} price={''} 
                  returnToBack={returnToBack} user={userDetail}/>
		            <FooterOnDashboard />
		        </div>
		    </div>	
           
			</>
			);
    


}

export default Plan;