
import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { PATHS } from "../../constants";
//import { connect } from "react-redux";
//import { userLogin } from "../../actions";
import  webLogo  from "../../images/web-logo.svg"
import { withRouter } from "react-router";
import {Notification} from "../includes";
import { useSelector } from "react-redux";


import * as $ from 'jquery';
window.jQuery = window.$ = $

const NavOnDashboard = (props) => { 

   
   let url = "";


   
    const handleLogout = (e) => {
      e.preventDefault();
      localStorage.clear();

      props.history.push(PATHS.LOGIN);
      
    }

    useEffect(() => {

        $('#sidebarCollapse').on('click', function (e) {
                e.preventDefault();
                $('#sidebar').toggleClass('active');
         });
     
    },[])

    const imagePath = useSelector(state => state.service.userDetailResponse ? state.service.userDetailResponse.path:[] );



  return (<header className="topbar" data-navbarbg="skin6">
            <nav className="navbar top-navbar navbar-expand-md">
                <div className="navbar-header" data-logobg="skin6">
                    <a href={url}  id="sidebarCollapse" className="nav-toggler waves-effect waves-light d-block d-md-none">
                        <i className="fas fa-bars"></i>
                    </a>
                    <div className="navbar-brand">
                        <Link to={PATHS.DASHBOARD}>
                            <b className="logo-icon">
                                <img src={webLogo} alt="homepage" className="web-logo" style={{width: '215px'}}/>
                            </b>                           
                        </Link>
                    </div>
                    <a href={url} className="topbartoggler d-block d-md-none waves-effect waves-light" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fas fa-ellipsis-h"></i>
                    </a>
                </div>
                <div className="navbar-collapse collapse" id="navbarSupportedContent">
                  
                    <ul className="navbar-nav float-left mr-auto ml-3 pl-1">
                      
                       {/* <li className="nav-item d-none d-md-block tpsearch-box">                            
                            <a className="nav-link pl-0" href="/#">
                                <form>
                                    <div className="customize-input">
                                        <input className="form-control custom-shadow custom-radius border-0 bg-white"
                                            type="search" placeholder="Search" aria-label="Search" />
                                            <i className="fas fa-search form-control-icon"></i>
                                    </div>
                                </form>
                            </a>
                        </li> */}
                        
                      
                    </ul>
                    <ul className="navbar-nav float-right right-nav">
                       
                        <li className="nav-item dropdown">
                           <Notification mount={props.mount}/>
                        </li>

                        
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle d-flex align-items-center justify-content-center" href="/#" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                {props.user && props.user.profile_image ?
                                    <img src={ imagePath ? (imagePath.user + props.user.profile_image) : '' } alt="user" className="rounded-circle profile-image-extra"/> 
                                  : <img src="/assets/images/users/profile-pic.jpg" alt="user" className="rounded-circle"
                                    width="40" /> }
                                <span className="ml-2 d-none d-lg-inline-block">
                                    <span>Hello,</span> 
                                    <span className="">{props.user ? props.user.name : ''}</span> 
                                    <i className="fas fa-chevron-down"></i>
                                </span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right user-dd animated flipInY pt-3 pb-3">
                                <Link className="dropdown-item d-flex align-items-center" to={PATHS.UPDATE_PROFILE} >
                                    <i className="fas fa-user"></i>
                                    <span className="ml-2">My Profile</span>
                                </Link>
                                <Link className="dropdown-item d-flex align-items-center" to={PATHS.CHANGE_PASSWORD} >
                                    <i className="fas fa-key"></i>
                                    <span className="ml-2">Change Password</span>
                                </Link>

                                <Link className="dropdown-item d-flex align-items-center" to={PATHS.UPDATE_MEDIUM} >
                                    <i className="fas fa-key"></i>
                                    <span className="ml-2">Update Medium</span>
                                </Link>

                                <Link className="dropdown-item d-flex align-items-center" to={PATHS.MY_WALLET} >
                                    <i className="fas fa-wallet"></i><span className="ml-2">My Transaction</span>
                                </Link>
                                
                                <a className="dropdown-item d-flex align-items-center" href="/#"  onClick={handleLogout}>
                                    <i className="fas fa-power-off"></i>
                                    <span className="ml-2">Logout</span>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>); 
}



export default withRouter((NavOnDashboard));

