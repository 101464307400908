export const randomClass = () => {
	var colors = ["text-c-blue","text-c-yellow","text-c-pink","text-c-purple", "text-c-green", "text-c-black"];
	var randomColor = colors[Math.floor(Math.random()*colors.length)];
	return randomColor;
}

export const randomButtonClass = () => {
  var colors = ["btn-primary","btn-danger","btn-success","btn-cyan"];
  var randomColor = colors[Math.floor(Math.random()*colors.length)];
  return randomColor;
}


export const makeTitle = (slug) => {
  var words = slug.split('-');

  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(' ');
}


export const add3dots =(text, count) => {
    return text.slice(0, count) + (text.length > count ? "..." : "");
}

export const greetings = () => {

    let myDate = new Date();
    let hrs = myDate.getHours();
    let greet = '';
    if (hrs < 12)
        greet = 'Good Morning';
    else if (hrs >= 12 && hrs <= 17)
        greet = 'Good Afternoon';
    else if (hrs >= 17 && hrs <= 24)
        greet = 'Good Evening';

    return greet;
}


export const titleCase =(str) => {
  // str = str ? str.toLowerCase().split(' ') : 'n/a'
  // for (var i = 0; i < str.length; i++) {
  //   str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
  // }
  // return str.join(' ');
  return str;
}
