import React, {  useEffect} from "react";
import { useSelector, useDispatch,  } from "react-redux";

import { fetchNotification, markReadNotification } from "../../actions";
import moment from "moment";
//import { Link } from "react-router-dom";
import { PATHS } from "../../constants";
import { withRouter } from "react-router";

const Notification = (props) => { 
    

     const dispatch = useDispatch();
     useEffect(() => {
          
              dispatch(fetchNotification())
          
       }, []);

     const notification = useSelector((state) =>
       state.service.notificationResponse ? state.service.notificationResponse.data : '',
    );

     const isLogout = useSelector((state) =>
       state.service.isLogout,
    );




     const notificationIcon = (type) => {
        let icon = "";
        let url = "";


        switch(type) {
              case 1:
                  icon = 'fas fa-chalkboard-teacher';
                  url = '/requested-session';  
                break;
              case 2:
                  icon = 'fas fa-chalkboard-teacher';
                  url = '/live-session'; 
                break;
              case 3:
                  icon = 'fas fa-chalkboard-teacher';
                  url = '/practice-test'; 
                break;  
              case 4:
                  icon = 'fas fa-chalkboard-teacher';
                  url = '/events'; 
                break;    

              default:
                  icon = "fas fa-chevron-down";
                  url = "/";
            }

        return { icon : icon, url:url}

     }  


     const handleView = (noti, e) => {
        e.preventDefault();
        dispatch(markReadNotification(noti.id))

        let url = notificationIcon(noti.type);
        dispatch(fetchNotification())

        props.history.push(url.url);

     }


   
    return (
        <>
            <a className="nav-link dropdown-toggle pl-md-3 position-relative" href="/#"
                id="bell" role="button" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <span className="notify-span"><i className="far fa-bell"></i></span>
                <span className="badge badge-primary notify-no rounded-circle">{notification ? notification.unread_count===0?'':notification.unread_count : ''}</span>
            </a>
            <div className="dropdown-menu dropdown-menu-right mailbox animated bounceInDown">
                <ul className="list-style-none">
                    <li>
                        <div className="message-center notifications position-relative">
                        {( notification && notification.notification && notification.notification.length> 0)
                            ? notification.notification.map((noti, i) => (
                                    <a href="#!" key={i} onClick= {(e) => handleView(noti, e) }
                                        className="message-item d-flex align-items-center border-bottom px-3 py-2"
                                        style={{backgroundColor : (noti.read_at !== null ? '' : '#ddd')}}>
                                        <div className="btn btn-danger rounded-circle btn-circle">
                                            <i className={notificationIcon(noti.type).icon}></i>
                                        </div>
                                        <div className="w-75 d-inline-block v-middle pl-2">
                                            {/*<h6 className="message-title mb-0 mt-1">{noti.sender_name}</h6>*/}
                                            <span className="font-12 text-nowrap d-block text-muted">{noti.message}!</span>
                                            <span className="font-12 text-nowrap d-block text-muted">{moment(noti.created_at).fromNow()}</span>
                                        </div>
                                    </a>
                                ))
                                :
                                
                                <a href="#!"
                                    className="message-item d-flex align-items-center border-bottom px-3 py-2">
                                    <div className="btn btn-danger rounded-circle btn-circle">
                                        <i className="fas fa-chevron-down"></i>
                                    </div>
                                    <div className="w-75 d-inline-block v-middle pl-2">
                                        {/* <h6 className="message-title mb-0 mt-1">Luanch Admin</h6> */}
                                        <span className="font-12 text-nowrap d-block text-muted">Notification not found!</span>
                                        <span className="font-12 text-nowrap d-block text-muted">9:30 AM</span>
                                    </div>
                                </a>
                            }
                        </div>
                    </li>
                    {/* <li>
                        <a className="nav-link pt-3 text-center text-dark" href="/#">
                            <strong>Check all notifications</strong>
                            <i className="fa fa-angle-right"></i>
                        </a>
                    </li> */}
                </ul>
            </div>
        </>
        )
}

export default withRouter((Notification)) ;