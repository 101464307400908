 import React, {useState, useRef} from "react";
import { Modal, Button } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import ReactPlayer from 'react-player';
import { Document, Page, pdfjs} from 'react-pdf';
import { VIDEO_BASE_PATH } from "../../constants";
import 	Player from "./Player";
import screenful from "screenfull";
import {  faExpandArrowsAlt, faCompress } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./style.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const ModalChapter = (props) => { 


	const [numPages, setNumPages] = useState(null);
	const [fullscreen, setFullscreen] = useState('');


	function onDocumentLoadSuccess({ numPages }) {
	    setNumPages(numPages);
	}

	 // const toggleFullScreen = () => {
	 //    screenful.toggle(containerRef.current);
	 //  };

	 const expand = (cls) => {
	 
	 	if(fullscreen == ''){
	 		setFullscreen('modal-90w')
	 	}else{
	 		setFullscreen('')
	 	}
	 	
	 }
	
	
	if(!props.show){
          return null;
      }

     

	return (<>
			
				<Modal
	              show={props.show}
	              onHide={() => props.onClose()}
	              dialogClassName={fullscreen}
	              size='lg'
	            >
	              <Modal.Header closeButton>
	                <Modal.Title>
	                 {props.chapterDetails ? props.chapterDetails.title : ''}
	                </Modal.Title>
	                <div className="expand" onClick={() => expand()} >
				         {fullscreen ? <FontAwesomeIcon icon={faCompress} />  : <FontAwesomeIcon icon={faExpandArrowsAlt} /> }
				    </div>
	              </Modal.Header>
	              <Modal.Body>
	              {
	              	
	              	props.chapterDetails.content_type === 1 
	              	? 
	              	(props.chapterDetails ? ReactHtmlParser(props.chapterDetails.content) : '')
	              	: props.chapterDetails.content_type === 2 
	              	? <div className="all-page-container">
					      <Document
					        file={VIDEO_BASE_PATH+props.chapterDetails.pdf_file_path}
					        onLoadSuccess={onDocumentLoadSuccess}
					        onLoadError={(err)=>console.log(err.message)}
					        loading="Loading page…"
					      >
					       {Array.apply(null, Array(numPages))
								    .map((x, i)=>i+1)
								    .map((page, index) => <Page key={index} pageNumber={page} />)}
					       
					      </Document>
					      {/*<p>Page {pageNumber} of {numPages}</p> <Player url={VIDEO_BASE_PATH+props.chapterDetails.video_path}
 						title={props.chapterDetails.title}/> 
			                    		
*/}
					    </div>
	              	: 

	              	<ReactPlayer
			              className='react-player'
			              url={VIDEO_BASE_PATH+props.chapterDetails.video_path}
			              playing={true}
			              controls={true}
			              width='100%'
              			  height='100%'	
			            /> 
	              }
	              	</Modal.Body>

	              {/*	<Modal.Footer style={{justifyContent:"center"}}>
	              	{props.prev === undefined ? '' :
					    <Button variant="primary" onClick={()=>props.orev(props.chapterDetails)}>Prev</Button> }
					{props.next === undefined ? '' :
					    <Button variant="primary" onClick={()=>props.next(props.chapterDetails)}>Next</Button> }    
					    
					  </Modal.Footer> */}
	            </Modal>
	          
			</>
			);
	}	

export default ModalChapter;
