import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { PATHS } from "../../constants";


import "../../App.css";
import { LoaderScreen, NavOnIndex, FooterOnIndex, NavOnDashboard, FooterOnDashboard, Sidebar } from "../includes";
import {  setLoader, fetchPracticeTest, userInfo } from "../../actions";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import ReactHtmlParser from 'react-html-parser';
//import {IMAGE_BASE_PATH} from "../../constants";
import  {randomClass,  add3dots, titleCase}  from "../../utils/general"
import {  faQuestion, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from '../pagination/Pagination.component';
import Post from './SingleCard.component';
/*
 TYPES
*/

type Props = {
  history: Object,
  fetchPracticeTest: Function,
  setLoader: Function,
  isLoading: boolean
};

class PracticeTest extends Component<Props> {

  constructor(props){
       super(props)
       this.state = {
          value:'',
          isAuth : false,
          filter:'',
          data:[],
       }
      
    }

  componentDidMount() {

    this.props.setLoader(true);

    
     this.props.fetchPracticeTest().then(result => {
        if (this.props.testInfo) {
           this.setState({data: this.props.testInfo});
        }
    });
     
     const user = localStorage.getItem('isAuth') ? true : false;
    this.setState({ isAuth:user });

   
    this.props.userInfo().then(result => {
        if (this.props.userDetail) {
            this.props.setLoader(false);
        }
    })
  
    AOS.init();
   
  }

 

  handleChange = (event) => {
    this.setState({value: event.target.value},()=>this.renderList());
    
  }

  //  handleView = (e,topic_id) => {
  //   e.preventDefault();


  //   if (e.type === 'click') {
  //    this.props.history.push('/quiz/' + topic_id)
  //   } else if (e.type === 'contextmenu') {
  //     window.open("/quiz/"+ topic_id, "_blank");
  //   } else{
  //     this.props.history.push('/quiz/' + topic_id)
  //   }
  
    
  // }

  // handleViewQuiz = (e,topic_id) => {
  //   e.preventDefault();

  //    if (e.type === 'click') {
  //    this.props.history.push('/quiz-result/' + topic_id)
  //   } else if (e.type === 'contextmenu') {
  //     window.open("/quiz-result/"+ topic_id, "_blank");
  //   } else{
  //     this.props.history.push('/quiz-result/' + topic_id)
  //   }
    
  // }

  handleFilter = (e) => {
    console.log(e.target.value)
     this.setState({filter: e.target.value}, async() => await this.renderList());
    
  };

  strip = (html) =>{
   let doc = new DOMParser().parseFromString(html, 'text/html');
   return doc.body.textContent || "";
}
  

  renderList = () => {
   
    var filteredData = this.props.testInfo;
    var inputValue = this.state.value;

    console.log(typeof(this.state.filter), filteredData);

    if(this.state.filter && filteredData && filteredData.length>0){
      filteredData = filteredData.filter((quiz)=> quiz.quiz_attempt_status === Number(this.state.filter))
    }

    console.log(this.state.filter, filteredData);


    if(inputValue && filteredData && filteredData.length>0){

         filteredData =  filteredData.filter(quiz => {

            return quiz.quiz_title.toLowerCase().includes(inputValue.toLowerCase()) || this.strip(quiz.quiz_title).replace(/&.*;/g,'').toLowerCase().includes(inputValue.toLowerCase())
         })
      }

  

   // return filteredData && filteredData.length>0 ?
   //   filteredData.map((topic, i) =>
   //          <div className="col-lg-4 col-md-12 stretch-card" key={i}>
   //            <div className="card">
   //                <div className="card-body ck-editor">
   //                    <h4 className={randomClass() + " card-title brdrbtm-lblue pb-3 mb-3"}>{(titleCase(add3dots(topic.quiz_title, 30)))}</h4>
                      
   //                      <div style={{ overflow: "auto", height:"60px" }}>
   //                        {topic.description ? ReactHtmlParser(add3dots(topic.description, 70)) : ""}
   //                      </div>
                      
   //                    <div className="cat-info-list">
   //                        <h5 className="card-title">Test Info</h5>
   //                        <ul className="list-style-none pl-3 mb-3">
   //                            <li>
   //                                <a href="#!" style={{pointerEvents: "none"}}>
   //                                    <FontAwesomeIcon icon={faQuestion} className="text-info"/>{' '}
   //                                    No Of Question - {topic.quiz_questions_count}
   //                                </a>
   //                            </li>
   //                            <li>
   //                                <a href="#!" style={{pointerEvents: "none"}}>
   //                                    <FontAwesomeIcon icon={faCheck} className="text-info"/>{ ' '}
   //                                     Total Marks - {topic.quiz_marks}
   //                                </a>
   //                            </li>
   //                            <li>
   //                                <a href="#!" style={{pointerEvents: "none"}}>
   //                                    <FontAwesomeIcon icon={faCheck} className="text-info"/>{ ' '}
   //                                     Attempt Status - { topic.quiz_attempt_status === 1 ? "Attempted" : "Not Attempt" }
   //                                </a>
   //                            </li>
   //                        </ul>
   //                    </div>
   //                    { topic.quiz_attempt_status === 1 ?
   //                      <a href="#!" onClick={(e)=>this.handleViewQuiz(e,topic.id)} onContextMenu={(e)=>this.handleViewQuiz(e,topic.id)} className="btn waves-effect waves-light btn-rounded btn-primary pl-4 pr-4">View Result</a>
   //                      :
   //                      <a href="#!" onClick={(e)=>this.handleView(e,topic.id)} onContextMenu={(e)=>this.handleView(e,topic.id)}  className="btn waves-effect waves-light btn-rounded btn-primary pl-4 pr-4">Take Test</a>
   //                    }
                      
   //                </div>
   //            </div>
   //        </div>   
   //    ) 
   //   :  <div className="col-lg-12 col-md-12 stretch-card" data-aos="fade-up" data-aos-delay="100">
   //            <div className="card">
   //                <div className="card-body">
   //                <p>No Record Found</p>
   //                </div>
   //            </div>
   //      </div>  

   this.setState({data:filteredData});


       
  }

  render() {

    let auth = this.state.isAuth;



   
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    return (

      <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
        data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">

      <NavOnDashboard user={this.props.userDetail}/>
        <Sidebar />
      <div className="page-wrapper">
        <div className="page-m-height">
          <div className="page-breadcrumb">
              <div className="row">
                  <div className="col-12 col-sm-5 align-self-center mb-3 mb-sm-0">
                      <h3 className="page-title text-truncate text-dark font-weight-medium mb-1"> Practice Test Evaluation</h3>                        
                  </div>

                  <div className="col-12 col-sm-4 align-self-center search-form  mb-3 mb-md-0">
                      <div className="sort-by">
                        <label className="mb-0">Filter by</label>
                        <div className="customize-input custom-width-mobile">
                            <select className="form-control bg-white custom-radius custom-shadow" onChange={this.handleFilter} value={this.state.filter}>
                                <option value="">Select here...</option>
                                <option value="1">Attempted</option>
                                <option value="0">Not Attempted</option>
                            </select>
                        </div>
                      </div>
                  </div>
                  <div className="col-12 col-sm-3 align-self-center search-form mb-3 mb-md-0">
                      <div className="customize-input">
                          <input className="form-control custom-shadow custom-radius border-0 bg-white"
                              type="search" placeholder="Search" aria-label="Search" onChange={this.handleChange} value={this.state.value}/>
                          <i className="form-control-icon fas fa-search" ></i> 
                      </div>
                  </div>
              </div>
          </div>
          <div className="container-fluid">
              
                   {this.state.data.length > 0 ? (
                        <Pagination
                          data={this.state.data}
                          RenderComponent={Post}
                          title="Posts"
                          pageLimit={3}
                          dataLimit={9}
                          history={this.props.history}
                        />
                    ) : (
                     <div className="col-lg-12 col-md-12 stretch-card" data-aos="fade-up" data-aos-delay="100">
                          <div className="card">
                              <div className="card-body">
                              <p>No Record Found</p>
                              </div>
                          </div>
                    </div> 
                    )}
              
          </div>
        </div>
        <FooterOnDashboard />
    </div>
    </div>
    );
  }
}

const mapStateToProps = state => {
    return {
        isLoading: state.service.isLoading,
        testInfo: state.service.praticeTestResponse ? state.service.praticeTestResponse.data : null,
        userDetail: state.service.userDetailResponse ? state.service.userDetailResponse.data : '',
        imagePath: state.service.subCategoryResponse.path,
    };
};

export default connect(mapStateToProps, { setLoader, fetchPracticeTest, userInfo })(PracticeTest);
