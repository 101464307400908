import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  fetchMyTopic, deleteMyTopic } from "../../actions";
import { LoaderScreen } from "../includes";
import  {add3dots}  from "../../utils/general";
import ReactHtmlParser from 'react-html-parser';
import { withRouter } from "react-router";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


const RunningCourseList = (props) => {

	 const dispatch = useDispatch();

	  useEffect(() => {
	    if(props.mount){
	      dispatch(fetchMyTopic());
	    }
	      
	   }, [dispatch, props.mount]);

	  const myTopicResponse = useSelector((state) =>
	        state.service.myTopicResponse ? state.service.myTopicResponse.data : '',
	    );

	  const handleView = (topic_id) => {
	    props.history.push('/topic/' + topic_id)
	  }

	  const handleRemove = (mytopic_id) => {
    
	    confirmAlert({
	      title: 'Delete',
	      message: 'Are you sure want to Delete this topic?',
	      buttons: [
	        {
	          label: 'Yes',
	          onClick: () => dispatch(deleteMyTopic(mytopic_id))
	            
	        },
	        {
	          label: 'No',
	          onClick: () => ''
	        }
	      ]
	    });
	  }


	  const renderList = (myTopic) => {
	      const  { search } = props;
	      if(search && myTopic && myTopic.length>0){

	         myTopic =  myTopic.filter(mytopic => {
	          
	            return mytopic.topic.title.toLowerCase().includes(search.toLowerCase()) 
	         })
	      }


	      return myTopic && myTopic.length>0 ? 
	         myTopic.map((mytopic, i) => (
	            <tr key={i}>                                                
	                <td>{mytopic.topic.title}</td>
	                <td>{mytopic.topic.chapter_count}</td>
	                <td>{ReactHtmlParser(add3dots(mytopic.topic.description, 220))}</td>
	                <td>
	                  <button className="btn btn-primary btn-sm mt-1" onClick={() => handleView(mytopic.topic.slug)} ><i className="fa fa-eye" aria-hidden="true"></i></button>{' '} 
	                  <button className="btn btn-danger btn-sm mt-1" onClick={() => handleRemove(mytopic.topic_id)} ><i className="fa fa-trash" aria-hidden="true"></i></button>
	                 </td>
	            </tr>
	            ))
	          :
	            <tr>                                                
	                <td colSpan="4" className="text-center">No record found</td>
	            </tr>
	              
	  }



	  if(!myTopicResponse){
	  	return <LoaderScreen />
	  }

	  return (
	  	<div className="card">
            <div className="card-body">
                <h4 className="card-title brdrbtm-lblue pb-3 mb-3">Running Courses</h4>                                
                <div className="table-responsive cource-div">
                    <table className="table mb-0">
                        <thead className="bg-info text-white">
                            <tr>
                                <th scope="col" style={{ width:'20%'}}>Topic Name</th>
                                <th scope="col" style={{ width:'10%'}}>No. Of Chapters</th>
                                <th scope="col" style={{ width:'50%'}}>Description</th>
                                <th scope="col" style={{ width:'20%'}}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        { renderList(myTopicResponse)}
                        
                        </tbody>
                    </table>
                </div>
            </div>
         </div>)

}

 

export default withRouter((RunningCourseList));