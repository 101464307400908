export const MENU_LIST = "MENU_LIST";
export const SET_LOADER = "SET_LOADER";
export const FETCH_CMS_CONTENT = "FETCH_CMS_CONTENT";
export const FETCH_FAQ = "FETCH_FAQ";
export const CONTACT_FORM = "CONTACT_FORM";
export const CONTACT_US = "CONTACT_US";

//Authentication Actions 
export const CATCH_ERROR = "CATCH_ERROR";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";


export const FORGET_PASSWORD_FORM = "FORGET_PASSWORD_FORM";
export const FORGOT_PSWD = "FORGOT_PSWD";

export const RESET_PSWD = "RESET_PSWD";

export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const VERIFY_EMAIL_SEND = "VERIFY_EMAIL_SEND";
export const USER_LOGOUT = "USER_LOGOUT";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

// App Api Actions
export const USER_INFO = "USER_INFO";
export const UPDATE_INFO = "UPDATE_INFO";
export const FETCH_SUB_CATEGORY = "FETCH_SUB_CATEGORY";
export const FETCH_TOPIC = "FETCH_TOPIC";
export const FETCH_TOPIC_INFO = "FETCH_TOPIC_INFO";
export const FETCH_CATEGORY = "FETCH_CATEGORY";
export const FETCH_ALL_TOPIC = "FETCH_ALL_TOPIC";

// My Topic Action
export const FETCH_MY_TOPIC = "FETCH_MY_TOPIC";
export const DELETE_MY_TOPIC = "DELETE_MY_TOPIC";
export const ADD_MY_TOPIC = "ADD_MY_TOPIC";

// Requested Session Action
export const FETCH_REQUESTED_SESSION = "FETCH_REQUESTED_SESSION";
export const ADD_SESSION_FORM = "ADD_SESSION_FORM";
export const ADD_SESSION_REQUEST = "ADD_SESSION_REQUEST"; 
export const LIVE_SESSION_EVENT = "LIVE_SESSION_EVENT"; 


// GET ALL Notification
export const FETCH_NOTIFICATION = "FETCH_NOTIFICATION";
export const MARK_READ_NOTIFICATION = "MARK_READ_NOTIFICATION";
export const DASHBOARD_DATA = "DASHBOARD_DATA";

export const FETCH_QUIZ_INFO = "FETCH_QUIZ_INFO";
export const INITIALTESTDATA = "INITIALTESTDATA";
export const UPDATE_TEST_ATTEMPTS = "UPDATE_TEST_ATTEMPTS";

export const GET_QUIZ_RESULT = "GET_QUIZ_RESULT";



export const FETCH_PRACTICE_TEST = "FETCH_PRACTICE_TEST";
export const FETCH_ALL_MEDIUM = "FETCH_ALL_MEDIUM";
export const UPDATE_MEDIUM = "UPDATE_MEDIUM";

export const SESSION_DETAIL = "SESSION_DETAIL";

export const FETCH_WALLET = "FETCH_WALLET";
export const FETCH_PLANS = "FETCH_PLANS";
export const MAKE_SUBSCRIPTION = "MAKE_SUBSCRIPTION";

export const FETCH_MY_SUBSCRIPTION = "FETCH_MY_SUBSCRIPTION";

export const REQUESTED_SESSION_DETAIL = "REQUESTED_SESSION_DETAIL";

export const PAY_SESSION = "PAY_SESSION";
export const EVENT = "EVENT";
export const EVENT_DETAIL = "EVENT_DETAIL";

export const USER_IMAGE_BY_ID = "USER_IMAGE_BY_ID";

export const USER_SUB_CAT = "USER_SUB_CAT";