import React, { Component } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
//import { PATHS } from "../../constants";
import { Helmet } from "react-helmet";


import "../../App.css";
import { LoaderScreen, NavOnIndex, FooterOnIndex } from "../includes";
import { userInfo, setLoader } from "../../actions";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import Contact from "./Contact.component";

/*
 TYPES
*/

type Props = {
  history: Object,
  langList: Object,
  userDetail: Function,
  setLoader: Function,
  isLoading: boolean
};

class Home extends Component<Props> {

  constructor(props){
       super(props)
       this.state = {
       
       }
      const canonical = window.location.href;
      
       //creates a reference for your element to use
       this.myDivToFocus = React.createRef();  
       //document.getElementById('canonical').setAttribute('href',window.location.href);  
    }

  componentDidMount() {
    AOS.init();

    if (!localStorage.getItem("access_token")) {
      //this.props.history.push(PATHS.LOGIN)
      localStorage.clear();
    }else{
    
        this.props.userInfo().then(result => {
            console.log(this.props.userDetail);
            if (this.props.userDetail) {
                this.props.setLoader(false);
            }
        })
    }
     
   
  }

  

  handleContactFocus = (event) => {
        event.preventDefault();
        //.current is verification that your element has rendered
        if(this.myDivToFocus.current){
      
            this.myDivToFocus.current.scrollIntoView({ 
               behavior: "smooth", 
               block: "nearest"
            })
        }
    }

  render() {
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    return (
      <div className="main-wrapper white-bg">
        <Helmet>
            <meta charSet="utf-8" />
                <title>Online English Learning Classes | MyEduMaster</title>
                <meta
                    name="description"
                    content="MyEduMaster’s is an online english learning platform with professional faculties. Make yourself proficient in english today and enhance your skills."
                />
                {/* <link rel="canonical" href={window.location.href} /> */}
        </Helmet>
       
        <div className="top-wrapper">  
          <NavOnIndex handleContactFocus={this.handleContactFocus} user={this.props.userDetail}/>          
        
          <div className="banner pt-banner">
              <div className="container">
                  <div className="row">
                      <div className="col-md-6 pr-4 pr-sm-5 pt-2 pt-sm-4 pt-lg-5 mt-2 mt-lg-3" data-aos="fade-up" data-aos-duration="3000" data-aos-delay="100">
                          
                          <h1>“BELIEVE IT OR NOT YOU CAN LEARN ENGLISH”</h1>
                          <p style={{ textAlign: "justify" }}>
                          MyEduMaster is an online learning platform. Improving English communication skills will not need to rely on translations or subtitles anymore to enjoy your favourite books, songs and films. Although learning English can be challenging and time-consuming, we ensure that it is also very valuable to learn and can create many opportunities in the future.
                          </p>
                         {/* <a href="/#" className="btn waves-effect waves-light btn-outline-primary pr-4 pl-4">Get Started</a> */}

                      </div>
                      <div className="col-md-6 d-none d-md-block pt-4 mt-4 mt-lg-5">
                        <div className="banner-pic">
                          <img src="/assets/images/banner-img.png" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="200" alt="img" />
                        </div>                          
                      </div>
                  </div>
              </div>
          </div>
         
        </div>        
        
        <div className="four-box mt-n0 mt-sm-n5">
            <div className="container fbox-bg py-4 py-sm-5">
                <div className="row">
                    <div className="col-6 col-md-3 text-center">
                          <img src="/assets/images/online-courses.png" alt="Online Courses" className="img-fluid" data-aos="flip-up" data-aos-delay="100" data-aos-duration="3000" />
                    </div>
                    <div className="col-6 col-md-3 text-center pt-4">
                          <img src="/assets/images/expert-instrucation.png" alt="Expert Instrucation" className="img-fluid" data-aos="flip-up" data-aos-delay="200" data-aos-duration="3000" />
                    </div>
                    <div className="col-6 col-md-3 text-center pt-3">
                          <img src="/assets/images/AI-chatbot.png" alt="AI Chatbot" className="img-fluid" data-aos="flip-up" data-aos-delay="300" data-aos-duration="3000" />
                    </div>
                    <div className="col-6 col-md-3 text-center pt-5">
                          <img src="/assets/images/buy-membership.png" alt="Buy Membership" className="img-fluid" data-aos="flip-up" data-aos-delay="400" data-aos-duration="3000" />
                    </div>
                </div>
            </div>
        </div>

        <div className="find-perfect py-3 py-sm-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 mt-5 pl-5 pl-sm-2 pr-5 pr-sm-2">
                        <img src="/assets/images/find-perfect.svg" alt="Find Perfect" className="img-fluid" data-aos="zoom-out-right" data-aos-duration="1000" data-aos-delay="200" />
                    </div>
                    <div className="col-md-7 offset-md-1 d-flex align-items-center pt-4" data-aos="fade-up" data-aos-duration="3000" data-aos-delay="100">
                        <div className="fp-content">
                            <h2>
                                Find the perfect Faculty with MyEduMaster
                            </h2>
                            <p style={{ textAlign: "justify" }}>
                               We have well qualified, experienced, excellent faculty with brilliant minds. We have committed to their work. For us it is a matter of pride and responsibility. We have a group of individuals who are passionate and can contribute towards better learning performance of students. You can always be assured that students at MyEduMaster are in good hands.
                            </p>
                          {/*  <ul className="blue-dot-list">
                                <li><i className="fas fa-circle"></i>Lorem ipsum dolor sit amet, consectetur </li>
                                <li><i className="fas fa-circle"></i>Adipiscing elit, sed do eiusmod tempor </li>
                                <li><i className="fas fa-circle"></i>Incididunt ut labore et dolore magna aliqua. </li> 
                                <li><i className="fas fa-circle"></i>Ut enim ad minim veniam, quis nostrud exercitation </li>
                                <li><i className="fas fa-circle"></i>Ullamco laboris nisi ut aliquip ex ea commodo consequat </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="our-values py-2 py-sm-4 py-lg-5 my-4 my-lg-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-4 d-flex align-items-center">
                        <div className="ov-content" data-aos="fade-up" data-aos-duration="3000" data-aos-delay="100">
                            <h2>
                                Our Core Values
                            </h2>
                            <strong className="d-flex mb-2">
                                Student Centric, Ethical, Dignified, Professional, Integrity, Transparency, Diversity
                            </strong>
                             {/*<p className="mb-3">
                                Accusantium quam, aliquam ultricies eget tempor id, aliquam eget nibh et. Maecen aliquam, risus at semper. Proin iaculis purus consequat sem cure digni ssim. Donec porttitora entum.
                            </p>
                            <a href="/#" className="btn waves-effect waves-light btn-outline-primary">View More.</a> */}
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-8 pl-lg-4">
                        <div className="row">
                            <div className="col-md-6 pt-5">
                                <div className="ov-card p-3 p-sm-5 brdr-radius-15 green-bg mb-4 text-center" data-aos="flip-left" data-aos-delay="100" data-aos-duration="2000">
                                    <i className="fas fa-headset"></i>
                                    <h3>
                                        AI Chatbox
                                    </h3>
                                   {/* <p>
                                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                                    </p> */}
                                </div>
                                <div className="ov-card p-3 p-sm-5 brdr-radius-15 light-purple-bg mb-4 text-center" data-aos="flip-right" data-aos-delay="200" data-aos-duration="3000">
                                    <i className="fas fa-signature"></i>
                                    <h3>
                                        Free Courses
                                    </h3>
                                    <p>
                                        Basic grammar
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="ov-card p-3 p-sm-5 brdr-radius-15 light-blue-bg mb-4 text-center" data-aos="flip-left" data-aos-delay="300" data-aos-duration="4000">
                                    <i className="fas fa-signature"></i>
                                    <h3>
                                        Premium Courses
                                    </h3>
                                    <p>
                                        IELTS, TOFEL, Spoken English, Public Speaking, Story telling, Essay writing, Drama and speech, Debating, Foreign Languages, GRE, GMAT, UPSC and MPSC all exams, Vedic maths, Painting and drawing, Photography.
                                    </p>
                                </div>
                                <div className="ov-card p-3 p-sm-5 brdr-radius-15 purple-bg mb-4 text-center" data-aos="flip-right" data-aos-delay="400" data-aos-duration="5000">
                                    <i className="fas fa-user-tie"></i>
                                    <h3>
                                        Personal Development
                                    </h3>
                                    <p>
                                        Public speaking, Drama and speech, spoken English, Debating.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        <div className="believe-it dark-blue-bg pt-4 pt-sm-5 pb-0">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 mb-3 mb-sm-5">
                        <div className="white-heading" data-aos="fade-up" data-aos-duration="3000" data-aos-delay="100">
                            <h2>
                                Believe it or not you can learn English!
                            </h2>
                           
                        </div>
                    </div>

                    
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6 col-lg-4 stretch-card">
                                <div className="card brdr-radius-15 p-4 text-center align-items-center" data-aos="flip-left" data-aos-delay="100" data-aos-duration="2000">
                                    <i className="fas fa-book green-text"></i>
                                    <h2 className="green-text">English Grammar</h2>
                                    <p>
                                       English grammar is the key to speaking English fluently and confidently which will avoid errors. This includes words, phrases, clauses and many more…
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 stretch-card">
                                <div className="card brdr-radius-15 p-4 text-center align-items-center" data-aos="flip-right" data-aos-delay="200" data-aos-duration="2000">
                                    <i className="fas fa-book-reader purple-text"></i>
                                    <h2 className="purple-text">English Lessons</h2>
                                    <p>from Grade 1 to Grade 12
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 stretch-card">
                                <div className="card brdr-radius-15 p-4 text-center align-items-center" data-aos="flip-left" data-aos-delay="300" data-aos-duration="2000">
                                    <i className="fas fa-user-graduate light-purple-text"></i>
                                    <h2 className="light-purple-text">English Pronunciation</h2>
                                    <p>Proper pronunciation is the key to English Language. Increase Self-confidence, speech-monitoring.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 stretch-card">
                                <div className="card brdr-radius-15 p-4 text-center align-items-center" data-aos="flip-right" data-aos-delay="400" data-aos-duration="2000">
                                    <i className="fas fa-question light-blue-text"></i>
                                    <h2 className="light-blue-text">English Quizzes</h2>
                                    <p>
concentration, identify gaps in knowledge, build confidence and help children retain information                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 stretch-card">
                                <div className="card brdr-radius-15 p-4 text-center align-items-center" data-aos="flip-left" data-aos-delay="500" data-aos-duration="2000">
                                    <i className="fas fa-book-open yellow-text"></i>
                                    <h2 className="yellow-text">Academic co-curricular activities</h2>
                                    <p>
Co-curricular activities for development of various domains of the brain and personality such as storytelling, debating, Public Speaking, Dramatics, etc..                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 stretch-card">
                                <div className="card brdr-radius-15 p-4 text-center align-items-center" data-aos="flip-right" data-aos-delay="600" data-aos-duration="2000">
                                    <i className="fas fa-id-card dark-blue-text"></i>
                                    <h2 className="dark-blue-text">Competitive Exam English</h2>
                                    <p>
Enhance the skill to understanding the concept which is required in a broader context when we apply to exams like IELTS,TOEFL and many more..                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="dropbg">
            <img src="/assets/images/blue-drop-bg.svg" alt="Drop" className="img-fluid" />
        </div>
     
        <div className="contact-us pt-0 pb-5 mt-0 mt-sm-n5" ref={this.myDivToFocus}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 mb-4">
                        <div className="black-heading" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                            <h2>
                                Contact Us Now
                            </h2>
                        </div>
                    </div>
                    <Contact />
                </div>
            </div>
        </div>
     
        <FooterOnIndex />
        
    </div>
    );
  }
}

const mapStateToProps = state => ({
  langList: state.service.langList,
  isLoading: state.service.isLoading,
  userDetail: state.service.userDetailResponse ? state.service.userDetailResponse.data : '',
});
export default connect(mapStateToProps, { setLoader, userInfo })(Home);
