import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { Events, EventApiData } from "./EventData";
import "./Calendar.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { liveSessionEvent } from "../../actions";


//BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));
const localizer = momentLocalizer(moment);



const MyCalendar = (props) => {
  const [alerts, setAlert] = useState(null);
  const [calevents, setCalEvents] = useState(Events);

   const dispatch = useDispatch();

  useEffect(() => {
    if(props.mount){
      dispatch(liveSessionEvent());
    }
      
   }, [dispatch, props.mount]);

  const eventDetail = useSelector((state) =>
       state.service.sessionEventResponse ? state.service.sessionEventResponse.data : '',
    );


  //console.log('hello',props.eventDetail);


//const eventDetail = props.eventDetail;

  const alertselectedEvent = (event) => {
    console.log(props);
    // alert(
    //   "\n" +
    //     event.title +
    //     " \n\nStart Time: " +
    //     event.start +
    //     " \nEnd Time: " +
    //     event.end
    // );
      setAlert(
        <div>
          <SweetAlert title={event.title} 
            confirmBtnText="View"
            onConfirm={(e) => hideAlert(event)} 

            >
              Faculty Name - {event.faculty_name ? event.faculty_name : '' } <br />     
              Start time - {moment(event.start).format('MMMM Do YYYY, h:mm:ss a') } <br />
              End time - {moment(event.end).format('MMMM Do YYYY, h:mm:ss a') } <br />  
                   
          </SweetAlert>
        </div>
    );
  };

  // const addNewEventAlert = (slotInfo) => {
  //   setAlert(
  //     <div>
  //       <SweetAlert
  //         input
  //         showCancel
  //         style={{}}
  //         title="Create New Event"
  //         placeHolder="Write Event Title"
  //         onConfirm={(e) => addNewEvent(e, slotInfo)}
  //         onCancel={() => hideAlert()}
  //         cancelBtnBsStyle="danger"
  //         confirmBtnBsStyle="info"
  //       ></SweetAlert>
  //     </div>
  //   );
  // };

  // const addNewEvent = (e, slotInfo) => {
  //   var newEvents = calevents;
  //   newEvents.push({
  //     title: e,
  //     start: slotInfo.start,
  //     end: slotInfo.end,
  //   });

  //   setAlert(null);
  //   setCalEvents(newEvents);
  // };

  const hideAlert = (event) => {
    //e.preventDefault();

   
    setAlert(null);
    props.handleView(event)
  };

  const convertDate = (date) => {
    return moment(date).toDate();
  }

  const eventData = (event) => {

    const evs  = event ? event.map(e => ({...e, start:convertDate(e.start), end:convertDate(e.end)})) : [];
    
    return evs;
    
  }

  const eventColors = (event) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  };

      

  return (
    <div>
     
      {alerts}
   
        <Calendar
          selectable
          events={eventData(eventDetail)}
          defaultView="month"
          scrollToTime={new Date(1970, 1, 1, 6)}
          defaultDate={new Date()}
          localizer={localizer}
          onSelectEvent={(event) => alertselectedEvent(event)}
      //    onSelectSlot={(slotInfo) => addNewEventAlert(slotInfo)}
          eventPropGetter={(event) => eventColors(event)}
          style={{ height: props.height?props.height:"60vh" }}
        />

    </div>
  );
};

export default MyCalendar;
