import React from "react";
import NavOnIndex from "../includes/NavOnIndex";
import FooterOnIndex from "../includes/FooterOnIndex";

const Error404 = () => (
  <div className="main-wrapper white-bg">
       
        <div className="top-wrapper-new not-found-page">
  <NavOnIndex />
  </div>
  <div className="four-box mt-n0 mt-sm-n5">
<div class="error_page_inner">
            <span class="error_img"></span>
            <div class="error_page_text">

            <i class="far fa-frown"></i>
                <h2>Sorry.</h2>

                <h3>We cannot find the page you’re looking for...<span>Maybe you mistyped it or we’ve moved it? Who knows!</span></h3>
               
            </div>
        </div>
  </div>
  <FooterOnIndex />
  </div>
);

export default Error404;
