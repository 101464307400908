import React, { useEffect } from "react";
import { withRouter } from "react-router";
import {  NavLink } from "react-router-dom";
import { PATHS, VIDEO_BASE_PATH } from "../../constants";
import { useSelector } from "react-redux";


const LandingScreen = (props) => { 

	 const category = useSelector(state => state.service.categoryResponse.data);
	 const path = useSelector(state => state.service.categoryResponse.path);

	const basePath = path ? path.category : VIDEO_BASE_PATH+'/uploads/category/';

	const medium = localStorage.getItem('userMediumId') ;


      

	const handleView = (e, param) =>  {
        e.preventDefault();
       if(param === 'english-1-to-12'){
          if (!isNaN(medium)) {
          	props.history.push(PATHS.UPDATE_MEDIUM);
       		return;
	      } 
       	
       }

       	props.history.push('/library/' + param);
         
    }    

	return (
		 		<div className="container-fluid">
                    <div className="row">
                    {category && category.map((cat, i)=>(
                        <div className="col-md-6 col-xl-6 stretch-card" key={cat.id}>
                            <div className="card box-h ge-bg" style={{ 
						      backgroundImage: `url(${basePath}${cat.image})` 
						    }}>
                                <a href="javascript:void(0)" onClick={(e)=>handleView(e, cat.slug)}>
                                    <div className="bh-overlay">
                                        <h2>                                            
                                            {cat.name}                                          
                                        </h2>
                                    </div>
                                </a>
                            </div>
                        </div>
                     ))} 
                        {/* <div className="col-md-6 col-xl-6 stretch-card">
                                                    <div className="card box-h aa-bg">
                                                        <a href="javascript:void(0)">
                                                            <div className="bh-overlay">
                                                                <h2>                                        
                                                                    Academic co-curricular Activities                                         
                                                                </h2>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-xl-6 stretch-card">
                                                    <div className="card box-h ce-bg">
                                                        <a href="javascript:void(0)">
                                                            <div className="bh-overlay">
                                                                <h2>                                        
                                                                    Competitive Exams                                         
                                                                </h2>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-xl-6 stretch-card">
                                                    <div className="card box-h es-bg">
                                                        <a href="javascript:void(0)">
                                                            <div className="bh-overlay">
                                                                <h2>
                                                                    English Spoken                                             
                                                                </h2>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div> */}
                    </div>
                </div>
        )
}


export default withRouter((LandingScreen));