import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { PATHS } from "../../constants";


import "../../App.css";
import { LoaderScreen, NavOnIndex, FooterOnIndex, NavOnDashboard, FooterOnDashboard, Sidebar } from "../includes";
import {  setLoader, fetchSubCategory, fetchTopic, userInfo, fetchAllMedium, fetchCategory, updateUserSubCat } from "../../actions";
 
import ReactHtmlParser from 'react-html-parser';
//import {IMAGE_BASE_PATH} from "../../constants";
import  { makeTitle, add3dots, titleCase}  from "../../utils/general"
import Truncate from "react-truncate-html";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import { Helmet } from 'react-helmet';


// import ReactPaginate from "react-paginate";
/*
 TYPES
*/

type Props = {
  history: Object,
  fetchTopic: Function,
  fetchSubCategory: Function,
  setLoader: Function,
  isLoading: boolean
};

const authImgStyle = {
  height: '134px',
  width: '360px',
};

const imgStyle = {
      height: '158px',
    width: '348px',
}

class Topic extends Component<Props> {

  constructor(props){
       super(props)
       this.state = {
          slug:null,
          value:'',
          isAuth : false,
          filter:'',
         categoryName: '', 
         titleCms: "",
         descriptionCms: ""
       }
      //document.getElementById('canonical').setAttribute('href', window.location.href); 
      
    }

  componentDidMount() {
    
  
    this.props.setLoader(true);
    
     let slug = this.props.match.params.slug;
     let category = this.props.categoryResponse;
     let catName = category.filter(ele => ele.slug === slug);
     let categoryName : '';
     
     if(catName){
        categoryName = catName[0].name;
     }
    
    if (slug == 'academic-co-curricular-activities'){
      this.setState({ titleCms: "Extracurricular Activities | MyEduMaster" });
      this.setState({ descriptionCms: "MyEduMaster facilitates online extracurricular activities for youngsters to interact each other in a formal manner." }); 

    } else if (slug == 'competitive-exams'){
      this.setState({ titleCms: "General Competitive Exams English | MyEduMaster" });
      this.setState({ descriptionCms: "MyEduMaster provides competitive tests with general english questions and answers appears in reputable competitive exams." }); 

    } else if (slug == 'spoken-english'){
      this.setState({ titleCms: "Spoken English Learning Course | MyEduMaster" });
      this.setState({ descriptionCms: "Learn online english speaking course with MyEduMaster to improve your english communication skills. Get the english course today." }); 

    } else if (slug == 'english-1-to-12') {
      this.setState({ titleCms: "English Syllabus For class 1st to 12th | MyEduMaster" });
      this.setState({ descriptionCms: "MyEduMaster provide a complete english syllabus from class 1 to class 12. Sign up now and go through the syllabus and plan your study accordingly." });
    }
    

    if(slug === 'english-1-to-12'){
      let subCat = localStorage.getItem('userSubCat') || '';
      this.setState({value:subCat});
    }else{
      this.setState({value:''});
    }

    
     const user = localStorage.getItem('isAuth') ? true : false;
     const medium_id = localStorage.getItem('userMediumId') ? localStorage.getItem('userMediumId') : null;
    
    if(user && !medium_id && slug === 'english-1-to-12'){
      this.props.history.push(PATHS.UPDATE_MEDIUM);
    }

    let formValues = {
      slug: slug,
    }

    if(user ){
        this.props.userInfo().then(result => {
            if (this.props.userDetail) {
                this.props.setLoader(false);
            }
        })
    }

    if(user && slug === 'english-1-to-12'){
        formValues.medium_id = medium_id;
    }
      
    this.props.fetchTopic(formValues)
    this.props.fetchSubCategory(slug);
    this.props.fetchAllMedium();
    
    this.setState({ isAuth:user, categoryName:categoryName,slug:slug });

  }

   componentDidUpdate(prevProps) {

      if (prevProps.match.params.slug !== this.props.match.params.slug) {
        if (this.props.match.params.slug == 'academic-co-curricular-activities') {
          this.setState({ titleCms: "Extracurricular Activities | MyEduMaster" });
          this.setState({ descriptionCms: "MyEduMaster facilitates online extracurricular activities for youngsters to interact each other in a formal manner." });

        } else if (this.props.match.params.slug == 'competitive-exams') {
          this.setState({ titleCms: "General Competitive Exams English | MyEduMaster" });
          this.setState({ descriptionCms: "MyEduMaster provides competitive tests with general english questions and answers appears in reputable competitive exams." });

        } else if (this.props.match.params.slug == 'spoken-english') {

          this.setState({ titleCms: "Spoken English Learning Course | MyEduMaster" });
          this.setState({ descriptionCms: "Learn online english speaking course with MyEduMaster to improve your english communication skills. Get the english course today." });

        }
        else if (this.props.match.params.slug == 'english-1-to-12') {
          this.setState({ titleCms: "English Syllabus For class 1st to 12th | MyEduMaster" });
          this.setState({ descriptionCms: "MyEduMaster provide a complete english syllabus from class 1 to class 12. Sign up now and go through the syllabus and plan your study accordingly." });

        }

        let slug = this.props.match.params.slug;
       
        if(slug === 'english-1-to-12'){
          let subCat = localStorage.getItem('userSubCat') || '';
          this.setState({value:subCat});
        }else{
          this.setState({value:''});
        }


         
         let formValues = {
           slug: slug,
         }

         const user = localStorage.getItem('isAuth') ? true : false;
         const medium_id = localStorage.getItem('userMediumId') ? localStorage.getItem('userMediumId') : null;
         
        if(user && !medium_id && slug === 'english-1-to-12'){
          this.props.history.push(PATHS.UPDATE_MEDIUM);
        }


         if(user && slug === 'english-1-to-12'){
          formValues.medium_id = medium_id;
        }

        this.props.setLoader(true);
        let category = this.props.categoryResponse;
        let categoryName : '';
        let catName = category.filter(ele => ele.slug === slug);
         if(catName){
            categoryName = catName[0].name;
         }
        this.setState({slug:slug, categoryName:categoryName});
        this.props.fetchTopic(formValues);
        this.props.fetchSubCategory(slug);

      }
   }

  handleChange = (event) => {
    this.setState({value: event.target.value}, (val) => {
          if(this.props.match.params.slug == 'english-1-to-12'){
            localStorage.setItem('userSubCat', this.state.value);

          }
          let user = localStorage.getItem('isAuth') ? true : false;
          if(user){
            this.props.updateUserSubCat({subcat:this.state.value})
          }
       });
   // this.setState({value: event.target.value});
  }

  handleFilter = (event) => {


   this.setState({filter: event.target.value});

     let slug = this.props.match.params.slug;
     let formValues = {
       slug: slug,
       medium_id:event.target.value
     }
     this.props.fetchTopic(formValues);
  }

   handleView = (topic_id) => {
  //  e.preventDefault();
    this.props.history.push('/topic/' + topic_id)
  }



  renderList = () => {
    var filter = this.state.value;
  
    var filteredData = this.props.topicsInfo;

    if(filter && filteredData && filteredData.length>0){
       filteredData = filteredData.filter((item) => {
          return (item.subcategory_id === Number(filter)) 
      });
    }


   return filteredData && filteredData.length>0 ?
    filteredData.map((topic, i) =>
      <div className="col-lg-4 col-md-12 stretch-card" data-aos="fade-up" data-aos-delay="100" key={topic.id}>
              <div className="card">
              <div className="ck-banner">
                  <img className="card-img-top img-fluid" src={topic && topic.image ? this.props.imagePath.topic + topic.image: "/assets/images/card-1.jpg"} alt="Card" style={this.state.isAuth ? authImgStyle : imgStyle } />
                  </div>
                  <div className="card-body ck-editor">
                      <div className="ct-row">
                          <h4 className="card-title">{titleCase(add3dots(topic.title,20))}</h4>
                      </div>
                      <p>
                        <div className="cat-title-info">
                          {/* ReactHtmlParser(add3dots(topic.description,100)) */}

                          <HTMLEllipsis
                            isClamped={false}
                            unsafeHTML={topic.description}
                            maxLine="2"
                            ellipsis="..."
                            ellipsisHTML="<i>... read more</i>"
                            basedOn="letters"
                          />
                        </div>
                      </p>
                      <div className="cat-info-list">
                          <h5 className="card-title">Topics</h5>
                          <div className="list-scroll">
                            <ul className="list-style-none pl-3 mb-0">
                              {topic.chapters.length>0 ? topic.chapters.map((chapter, index)=>{
                               return (
                               index < 2 ?
                               <li key={index}>
                                   <a href="#!" onClick={(e)=>e.preventDefault()} style={{pointerEvents: "none"}}>
                                        <i className="fa fa-check text-info mr-1" ></i> 
                                        {chapter.title}
                                    </a>
                                </li>
                                : ''
                                )
                              }) :  <li><a href="/#">No topic found</a></li>}
                                
                            </ul>
                          </div>
                      </div>
                      
                      <a href="#!"  onClick={() => {this.handleView(topic.slug)} } className="btn waves-effect waves-light btn-rounded btn-primary pl-4 pr-4">View more</a>
                  </div>
              </div>
          </div> 
     ) 
     :  <div className="col-lg-12 col-md-12 stretch-card" data-aos="fade-up" data-aos-delay="100">
              <div className="card">
                  <div className="card-body">
                  <p>No Record Found</p>
                  </div>
              </div>
        </div>    
       
  }


  render() {


   
    let auth = this.state.isAuth;

   
    if (this.props.isLoading) {
      return <LoaderScreen />;
    }

    return (
      
    auth === false ?
      <div className="main-wrapper white-bg">
          
        <NavOnIndex inner="true"/>
            <div className="innr-banner inn-pt">
              <div className="container pt-4 pb-4">
                  <div className="row">
                      <div className="col-md-10">
                          <nav aria-label="breadcrumb" data-aos="fade-up" data-aos-delay="100">
                            <ol className="breadcrumb p-0">
                                <li className="breadcrumb-item"><Link to={PATHS.HOME}>Home</Link></li>
                                <li className="breadcrumb-item" aria-current="page">{this.state.categoryName ? titleCase(this.state.categoryName) : ''}</li>
                            </ol>
                        </nav>
                        <div className="mb-2" data-aos="fade-up" data-aos-delay="200">
                            <h1>
                                {this.state.categoryName ? titleCase(this.state.categoryName) : ''}
                            </h1>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
           
        <div className="section py-4 py-sm-5 sp-card">
          <div className="container">
            <div className="row mb-3">
                <div className="col-md-12">
                    <div className="black-heading" data-aos="fade-up" data-aos-delay="300">
                      
                      <div className="row justify-content-end">
                      <div className="col-12 col-md-4 search-form mb-3 mb-md-0">
                          <div className="sort-by">
                              <label className="mb-0">Filter by</label>
                              <div className="customize-input">
                                  <select className="custom-select form-control bg-white custom-radius custom-shadow brdr-dark" onChange={this.handleFilter} value={this.state.filter}>
                                      <option value="">Select Medium...</option>
                                      {this.props.allMedium && this.props.allMedium.map((medium, index) => (
                                          <option key={index} value={medium.id}>{medium.title}</option>
                                      ))}
                                  </select>
                              </div>
                            </div> 
                      </div>
                      <div className="col-12 col-md-4 align-self-center search-form mb-3 mb-md-0">
                         <div className="sort-by">
                            <label className="mb-0">Sort by</label>
                            <div className="customize-input">
                                <select className="custom-select form-control bg-white custom-radius custom-shadow brdr-dark" onChange={this.handleChange} value={this.state.value}>
                                    <option value="">Select Topic...</option>
                                    {this.props.subCategoryResponse && this.props.subCategoryResponse.map((subcat, index) => (
                                        <option key={index} value={subcat.id}>{subcat.name}</option>
                                    ))}
                                </select>
                            </div>
                          </div>
                      </div>
                      </div>
                    </div>
                </div>
            </div>            
             <div className="row">     
                { this.renderList() }
            </div>   
            {/*<ReactPaginate
                                 previousLabel={'previous'}
                                 nextLabel={'next'}
                                 breakLabel={'...'}
                                 breakClassName={'break-me'}
                                 pageCount={10}
                                 marginPagesDisplayed={5}
                                 pageRangeDisplayed={3}
                                 onPageChange={this.handlePageClick}
                                 containerClassName={'pagination'}
                                 activeClassName={'active'}
                                 pageClassName={'page-item'}
                                 pageLinkClassName={'page-link'}
                                 previousClassName={'page-item'}
                                 nextClassName={'page-item'}
                                 previousLinkClassName={'page-link'}
                                 nextLinkClassName={'page-link'}
                               />      */}   
        </div>
    </div>
        <FooterOnIndex />

      </div>
      :
      

      <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
        data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">

          <Helmet>
            <meta charSet="utf-8" />
            <title> {this.state.titleCms} </title>
            <meta name="description" content={this.state.descriptionCms} />
            {/* <link rel="canonical" href={window.location.href} /> */}
          </Helmet>
      <NavOnDashboard user={this.props.userDetail}/>
        <Sidebar />
      <div className="page-wrapper">
        <div className="page-m-height">
          <div className="page-breadcrumb">
              <div className="row">
                  <div className="col-12 col-sm-8 align-self-center mb-3 mb-sm-0">
                      <h3 className="page-title text-truncate text-dark font-weight-medium mb-1"> {this.state.categoryName ? titleCase(this.state.categoryName) : ''}</h3>                        
                  </div>

                  <div className="col-12 col-sm-4 align-self-center search-form">
                      <a className="nav-link p-0" href="#/">
                          <form>
                              <div className="customize-input">
                                 {/* <input className="form-control custom-shadow custom-radius border-0 bg-white"
                                      type="search" placeholder="Search" aria-label="Search" />
                                  <i className="form-control-icon fas fa-search" ></i> */}
                                  <select className="form-control custom-shadow custom-radius border-0 bg-white" onChange={this.handleChange} value={this.state.value}>
                                      <option value="">Select Topic...</option>
                                      {this.props.subCategoryResponse && this.props.subCategoryResponse.map((subcat, index) => (
                                          <option key={index} value={subcat.id}>{subcat.name}</option>
                                      ))}
                                  </select>
                              </div>
                          </form>
                      </a>
                  </div>
              </div>
          </div>
          <div className="container-fluid">
              <div className="row">
                   { this.renderList() }
              </div>
          </div>
        </div>
        <FooterOnDashboard />
    </div>
    </div>
    );
  }
}

const mapStateToProps = state => {
    return {
        subCategoryResponse: state.service.subCategoryResponse ? state.service.subCategoryResponse.data : null,
        isLoading: state.service.isLoading,
        topicsInfo: state.service.response ? state.service.response.data : null,
        userDetail: state.service.userDetailResponse ? state.service.userDetailResponse.data : '',
        imagePath: state.service.subCategoryResponse.path,
        allMedium : state.service.mediumResponse ? state.service.mediumResponse.data : '',
        categoryResponse: state.service.categoryResponse ? state.service.categoryResponse.data : null,
    };
};

export default connect(mapStateToProps, {updateUserSubCat, setLoader, fetchSubCategory, fetchTopic,fetchAllMedium, userInfo, fetchCategory })(Topic);
