
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { PATHS } from "../../constants";
import {  fetchCategory } from "../../actions";
import { withRouter } from "react-router";
import  webLogo  from "../../images/web-logo.svg"
import { useSelector, useDispatch } from "react-redux";

const NavOnIndex = (props) => { 

 
   const [auth, setAuth] = useState(false);
   
   useEffect(() =>   localStorage.getItem('isAuth') ? setAuth(true) : setAuth(false), [] );

    const dispatch = useDispatch();
      useEffect(() => {
        dispatch(fetchCategory());
      }, [dispatch]);

    const handleLogout = (e) => {
      e.preventDefault();
      localStorage.clear();
      props.history.push(PATHS.LOGIN);
      
    }

    const handleView = (e, param) =>  {
        e.preventDefault();
        props.history.push('/library/' + param);
    }    
    

    const category = useSelector(state => state.service.categoryResponse ? state.service.categoryResponse.data : []);
    const imagePath = useSelector(state => state.service.categoryResponse?state.service.categoryResponse.path:[] );

    const refreshDashboard = (e) => {
       e.preventDefault();
        window.location.href = PATHS.DASHBOARD;
    }

    

  return (<header id="header" className={'fixed-top' + (props.inner ? " innr-header" : "")}>
            <div className="container d-flex align-items-center">
              <nav className="navbar navbar-expand-lg navbar-light w-100 p-0">
                <Link className="logo mr-2 mr-md-3" to={PATHS.HOME} data-aos="fade-up" data-aos-delay="300">
                  <img src={webLogo} key={webLogo} alt="" className="img-fluid" />
                </Link>
                <div className='collapse navbar-collapse nav-menu' id="navbarsExample09" data-aos="fade-up" data-aos-delay="300">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                      <Link className="nav-link" to={PATHS.HOME}>Home</Link>
                    </li>
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Our Courses
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      {category && category.map((cat, i)=>(
                               <NavLink to={'/library/' + cat.slug} onClick={(e)=>handleView(e, cat.slug)} className="dropdown-item" key={i}>
                                   {cat.name}
                                </NavLink>
                       ))} 
                        
                       {/* <Link className="dropdown-item" to={PATHS.ACADEMIC_TOPIC}>Academic co-curricular Activities</Link>
                        <Link className="dropdown-item" to={PATHS.COMPETITIVE_TOPIC}>Competitive Exams</Link>
                        <Link className="dropdown-item" to={PATHS.SPOKEN_ENGLISH}>Spoken English</Link> */}
                      </div>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to={PATHS.PRACTICE}>Practice Test</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to={PATHS.ABOUT}>About us</Link>
                    </li>
                    <li className="nav-item">
                     {/*} <a href="/#" className="nav-link" onClick={props.handleContactFocus}>Contact us</a> */}
                      <Link className="nav-link" to={PATHS.CONTACT_US}>Contact us</Link>
                    </li>                    
                  </ul>
                </div>
                <div className="d-flex top-menu-bar" data-aos="fade-up" data-aos-delay="300">
                {auth ? 
                  <div className="profile-sec ml-2">
                    <div className="dropdown show">
                      <a className="dropdown-toggle" href="/#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                         { props.user && props.user.profile_image ? 
                            <img src={imagePath ? (imagePath.user + props.user.profile_image) : ""} alt="user" className="rounded-circle" width="40" />
                           :
                           <img src="/assets/images/users/profile-pic.jpg" alt="user" className="rounded-circle" width="40" />
                         }              
                      </a>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a className="dropdown-item" href="/#" onClick={refreshDashboard}><i className="fas fa-cog"></i> <span>Dashboard</span></a>
                        {/*<Link className="dropdown-item" to={PATHS.DASHBOARD}><i className="fas fa-cog"></i> <span>Dashboard</span></Link>*/}
                        <Link className="dropdown-item d-flex align-items-center" to={PATHS.MY_WALLET} >
                                    <i className="fas fa-wallet"></i><span className="ml-2">My Transaction</span>
                                </Link>
                        <Link className="dropdown-item" to={PATHS.UPDATE_PROFILE}><i className="fas fa-user"></i> <span>Profile</span></Link>
                        <a className="dropdown-item" href="/#" onClick={handleLogout}><i className="fas fa-sign-out-alt"></i> <span> Logout</span></a>
                      </div>
                    </div>
                  </div> 
                  : 
                   <Link className="btn white-brdr-btn pl-3 pl-sm-4 pr-3 pr-sm-4 sign-btn" to={PATHS.LOGIN}>Sign In</Link>
                  
                    }
                    {/*<button className="navbar-toggler ml-3" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded="false" aria-label="Toggle navigation">
                                          <span className="navbar-toggler-icon" onClick={handleCollapse}></span>
                                        </button>*/}
                    <button className="navbar-toggler ml-3 collapsed" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="bd-docs-nav" aria-expanded="false" aria-label="Toggle docs navigation">
                      <span className="navbar-toggler-icon"></span>
                    </button>
                
                </div>
              </nav>      
            </div>
          </header>); 
}

export default withRouter((NavOnIndex));