import React, { useEffect } from "react";
import {
  LoaderScreen,
  NavOnDashboard,
  FooterOnDashboard,
  Sidebar
} from "../includes";
import { userInfo, fetchPlans } from "../../actions";
import { useSelector, useDispatch } from "react-redux";

import useScript from "../../hooks/useScript";
import "./chat.css";

//import botAvatar from "../static/img/botAvatar.png";

//import Jobs from "../../external-js/script.js"

const Chat = props => {
  const jsFile = "/static/js/script.js";

  const dispatch = useDispatch();

  useScript(jsFile);

  useEffect(() => {
    dispatch(userInfo());
    dispatch(fetchPlans());
    var elemsTap = document.querySelector(".tap-target") || null;
    if (elemsTap) {
      var instancesTap = window.M.TapTarget.init(elemsTap, {});
      instancesTap.open();
      setTimeout(function() {
        instancesTap.close();
      }, 4000);
    }
  }, [dispatch]);

  const userDetail = useSelector(state =>
    state.service.userDetailResponse
      ? state.service.userDetailResponse.data
      : ""
  );

  const subscriptionActive = useSelector(state =>
    state.service.plansInfo
      ? state.service.plansInfo.is_subscription_active
      : false
  );

  const imagePath = useSelector(state =>
    state.service.userDetailResponse
      ? state.service.userDetailResponse.path
      : []
  );

  //
  if (userDetail) {
    window.user_image = imagePath.user + userDetail.profile_image;
  }

  return (
    <div
      id="main-wrapper"
      data-theme="light"
      data-layout="vertical"
      data-navbarbg="skin6"
      data-sidebartype="full"
      data-sidebar-position="fixed"
      data-header-position="fixed"
      data-boxed-layout="full"
    >
      <NavOnDashboard user={userDetail} />
      <Sidebar />
      <div className="page-wrapper">
        <div className="page-m-height">
          <div className="page-breadcrumb">
            <div className="row">
              <div className="col-12 col-sm-8 align-self-center mb-3 mb-sm-0">
                <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">
                  My Chat Bot
                </h3>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12 stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className=" brdrbtm-lblue mb-3 d-flex justify-content-between">
                      {!subscriptionActive ? (
                        <h4 className="card-title pb-3">
                          {" "}
                          Please purchase membership to access the chat bot
                        </h4>
                      ) : (
                        ""
                      )}
                    </div>
                    <h3>What is ChatBot ?</h3>
                    <p>
                      Chatbot is a computer program that simulates and processes
                      human conversation allowing humans to interact with
                      digital devices as if they were communicating with a real
                      person. It can communicate either written or spoken.
                      Chatbot have simple programs that answer a simple query
                      with a single-line response, or as sophisticated as
                      digital assistants that learn and evolve to deliver
                      increasing levels of personalization as they gather and
                      process information.
                    </p>
                    <hr />

                    <h3>How ChatBot Works:</h3>
                    <p>
                      User communicates with a chatbot via the chat interface,
                      like they are talking to a real person. Chatbot interprets
                      and processes user's words or phrases, grammar and gives
                      an instant pre-set answer. As of now there are 3 features
                      implemented which can use you to improve your English
                      Vocabulary.
                      <br />
                      1. Check Grammar.
                      <br />
                      2. Check Synonyms.
                      <br />
                      3. Check Antonyms.
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            {!subscriptionActive ? (
              <div>
                <div className="widget">
                  <div className="chat_header">
                    <span className="chat_header_title">Edu Master</span>
                    <div className="dropdown">
                      <span
                        className="dropdown-trigger"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="material-icons">more_vert</i>
                      </span>

                      <ul
                        id="dropdown1"
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <li className="dropdown-item" id="clear">
                          <a href="#!">Clear</a>
                        </li>
                        <li className="dropdown-item" id="close">
                          <a href="#!">Close</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="chats" id="chats">
                    <div className="clearfix"></div>
                  </div>

                  <div className="keypad">
                    <textarea
                      id="userInput"
                      placeholder="Type a message..."
                      className="usrInput"
                    ></textarea>
                    <div id="sendButton">
                      <i className="fa fa-paper-plane" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>

                <div className="profile_div" id="profile_div">
                  <img className="imgProfile" src="/static/img/botAvatar.png" />
                </div>

                <div className="tap-target" data-target="profile_div">
                  <div className="tap-target-content">
                    <h5 className="white-text">Hello 👋</h5>
                    <p className="white-text">What can i do for you</p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <FooterOnDashboard />
      </div>
    </div>
  );
};

export default Chat;
